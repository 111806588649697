import React, { useCallback, useState } from 'react';
import {
  Edit,
  EditProps,
  FileField,
  FileInput,
  FormTab,
  required,
  TabbedForm,
  useEditController,
} from 'react-admin';
import { Button } from '@material-ui/core';
import { ContentType } from './OfferCreate';
import StyledGridLayout from '../../components/StyledGridLayout';
import { StyledSelectInput, StyledTextInput } from '../../components/StyledInputs';
import { SimpleConfirmDeleteToolbar } from '../../components/SimpleConfirmDeleteToolbar';
import { ColorInput } from '../../components/ColorInput';

const OfferEdit = (props: EditProps) => {
  const { record } = useEditController(props);

  const [editHeroContent, setEditHeroContent] = useState(
    record?.heroContent !== undefined && record?.heroContent !== null,
  );
  const [editSecondaryContent, setEditSecondaryContent] = useState(
    record?.secondaryContent !== undefined && record?.secondaryContent !== null,
  );
  const [editTileContent, setEditTileContent] = useState(
    record?.tileContent !== undefined && record?.tileContent !== null,
  );

  const toggleContent = (type: ContentType, toggle: boolean) => {
    switch (type) {
      case ContentType.Hero: {
        setEditHeroContent(toggle);
        break;
      }
      case ContentType.Secondary: {
        console.log(`this was called: ${toggle}`);
        setEditSecondaryContent(toggle);
        break;
      }
      case ContentType.Tile: {
        setEditTileContent(toggle);
        break;
      }
    }
  };

  const aTransform = useCallback(
    (data) => {
      console.log(data);
      const updatedData = {
        ...data,
        heroContent: editHeroContent ? data.heroContent : null,
        secondaryContent: editSecondaryContent ? data.secondaryContent : null,
        tileContent: editTileContent ? data.tileContent : null,
      };
      console.log(editHeroContent);
      console.log(editSecondaryContent);
      console.log(editTileContent);
      console.log(updatedData);
      return updatedData;
    },
    [editHeroContent, editSecondaryContent, editTileContent],
  );

  return (
    <Edit {...props} title="Businesses">
      <TabbedForm redirect="show" toolbar={<SimpleConfirmDeleteToolbar transform={aTransform} />}>
        <FormTab label="Offer Details">
          <StyledGridLayout>
            <StyledTextInput source="offerName" validate={[required()]} />
            <StyledSelectInput
              source="offerType"
              label="Type"
              choices={[
                { id: 'MyOffers', name: 'My Offers' },
                { id: 'PrizeDraw', name: 'Prize Draw' },
              ]}
              validate={[required()]}
            />
            <StyledTextInput
              source="offerDetailSlug"
              label="Offer detail contentful slug"
              validate={[required()]}
            />
            <StyledTextInput source="contentfulEntityId" label="Contentful entity id" />
          </StyledGridLayout>
        </FormTab>
        <FormTab label="Hero Content">
          {!editHeroContent && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => toggleContent(ContentType.Hero, true)}
            >
              Add Hero Content
            </Button>
          )}
          {editHeroContent && (
            <>
              <StyledGridLayout>
                <StyledTextInput
                  label="Hero Title"
                  source="heroContent.title"
                  validate={[editHeroContent, required()]}
                />
                <StyledTextInput
                  label="Hero Content"
                  source="heroContent.content"
                  validate={[editHeroContent, required()]}
                />
                {record?.heroContent && (
                  <>
                    <FileInput source="heroContentOfferImage" accept="image/*">
                      <FileField source="src" title="Offer Image" />
                    </FileInput>
                    <FileInput source="heroContentLogoImage" accept="image/*">
                      <FileField source="src" title="Logo Image" />
                    </FileInput>
                  </>
                )}
                <StyledTextInput
                  source="heroContent.offerAction.title"
                  label="Hero content action title"
                  validate={[editHeroContent, required()]}
                />
                <StyledTextInput
                  source="heroContent.offerAction.link"
                  label="Hero content action link"
                  validate={[editHeroContent, required()]}
                />
              </StyledGridLayout>
              <Button
                variant="contained"
                color="primary"
                onClick={() => toggleContent(ContentType.Hero, false)}
              >
                Remove
              </Button>
            </>
          )}
        </FormTab>
        <FormTab label="Secondary Content">
          {!editSecondaryContent && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => toggleContent(ContentType.Secondary, true)}
            >
              Add Secondary Content
            </Button>
          )}
          {editSecondaryContent && (
            <>
              <StyledGridLayout>
                <StyledTextInput
                  source="secondaryContent.title"
                  validate={[editSecondaryContent, required()]}
                />
                <StyledTextInput
                  source="secondaryContent.content"
                  validate={[editSecondaryContent, required()]}
                />
                {record?.secondaryContent && (
                  <>
                    <FileInput source="secondaryContentOfferImage" accept="image/*">
                      <FileField source="src" title="Offer Image" />
                    </FileInput>
                    <FileInput source="secondaryContentLogoImage" accept="image/*">
                      <FileField source="src" title="Logo Image" />
                    </FileInput>
                  </>
                )}
                <StyledTextInput
                  source="secondaryContent.offerAction.title"
                  label="Secondary content action title"
                  validate={[editSecondaryContent, required()]}
                />
                <StyledTextInput
                  source="secondaryContent.offerAction.link"
                  label="Secondary content action link"
                  validate={[editSecondaryContent, required()]}
                />
                <ColorInput
                  source="secondaryContent.cardColor"
                  label="Card Color"
                  validate={required()}
                />
              </StyledGridLayout>
              <Button
                variant="contained"
                color="primary"
                onClick={() => toggleContent(ContentType.Secondary, false)}
              >
                Remove
              </Button>
            </>
          )}
        </FormTab>
        <FormTab label="Tile Content">
          {!editTileContent && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => toggleContent(ContentType.Tile, true)}
            >
              Add Tile Content
            </Button>
          )}
          {editTileContent && (
            <>
              <StyledGridLayout>
                <StyledTextInput
                  source="tileContent.title"
                  validate={[editTileContent, required()]}
                />
                <StyledTextInput
                  source="tileContent.content"
                  validate={[editTileContent, required()]}
                />
                {record?.tileContent && (
                  <>
                    <FileInput source="tileContentOfferImage" accept="image/*">
                      <FileField source="src" title="Offer Image" />
                    </FileInput>
                    <FileInput source="tileContentLogoImage" accept="image/*">
                      <FileField source="src" title="Logo Image" />
                    </FileInput>
                  </>
                )}
                <StyledTextInput
                  source="tileContent.offerAction.title"
                  label="Tile content action title"
                  validate={[editTileContent, required()]}
                />
                <StyledTextInput
                  source="tileContent.offerAction.link"
                  label="Tile content action link"
                  validate={[editTileContent, required()]}
                />
              </StyledGridLayout>
              <Button
                variant="contained"
                color="primary"
                onClick={() => toggleContent(ContentType.Tile, false)}
              >
                Remove
              </Button>
            </>
          )}
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default OfferEdit;
