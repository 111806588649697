/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { BusinessActions } from '../../../ra-queries/queries/businesses';

const BusinessActionsList = (props: any) => {
  const business = props.record;
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const [modalOpen, setModalOpen] = useState(false);

  const handleGenerateApiKey = useCallback(async () => {
    return dataProvider
      .update('Businesses', {
        id: business.id,
        data: {
          ...business,
          action: BusinessActions.GenerateApiKey,
        },
        previousData: business,
      })
      .then(() => {
        refresh();
        notify('A new API Key has been generated');
      })
      .catch((error) => {
        refresh();
        notify(`API Key cannot be generated: ${error.message}`, 'warning');
      });
  }, [business, dataProvider, notify, refresh]);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleAddAction = () => {
    setModalOpen(true);
  };

  return (
    <>
      <div style={{ margin: '20px 0 30px 0' }}>
        <Button variant="contained" color="primary" onClick={handleAddAction}>
          Generate API Key
        </Button>
      </div>
      <Dialog open={modalOpen} onClose={handleModalClose} fullWidth>
        <DialogTitle id="responsive-dialog-title">Generate API Key</DialogTitle>
        <DialogContent>
          This action will generate a new API key for this business. If the business has an existing
          API Key, it will override and this may impact the business&apos;s current integrations.
          The business will need to be notified about the new API Key. Do you want to continue?
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleModalClose} color="primary">
            No
          </Button>
          <Button
            color="primary"
            autoFocus
            onClick={() => {
              handleGenerateApiKey();
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BusinessActionsList;
