import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { UserActions } from '../../../ra-queries/queries/users';

const ToogleAdminButton = (props: any) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const isAdmin = props.record.isAdmin;

  const handleAdminPrivilegesToggle = useCallback(async () => {
    const action = isAdmin ? UserActions.RevokeAdminPrivileges : UserActions.GrantAdminPrivileges;
    const message = `Admin privileges have been ${isAdmin ? 'revoked' : 'granted'}`;

    return dataProvider
      .update('Users', {
        id: props.record.id,
        data: { ...props.record, action },
        previousData: props.record,
      })
      .then(() => {
        refresh();
        notify(message);
      })
      .catch((error) => {
        refresh();
        notify(`Could not change admin privileges: ${error.message}`, 'warning');
      });
  }, [dataProvider, isAdmin, notify, props.record, refresh]);

  return (
    <div style={{ margin: '20px 0 30px 0' }}>
      <div style={{ marginBottom: '10px' }}>
        This user {isAdmin ? 'has' : 'does not have'} admin privileges
      </div>
      <Button variant="contained" color="primary" onClick={handleAdminPrivilegesToggle}>
        {isAdmin ? 'Revoke' : 'Grant'} Admin Privileges
      </Button>
    </div>
  );
};

export default ToogleAdminButton;
