import React from 'react';
import {
  BooleanField,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  useShowController,
} from 'react-admin';
import Carmodels from './elements/Carmodels';

const CarmakerShow = (props: ShowProps) => {
  const { record } = useShowController(props);

  if (!record) return null;

  return (
    <>
      <Show {...props} title="Car Makers">
        <TabbedShowLayout>
          <Tab label="summary" contentClassName="two-columns-layout">
            <TextField source="name" />
            <BooleanField source="visible" />
          </Tab>
          <Tab label="Models" path="models">
            <Carmodels />
          </Tab>
        </TabbedShowLayout>
      </Show>
    </>
  );
};
export default CarmakerShow;
