import { gql } from '@apollo/client';
import {
  RaFetchType,
  RaGetResponse,
  RaListParams,
  RaListResponse,
  RaQuery,
  RaUpdateParams,
} from '../../interfaces/RaQueries';
import { LeasingOfferType } from '../../interfaces/types.generated';
import { ApiResponse } from '../../interfaces/ApiQueries';
import getLeasingOffersListParams from '../../utils/getLeasingOffersListParams';
import getLeasingOffersUpdateParams from '../../utils/getLeasingOffersUpdateParams';

interface ListResult {
  leasingOffers: {
    leasingOffers: Array<LeasingOfferType>;
    overallCount: number;
  };
}

const LeasingOffers = (raFetchType: RaFetchType, params: any): RaQuery => {
  switch (raFetchType) {
    case 'GET_LIST':
      return {
        query: gql`
          query (
            $limit: Int
            $offset: Int
            $searchPhrase: String
            $order: LeasingOffersQueryOrderEnum!
            $onlyShowVisibleToPublic: Boolean
          ) {
            leasingOffers(
              params: {
                limit: $limit
                offset: $offset
                searchPhrase: $searchPhrase
                order: $order
                onlyShowVisibleToPublic: $onlyShowVisibleToPublic
              }
            ) {
              leasingOffers {
                id
                title
                fromPrice
                subTitle
                tagline
                initialPayment
                contractLength
                miles
                vatIncluded
                visibleToPublic
                offerImage {
                  url
                }
                createdAt
                updatedAt
              }
              overallCount
            }
          }
        `,
        variables: getLeasingOffersListParams(params as RaListParams),
        parseResponse: ({ data }: ApiResponse<ListResult>): RaListResponse<LeasingOfferType> => ({
          data: data.leasingOffers.leasingOffers,
          total: data.leasingOffers.overallCount,
        }),
      };
    case 'GET_ONE':
      return {
        query: gql`
          query ($id: String!) {
            leasingOffer(id: $id) {
              id
              title
              fromPrice
              subTitle
              tagline
              initialPayment
              contractLength
              miles
              vatIncluded
              visibleToPublic
              offerImage {
                url
              }
              createdAt
              updatedAt
              enquiries {
                id
                contactName
                email
                phoneNumber
              }
            }
          }
        `,
        variables: { id: params.id },
        parseResponse: ({ data }): RaGetResponse<LeasingOfferType> => ({
          data: {
            ...data.leasingOffer,
          },
        }),
      };
    case 'CREATE':
      return {
        query: gql`
          mutation ($input: CreateLeasingOfferInputType!) {
            createLeasingOffer(input: $input) {
              id
              title
              fromPrice
              subTitle
              tagline
              initialPayment
              contractLength
              miles
              vatIncluded
              visibleToPublic
              offerImage {
                url
              }
              createdAt
              updatedAt
            }
          }
        `,
        variables: { input: params.data },
        parseResponse: ({ data }): RaGetResponse<LeasingOfferType> => ({
          data: data.createLeasingOffer,
        }),
      };
    case 'DELETE':
      return {
        query: gql`
          mutation ($offerId: String!) {
            deleteLeasingOffer(id: $offerId)
          }
        `,
        variables: { offerId: params.id },
        parseResponse: ({ data }): RaGetResponse<boolean> => ({
          data: data.deleteLeasingOffer,
        }),
      };
    case 'UPDATE':
      return {
        query: gql`
          mutation ($id: String!, $input: UpdateLeasingOfferInputType!) {
            updateLeasingOffer(id: $id, input: $input) {
              id
              title
              fromPrice
              subTitle
              tagline
              initialPayment
              contractLength
              miles
              vatIncluded
              visibleToPublic
              offerImage {
                url
              }
              createdAt
              updatedAt
            }
          }
        `,
        variables: getLeasingOffersUpdateParams(params as RaUpdateParams<LeasingOfferType>),
        parseResponse: ({ data }): RaGetResponse<LeasingOfferType> => ({
          data: data.updateLeasingOffer,
        }),
      };

    default:
      throw new Error(`Unknown fetch type: ${raFetchType}`);
  }
};

export default LeasingOffers;
