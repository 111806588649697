/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useDataProvider, useNotify, useRefresh } from 'react-admin';
import { gql, useQuery } from '@apollo/client';
import { OfferActions } from '../../../ra-queries/queries/offers';

const useStyles = makeStyles({
  root: {
    marginBottom: '20px',
  },
});

const GET_BUNDLE_TYPES = gql`
  query BundleTypes {
    bundleTypes {
      id
      name
      internalName
    }
  }
`;

const AttachedBundles = (props: any) => {
  const offer = props.record;
  const attachedBundles = props.record.attachedBundles;
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const classes = useStyles();

  const [modalAddOpen, setModalAddOpen] = useState(false);
  const [selectedBundleTypeId, setSelectedBundleTypeId] = useState('');
  const [selectedBundleOfferType, setSelectedBundleOfferType] = useState('');

  const [confirmDetachModalOpen, setConfirmDetachModalOpen] = useState(false);

  const { data: bundlesData } = useQuery(GET_BUNDLE_TYPES);

  const handleAttachOfferToBundle = useCallback(
    async ({ bundleId, bundleOfferType }: any) => {
      return dataProvider
        .update('Offers', {
          id: offer.id,
          data: {
            ...offer,
            bundleId,
            action: OfferActions.attachOfferToBundle,
            bundleOfferType,
          },
          previousData: offer,
        })
        .then(() => {
          refresh();
          notify('Offer has been attached to the bundle');
        })
        .catch((error) => {
          refresh();
          notify(`Offer could not be attached: ${error.message}`, 'warning');
        });
    },
    [offer, dataProvider, notify, refresh],
  );

  const handleDetachOfferFromBundle = useCallback(
    async ({ bundleId }: any) => {
      return dataProvider
        .update('Offers', {
          id: offer.id,
          data: {
            ...offer,
            bundleId,
            action: OfferActions.detachOfferFromBundle,
          },
          previousData: offer,
        })
        .then(() => {
          refresh();
          notify('Offer has been detached from the bundle');
        })
        .catch((error) => {
          refresh();
          notify(`Offer could not be detached: ${error.message}`, 'warning');
        });
    },
    [offer, dataProvider, notify, refresh],
  );

  const handleModalAddClose = () => {
    setModalAddOpen(false);
  };
  const handleAddAction = () => {
    setModalAddOpen(true);
  };

  const handleDetachConfirmModalClose = () => {
    setConfirmDetachModalOpen(false);
  };

  const handleDetachOfferAction = () => {
    setConfirmDetachModalOpen(true);
  };

  return (
    <>
      <div style={{ margin: '20px 0 30px 0' }}>
        <Button variant="contained" color="primary" onClick={handleAddAction}>
          Attach To Bundle
        </Button>
      </div>
      {attachedBundles.map((bundle: any) => (
        <Card key={bundle.id} className={classes.root}>
          <CardContent>
            <Typography variant="overline" display="block" gutterBottom>
              Attachment Type: {bundle.offerType}
            </Typography>
            <Typography variant="h6" display="block" gutterBottom>
              <Link to={`/Bundles/${bundle.bundle.id}/show`}>
                {bundle.bundle.name} ({bundle.bundle.internalName})
              </Link>
            </Typography>
            <Typography variant="overline" display="block" gutterBottom>
              {bundle.bundle.description}
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              style={{ background: 'none', color: '#f44336' }}
              onClick={() => {
                setSelectedBundleTypeId(bundle.bundle.id);
                handleDetachOfferAction();
              }}
              startIcon={<DeleteIcon />}
            >
              Detach Bundle
            </Button>
          </CardActions>
        </Card>
      ))}
      <Dialog open={modalAddOpen} onClose={handleModalAddClose} fullWidth>
        <DialogTitle id="responsive-dialog-title">Attach offer to bundle</DialogTitle>
        <DialogContent>
          <span>Bundle Type</span>
          <Select
            value={selectedBundleTypeId}
            onChange={(e: any) => setSelectedBundleTypeId(e.target.value)}
            variant="outlined"
            label="Businesses"
            fullWidth
          >
            {bundlesData &&
              bundlesData.bundleTypes.map((bundleType: any) => (
                <MenuItem value={bundleType.id} key={bundleType.id}>
                  {bundleType.name} ({bundleType.internalName})
                </MenuItem>
              ))}
          </Select>
          <span>Bundle Offer Type</span>
          <Select
            value={selectedBundleOfferType}
            onChange={(e: any) => setSelectedBundleOfferType(e.target.value)}
            variant="outlined"
            label="Offer Type"
            fullWidth
          >
            <MenuItem value="Hero" key="Hero">
              Hero
            </MenuItem>
            <MenuItem value="Secondary" key="Secondary">
              Secondary
            </MenuItem>
            <MenuItem value="Tile" key="Tile">
              Tile
            </MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleModalAddClose} color="primary">
            Cancel
          </Button>
          <Button
            color="primary"
            autoFocus
            disabled={
              !selectedBundleTypeId ||
              selectedBundleTypeId === '' ||
              !selectedBundleOfferType ||
              selectedBundleOfferType === ''
            }
            onClick={() => {
              handleAttachOfferToBundle({
                bundleId: selectedBundleTypeId,
                bundleOfferType: selectedBundleOfferType,
              });
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmDetachModalOpen} onClose={handleDetachConfirmModalClose} fullWidth>
        <DialogTitle id="responsive-dialog-title">Detach Bundle</DialogTitle>
        <DialogContent>
          This will detach the offer from this bundle and the offer will stop showing to the user
          for that specific bundle. Do you want to continue?
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDetachConfirmModalClose} color="primary">
            No
          </Button>
          <Button
            color="primary"
            autoFocus
            onClick={() => {
              handleDetachOfferFromBundle({ bundleId: selectedBundleTypeId });
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AttachedBundles;
