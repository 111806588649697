import React, { useState } from 'react';
import { Card, CardContent, MenuItem, Select, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { gql, useQuery } from '@apollo/client';
import DownloadReportButton from './DownloadReportButton';
import EmailReportButton from './EmailReportButton';

const useStyles = makeStyles({
  root: {
    marginTop: '20px',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '12px',
    marginBottom: '10px',
    display: 'inline-block',
  },
});

const GET_AVAILABLE_REPORTS = gql`
  query availableReports {
    availableReports {
      type
      name
    }
  }
`;

const Reports = () => {
  const classes = useStyles();
  const [timeEnd, setTimeEnd] = useState<string>('');
  const [timeStart, setTimeStart] = useState<string>('');
  const [report, setReport] = useState<string>('');

  const { data: reportsData, loading: loadingReportsData } = useQuery(GET_AVAILABLE_REPORTS);

  return (
    <Card className={classes.root}>
      <CardContent>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridGap: '20px',
            marginBottom: '20px',
          }}
        >
          <div>
            <span className={classes.label}>Date from</span>
            <TextField
              variant="outlined"
              size="small"
              type="date"
              value={timeStart}
              onChange={(e) => setTimeStart(e.target.value)}
              fullWidth
            />
          </div>
          <div>
            <span className={classes.label}>Date to</span>
            <TextField
              variant="outlined"
              size="small"
              type="date"
              value={timeEnd}
              onChange={(e) => setTimeEnd(e.target.value)}
              fullWidth
            />
          </div>
        </div>
        <div
          style={{
            marginBottom: '20px',
          }}
        >
          <span className={classes.label}>Report type</span>
          <Select
            value={report}
            onChange={(e: any) => setReport(e.target.value)}
            variant="outlined"
            label="Report"
            fullWidth
          >
            {!loadingReportsData &&
              reportsData &&
              reportsData.availableReports.map((reportOption: any) => (
                <MenuItem value={reportOption.type} key={reportOption.type}>
                  {reportOption.name}
                </MenuItem>
              ))}
          </Select>
        </div>
        <div
          style={{
            marginBottom: '10px',
            marginTop: '10px',
          }}
        >
          Use Download Report if you want to download the report right away from this page. Use
          Email Report if you want the link to be sent to your email. Use Email Report for large
          report data.
        </div>
        <DownloadReportButton timeStart={timeStart} timeEnd={timeEnd} report={report} />
        <span style={{ paddingRight: '10px' }} />
        <EmailReportButton timeStart={timeStart} timeEnd={timeEnd} report={report} />
      </CardContent>
    </Card>
  );
};

export default Reports;
