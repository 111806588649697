import React from 'react';
import {
  Datagrid,
  DateField,
  EditButton,
  Filter,
  FunctionField,
  List,
  ListProps,
  TextField,
  TextInput,
} from 'react-admin';

const OffersFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput label="Offer name" source="q" alwaysOn variant="outlined" />
    </Filter>
  );
};

const OffersList = (props: ListProps) => {
  return (
    <List {...props} bulkActionButtons={false} empty={false} filters={<OffersFilter />}>
      <Datagrid rowClick="show">
        <TextField source="id" label="Offer Id" sortable={false} />
        <TextField source="offerName" label="Offer Name" sortable={false} />
        <TextField source="offerType" label="Offer Type" sortable={false} />
        <FunctionField
          label="Hero Content"
          sortable={false}
          render={(record: any) => {
            return record.heroContent ? `Yes` : `No`;
          }}
        />
        <FunctionField
          label="Secondary Content"
          sortable={false}
          render={(record: any) => {
            return record.secondaryContent ? `Yes` : `No`;
          }}
        />
        <FunctionField
          label="Tile Content"
          sortable={false}
          render={(record: any) => {
            return record.tileContent ? `Yes` : `No`;
          }}
        />
        <DateField source="createdAt" sortable showTime />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default OffersList;
