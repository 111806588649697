import React from 'react';
import {
  BooleanInput,
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  Link,
  List,
  ListProps,
  TextField,
  TextInput,
} from 'react-admin';
import { gql, useQuery } from '@apollo/client';
import SubscriptionStatusField from '../../components/SubscriptionStatusField';
import { StyledSelectInput } from '../../components/StyledInputs';
import { displayDateFromTimestamp } from '../../utils/stringHelpers';

const GET_BUSINESSES = gql`
  query Businesses {
    businesses {
      id
      name
      displayName
    }
  }
`;

const GET_BUNDLE_TYPES = gql`
  query BundleTypes {
    bundleTypes {
      id
      name
    }
  }
`;

const SubscriptionsFilter = (props: any) => {
  const { data: businessesData } = useQuery(GET_BUSINESSES);
  const { data: bundleTypesData } = useQuery(GET_BUNDLE_TYPES);

  return (
    <Filter {...props}>
      <TextInput label="Customer Name/Email" source="q" alwaysOn variant="outlined" />
      <BooleanInput label="Due to renewal only" alwaysOn source="onlyDueToRenewal" />
      <StyledSelectInput
        source="resellerBusinessId"
        label="Reseller"
        choices={
          businessesData &&
          businessesData.businesses.map((buisness: any) => {
            return { id: buisness.id, name: `${buisness.displayName} (${buisness.name})` };
          })
        }
      />
      <StyledSelectInput
        source="bundleTypeId"
        label="Bundle"
        choices={
          bundleTypesData &&
          bundleTypesData.bundleTypes.map((bundleType: any) => {
            return { id: bundleType.id, name: bundleType.name };
          })
        }
      />
    </Filter>
  );
};
const SubscriptionsList = (props: ListProps) => (
  <List {...props} bulkActionButtons={false} filters={<SubscriptionsFilter />}>
    <Datagrid rowClick="show">
      <TextField source="code" sortable={false} />
      <SubscriptionStatusField sortable={false} label="Status" />
      <TextField source="bundleType.name" sortable={false} label="Bundle" />
      <FunctionField
        label="Subscriber"
        sortable={false}
        render={(record: any) => {
          if (record.user) {
            return `${record.user.details.firstName} ${record.user.details.lastName}`;
          }
          return '';
        }}
      />
      <FunctionField
        label="Reseller"
        sortable={false}
        render={(record: any) => {
          return record.resellerBusiness ? record.resellerBusiness.displayName : 'Zoom';
        }}
      />
      <FunctionField
        label="Requested Call"
        sortable={false}
        render={(record: any) => {
          return record.user.callRequest ? (
            <Link to={`/CallRoster/${record.user.callRequest.id}/show`}>Yes</Link>
          ) : (
            'No'
          );
        }}
      />
      <TextField source="yearOfSubscription" sortable={false} label="Year of Subscription" />
      <FunctionField
        label="Next renewal date"
        sortable={false}
        render={(record: any) => {
          return record.status === 'Expired'
            ? ''
            : `${displayDateFromTimestamp(record.currentPeriodEnd)}`;
        }}
      />
      <DateField source="createdAt" sortable={false} showTime />
    </Datagrid>
  </List>
);

export default SubscriptionsList;
