import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import {
  BooleanField,
  DateField,
  FunctionField,
  Link,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  useDataProvider,
  useNotify,
  useRefresh,
  useShowController,
} from 'react-admin';
import { InvitesActions } from '../../ra-queries/queries/invites';
import InviteNotes from './elements/InviteNotes';

const InviteShow = (props: ShowProps) => {
  const { record } = useShowController(props);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const handleRevokeDiscount = useCallback(
    async (currentRecord: any) => {
      return dataProvider
        .update('Invites', {
          id: currentRecord.id,
          data: { ...currentRecord, action: InvitesActions.RevokeInvite },
          previousData: currentRecord,
        })
        .then(() => {
          refresh();
          notify('Invite has been revoked');
        })
        .catch((error) => {
          refresh();
          notify(`Invite could not be revoked: ${error.message}`, 'warning');
        });
    },
    [dataProvider, notify, refresh],
  );

  const handleCustomerDeniedDiscount = useCallback(
    async (currentRecord: any) => {
      return dataProvider
        .update('Invites', {
          id: currentRecord.id,
          data: { ...currentRecord, action: InvitesActions.MarkAsCustomerDeniedInvite },
          previousData: currentRecord,
        })
        .then(() => {
          refresh();
          notify('Invite has been marked as Customer Denied');
        })
        .catch((error) => {
          refresh();
          notify(`Invite could not be marked as customer denied: ${error.message}`, 'warning');
        });
    },
    [dataProvider, notify, refresh],
  );

  const handleRevertToPendingDiscount = useCallback(
    async (currentRecord: any) => {
      return dataProvider
        .update('Invites', {
          id: currentRecord.id,
          data: { ...currentRecord, action: InvitesActions.RevertToPending },
          previousData: currentRecord,
        })
        .then(() => {
          refresh();
          notify('Invite has been Reverted to Pending');
        })
        .catch((error) => {
          refresh();
          notify(`Invite could not be marked as customer denied: ${error.message}`, 'warning');
        });
    },
    [dataProvider, notify, refresh],
  );

  const handleDeleteInviteData = useCallback(
    async (currentRecord: any) => {
      return dataProvider
        .update('Invites', {
          id: currentRecord.id,
          data: { ...currentRecord, action: InvitesActions.DeleteInviteData },
          previousData: currentRecord,
        })
        .then(() => {
          refresh();
          notify('Invite Data has been deleted');
        })
        .catch((error) => {
          refresh();
          notify(`Invite data could not be deleted: ${error.message}`, 'warning');
        });
    },
    [dataProvider, notify, refresh],
  );

  if (!record) return null;

  return (
    <Show {...props} title="Invites">
      <TabbedShowLayout>
        <Tab label="summary" contentClassName="two-columns-layout">
          <TextField source="id" />
          <TextField source="status" />
          <TextField source="resellerBusiness.displayName" label="Business" />
          <FunctionField
            source="createdBy"
            label="Business user"
            render={(discount: any) => {
              if (discount.createdBy) {
                return (
                  <Link to={`/Users/${discount.createdBy.id}/show`}>
                    {discount.createdBy.details?.firstName} {discount.createdBy.details?.lastName}
                  </Link>
                );
              }
              return '';
            }}
          />
          <TextField source="grantee.email" label="Grantee email" />
          <FunctionField
            source="grantee"
            label="Grantee"
            render={(discount: any) => {
              if (discount.grantee) {
                return (
                  <Link to={`/Users/${discount.grantee.user?.id}/show`}>
                    {discount.grantee.firstName} {discount.grantee.lastName}
                  </Link>
                );
              }
              return '';
            }}
          />
          <TextField source="carMakerName" />
          <TextField source="carModelName" />
          <FunctionField
            source="estimatedCarDeliveryDate"
            label="Estimated Car Delivery Date"
            render={(row: any) => {
              if (!row.estimatedCarDeliveryDate) {
                return '';
              }

              return new Date(Number(row.estimatedCarDeliveryDate)).toISOString().substring(0, 10);
            }}
          />
          <BooleanField source="isCarPartOfFleet" />
          <TextField source="proposalNumber" label={'Proposal Number'} />
          <DateField source="createdAt" showTime />
          {String(record.status) === 'PENDING' && (
            <div style={{ marginBottom: '20px', display: 'flex' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleRevokeDiscount(record)}
              >
                Revoke Invite
              </Button>

              <Button
                style={{ marginLeft: '20px' }}
                variant="contained"
                color="primary"
                onClick={() => handleCustomerDeniedDiscount(record)}
              >
                Mark As Customer Denied
              </Button>

              <Button
                style={{ marginLeft: '20px' }}
                variant="contained"
                color="primary"
                onClick={() => handleDeleteInviteData(record)}
              >
                Delete Invite Data
              </Button>
            </div>
          )}
          {String(record.status) === 'CUSTOMER_DENIED' && (
            <div style={{ marginBottom: '20px', display: 'flex' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleRevertToPendingDiscount(record)}
              >
                Revert to Pending Invite
              </Button>
            </div>
          )}
        </Tab>
        <Tab label="Notes" path="notes">
          <InviteNotes invite={record} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
export default InviteShow;
