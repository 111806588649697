import React from 'react';
import { EditProps, NumberField, Show, Tab, TabbedShowLayout, TextField } from 'react-admin';
import Resellers from './elements/Resellers';
import AttachedOffers from './elements/AttachedOffers';

const BundleShow = (props: EditProps) => (
  <Show {...props} title="Bundles">
    <TabbedShowLayout>
      <Tab label="summary" contentClassName="two-columns-layout">
        <TextField source="name" label="Display Name" />
        <TextField source="internalName" label="Internal Name" />
        <NumberField
          source="price"
          locales="en-GB"
          options={{ style: 'currency', currency: 'GBP' }}
        />
        <TextField source="description" />
        <TextField source="contentPageUrl" label="Content Page URL" />
        <TextField
          source="contentfulBenefitsComponentId"
          label="Contentful Benefits Component Id"
        />
        <TextField source="contentfulBannerComponentId" label="Contentful Banner Component Id" />
      </Tab>
      <Tab label="Resellers" path="resellers">
        <Resellers />
      </Tab>
      <Tab label="Attached Offers" path="offers">
        <AttachedOffers />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
export default BundleShow;
