import React, { useCallback, useState } from 'react';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
} from '@material-ui/core';
import { gql, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { CarActions } from '../../../ra-queries/queries/cars';

const useStyles = makeStyles({
  root: {
    marginRight: '10px',
    marginBottom: '20px',
  },
});

const GET_CAR_FEATURES = gql`
  query carFeatures {
    carFeatures {
      id
      name
      type
    }
  }
`;

const removeElementFromArray = (array: Array<string>, removedElement: string) => {
  const arrayCopy = [...array];
  return arrayCopy.filter((element) => element !== removedElement);
};

const CarFeatures = (props: any) => {
  const car = props.record;
  const features = car.features;
  const featuresIds = car.features.map((feature: any) => feature.id);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const classes = useStyles();

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFeatureId, setSelectedFeatureId] = useState('');

  const { data: carFeaturesData } = useQuery(GET_CAR_FEATURES);

  const handleCarFeaturesUpdate = useCallback(
    async (newFeatures: Array<string>) => {
      return dataProvider
        .update('Cars', {
          id: car.id,
          data: { ...car, action: CarActions.UpdateCarFeatures, newFeatures },
          previousData: car,
        })
        .then(() => {
          refresh();
          notify('Car features have been updated');
        })
        .catch((error) => {
          refresh();
          notify(`Car features could not be updated: ${error.message}`, 'warning');
        });
    },
    [car, dataProvider, notify, refresh],
  );

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedFeatureId('');
  };

  const handleAddAction = () => {
    setModalOpen(true);
  };

  return (
    <>
      <div style={{ margin: '20px 0 30px 0' }}>
        <Button variant="contained" color="primary" onClick={handleAddAction}>
          Add feature
        </Button>
      </div>
      {features.map((feature: any) => (
        <Chip
          key={feature.id}
          label={feature.name}
          onDelete={() => handleCarFeaturesUpdate(removeElementFromArray(featuresIds, feature.id))}
          className={classes.root}
        />
      ))}
      <Dialog open={modalOpen} onClose={handleModalClose} fullWidth>
        <DialogTitle id="responsive-dialog-title">Add feature to car</DialogTitle>
        <DialogContent>
          <Select
            value={selectedFeatureId}
            onChange={(e: any) => setSelectedFeatureId(e.target.value)}
            variant="outlined"
            label="Tags"
            fullWidth
          >
            {carFeaturesData &&
              carFeaturesData.carFeatures.map((carFeature: any) => (
                <MenuItem value={carFeature.id} key={carFeature.id}>
                  {carFeature.name}
                </MenuItem>
              ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleModalClose} color="primary">
            Cancel
          </Button>
          <Button
            color="primary"
            autoFocus
            onClick={() => handleCarFeaturesUpdate([...featuresIds, selectedFeatureId])}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CarFeatures;
