import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import React, { useCallback, useState } from 'react';
import { Button } from '@material-ui/core';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { BookingActions } from '../../../ra-queries/queries/bookings';

const BookingOperations = (props: any) => {
  const notify = useNotify();
  const [confirmOpenCapture, setConfirmOpenCapture] = useState(false);
  const paymentStatus = props.record.paymentStatus;

  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const handlePaymentCapture = useCallback(async () => {
    const action = BookingActions.CapturePayment;
    const message = `Payment has been captured for this booking`;

    return dataProvider
      .update('Bookings', {
        id: props.record.id,
        data: { ...props.record, action },
        previousData: props.record,
      })
      .then(() => {
        refresh();
        notify(message);
      })
      .catch((error) => {
        refresh();
        notify(error.message, 'warning');
      });
  }, [dataProvider, notify, props.record, refresh]);

  return (
    <div style={{ marginTop: 10, marginBottom: 10 }}>
      {paymentStatus === 'RequiresCapture' && (
        <>
          <h5>Booking Actions</h5>
          <Button variant="contained" color="primary" onClick={() => setConfirmOpenCapture(true)}>
            Capture Payment
          </Button>
          <ConfirmDialog
            title="Capture Payment"
            open={confirmOpenCapture}
            setOpen={setConfirmOpenCapture}
            onConfirm={handlePaymentCapture}
          >
            Do you want to manually capture the pending payment for this booking?
          </ConfirmDialog>
        </>
      )}
    </div>
  );
};

export default BookingOperations;
