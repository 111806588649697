import { RaListParams } from '../interfaces/RaQueries';
import { ApiListParams } from '../interfaces/ApiQueries';
import {
  CallRequestRequestedTimeTypeEnum,
  CallRosterDateFilterEnum,
  CallRosterListOrderEnum,
} from '../interfaces/types.generated';

interface CallRosterListParams extends ApiListParams {
  requestedTime?: CallRequestRequestedTimeTypeEnum;
  dateFilter: CallRosterDateFilterEnum;
  showCompleted: boolean;
}

const getCallRosterListParams = ({
  pagination,
  sort,
  filter,
}: RaListParams): CallRosterListParams => {
  const { perPage, page } = pagination;
  const limit = perPage;
  const offset = (page - 1) * (perPage || 1);

  let order: string | undefined;
  switch (`${sort.field}${sort.order}`) {
    case 'createdAtASC':
      order = CallRosterListOrderEnum.CreatedAtAsc;
      break;
    case 'createdAtDESC':
      order = CallRosterListOrderEnum.CreatedAtAsc;
      break;
    case 'requestedDateASC':
      order = CallRosterListOrderEnum.RequestedDateAsc;
      break;
    case 'requestedDateDESC':
      order = CallRosterListOrderEnum.RequestedDateDesc;
      break;
    default:
      order = CallRosterListOrderEnum.RequestedDateDesc;
      break;
  }

  return {
    limit,
    offset,
    order,
    dateFilter: filter.dateFilter,
    requestedTime: filter.requestedTime,
    showCompleted: filter.showCompleted ? filter.showCompleted : false,
  };
};

export default getCallRosterListParams;
