import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { displayDateFromTimestamp } from '../../../utils/stringHelpers';
import { Link } from 'react-admin';

const useStyles = makeStyles({
  root: {
    marginBottom: '20px',
  },
});

const SubscriptionRenewalRequests = (props: any) => {
  const renewalRequests = props.record.renewalRequests;
  const classes = useStyles();

  return (
    <>
      {renewalRequests.length > 0 ? (
        renewalRequests.map((request: any) => (
          <Card key={request.id} className={classes.root}>
            <CardContent>
              <Typography variant="overline" display="block" gutterBottom>
                ID: <Link to={`/renewalRequests/${request.id}/show`}>{request.id}</Link>
              </Typography>
              <Typography variant="overline" display="block" gutterBottom>
                Status: {request.status}
              </Typography>
              <Typography variant="overline" display="block" gutterBottom>
                Created At: {displayDateFromTimestamp(request.createdAt)}
              </Typography>
            </CardContent>
          </Card>
        ))
      ) : (
        <div style={{ margin: '20px 0 30px 0' }}>
          This Subscription does not have any renewal requests.
        </div>
      )}
    </>
  );
};

export default SubscriptionRenewalRequests;
