import React, { useCallback, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { CarActions } from '../../../ra-queries/queries/cars';

const useStyles = makeStyles({
  root: {
    marginBottom: '20px',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
});

const CarPhotos = (props: any) => {
  const car = props.record;
  const photos = car.details.images;
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const classes = useStyles();

  const [modalOpen, setModalOpen] = useState(false);
  const [newPhoto, setNewPhoto] = useState<any>();

  const handleModalClose = () => {
    setModalOpen(false);
    setNewPhoto(undefined);
  };

  const handleAddAction = () => {
    setModalOpen(true);
  };

  const handleCarPhotoDelete = useCallback(
    async (photoId: string) => {
      return dataProvider
        .update('Cars', {
          id: car.id,
          data: { ...car, action: CarActions.RemoveCarPhoto, photoId },
          previousData: car,
        })
        .then(() => {
          refresh();
          notify('Photo has been deleted');
        })
        .catch((error) => {
          refresh();
          notify(`Photo could not be deleted: ${error.message}`, 'warning');
        });
    },
    [dataProvider, notify, refresh, car],
  );

  const handlePhotoAdd = useCallback(async () => {
    return dataProvider
      .update('Cars', {
        id: car.id,
        data: { ...car, action: CarActions.AddCarPhoto, newPhoto },
        previousData: car,
      })
      .then(() => {
        refresh();
        notify('Photo has been added');
      })
      .catch((error) => {
        refresh();
        notify(`Photo could not be added: ${error.message}`, 'warning');
      });
  }, [dataProvider, car, newPhoto, refresh, notify]);

  const handleSetMainImage = useCallback(
    async (mainImageId) => {
      return dataProvider
        .update('Cars', {
          id: car.id,
          data: { ...car, action: CarActions.SetCarMainImage, mainImageId },
          previousData: car,
        })
        .then(() => {
          refresh();
          notify('Main image has been updated');
        })
        .catch((error) => {
          refresh();
          notify(`Main image could not be set: ${error.message}`, 'warning');
        });
    },
    [dataProvider, car, refresh, notify],
  );

  return (
    <>
      <div style={{ margin: '20px 0 30px 0' }}>
        <Button variant="contained" color="primary" onClick={handleAddAction}>
          Add photo
        </Button>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
          gridGap: '20px',
        }}
      >
        {photos.length > 0 ? (
          photos.map((photo: any) => (
            <Card key={photo.id} className={classes.root}>
              <a href={photo.url} target="_blank" rel="noreferrer">
                <CardMedia className={classes.media} image={photo.url} />
              </a>
              <CardActions>
                {/* <Button size="small" >
                  Preview
                </Button> */}
                <Button
                  size="small"
                  style={{ background: 'none', color: '#f44336' }}
                  onClick={() => handleCarPhotoDelete(photo.id)}
                  startIcon={<DeleteIcon />}
                >
                  Delete
                </Button>

                {car.details.mainImageId === photo.id ? (
                  <div />
                ) : (
                  <Button
                    size="small"
                    style={{ background: 'none', color: 'green', fontWeight: 700 }}
                    onClick={() => {
                      handleSetMainImage(photo.id);
                    }}
                  >
                    Set as Main
                  </Button>
                )}
              </CardActions>
            </Card>
          ))
        ) : (
          <div style={{ margin: '20px 0 30px 0' }}>No photos added yet.</div>
        )}
      </div>

      <Dialog open={modalOpen} onClose={handleModalClose} fullWidth>
        <DialogTitle id="responsive-dialog-title">Add new photo</DialogTitle>
        <DialogContent>
          <form>
            <input
              accept="image/*"
              type="file"
              id="newPhotoFile"
              onChange={(e: any) => setNewPhoto(e.target.files[0])}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleModalClose} color="primary">
            Cancel
          </Button>
          <Button color="primary" autoFocus onClick={handlePhotoAdd} disabled={!newPhoto}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CarPhotos;
