import React, { useCallback, useState } from 'react';
import {
  FunctionField,
  SimpleShowLayout,
  TextField,
  useDataProvider,
  useNotify,
  useRefresh,
} from 'react-admin';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField as InputTextField,
} from '@material-ui/core';
import { CarActions } from '../../../ra-queries/queries/cars';
import ConfirmDialog from '../../../components/ConfirmDialog';

const CarSalesInfo = (props: any) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const car = props.record;
  const salesInfo = props.record.salesInfo;

  const [modalOpen, setModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState<string>();
  const [confirmOpenDelete, setConfirmOpenDelete] = useState(false);

  const [editedSalesInfo, setEditedSalesInfo] = useState(salesInfo);
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleAddAction = () => {
    setModalOpen(true);
    setModalOpen(true);
    setModalAction('Add');
    setEditedSalesInfo({});
  };

  const handleEditAction = () => {
    if (salesInfo) {
      setModalOpen(true);
      setModalAction('Edit');
      setEditedSalesInfo(salesInfo);
    }
  };

  const handleEditCarSalesInfo = useCallback(
    async (record: any) => {
      return dataProvider
        .update('Cars', {
          id: car.id,
          data: { ...car, action: CarActions.UpdateCarSalesInfo, salesInfo: record },
          previousData: car,
        })
        .then(() => {
          refresh();
          notify('Car sales info has been Updated');
        })
        .catch((error) => {
          refresh();
          notify(`Car sales info could not be updated: ${error.message}`, 'warning');
        });
    },
    [dataProvider, notify, refresh, car],
  );

  const handleCreateCarSalesInfo = useCallback(
    async (record: any) => {
      return dataProvider
        .update('Cars', {
          id: car.id,
          data: { ...car, action: CarActions.AddCarSalesInfo, salesInfo: record },
          previousData: car,
        })
        .then(() => {
          refresh();
          notify('Car sales info has been Added');
        })
        .catch((error) => {
          refresh();
          notify(`Car sales info could not be added: ${error.message}`, 'warning');
        });
    },
    [dataProvider, notify, refresh, car],
  );

  const handleDeleteCarSalesInfo = useCallback(async () => {
    return dataProvider
      .update('Cars', {
        id: car.id,
        data: { ...car, action: CarActions.DeleteCarSalesInfo },
        previousData: car,
      })
      .then(() => {
        refresh();
        notify('Car sales info has been Deleted');
      })
      .catch((error) => {
        refresh();
        notify(`Car sales info could not be deleted: ${error.message}`, 'warning');
      });
  }, [dataProvider, notify, refresh, car]);

  return (
    <>
      {salesInfo && (
        <SimpleShowLayout {...props} className="two-columns-layout">
          <TextField source="salesInfo.id" label="Id" />
          <TextField source="salesInfo.description" label="Description" />
          <FunctionField
            source="salesInfo.price"
            label="Price"
            render={(sub: any) => {
              return `£${sub.salesInfo.price}`;
            }}
          />
          <TextField source="salesInfo.url" label="URL" />
          <TextField source="salesInfo.email" label="Email" />
          <TextField source="salesInfo.phoneNumber" label="Phone Number" />

          <div style={{ marginBottom: '20px', display: 'flex' }}>
            <Button variant="contained" color="primary" onClick={handleEditAction}>
              Edit Sales Info
            </Button>

            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: '20px' }}
              onClick={() => setConfirmOpenDelete(true)}
            >
              Delete Sales Info
            </Button>
          </div>
        </SimpleShowLayout>
      )}

      {!salesInfo && (
        <div style={{ marginTop: '20px', marginBottom: '20px', display: 'flex' }}>
          <Button variant="contained" color="primary" onClick={handleAddAction}>
            Create Sales Info
          </Button>
        </div>
      )}

      <Dialog open={modalOpen} onClose={handleModalClose} fullWidth>
        <DialogTitle id="responsive-dialog-title">{modalAction} Car Sales Info</DialogTitle>
        <DialogContent>
          <InputTextField
            label="URL"
            value={editedSalesInfo && editedSalesInfo.url}
            onChange={(e) => {
              setEditedSalesInfo({
                ...editedSalesInfo,
                url: e.target.value,
              });
            }}
            variant="outlined"
            fullWidth
            style={{
              marginBottom: '10px',
            }}
          />
          <InputTextField
            label="Description"
            value={editedSalesInfo && editedSalesInfo.description}
            onChange={(e) => {
              setEditedSalesInfo({
                ...editedSalesInfo,
                description: e.target.value,
              });
            }}
            variant="outlined"
            style={{
              marginBottom: '10px',
            }}
            fullWidth
            required
          />
          <InputTextField
            label="Price"
            value={editedSalesInfo && editedSalesInfo.price}
            onChange={(e) => {
              setEditedSalesInfo({
                ...editedSalesInfo,
                price: e.target.value,
              });
            }}
            variant="outlined"
            style={{
              marginBottom: '10px',
            }}
            fullWidth
            required
          />
          <InputTextField
            label="Email"
            value={editedSalesInfo && editedSalesInfo.email}
            onChange={(e) => {
              setEditedSalesInfo({
                ...editedSalesInfo,
                email: e.target.value,
              });
            }}
            variant="outlined"
            fullWidth
            style={{
              marginBottom: '10px',
            }}
          />
          <InputTextField
            label="Phone Number"
            value={editedSalesInfo && editedSalesInfo.phoneNumber}
            onChange={(e) => {
              setEditedSalesInfo({
                ...editedSalesInfo,
                phoneNumber: e.target.value,
              });
            }}
            variant="outlined"
            fullWidth
            style={{
              marginBottom: '10px',
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleModalClose} color="primary">
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => {
              if (modalAction === 'Edit') {
                handleEditCarSalesInfo(editedSalesInfo);
              } else {
                handleCreateCarSalesInfo(editedSalesInfo);
              }
            }}
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmDialog
        title="Delete Car Sales Info?"
        open={confirmOpenDelete}
        setOpen={setConfirmOpenDelete}
        onConfirm={() => handleDeleteCarSalesInfo()}
      >
        Do you want to delete this car sales info?
      </ConfirmDialog>
    </>
  );
};
export default CarSalesInfo;
