import React from 'react';
import { FunctionField } from 'react-admin';

const SubscriptionStatusField = (props: any) => (
  <FunctionField
    {...props}
    render={({ status, currentPeriodEnd }: any) => {
      if (status !== 'Canceled' || !currentPeriodEnd) {
        return status;
      }

      const isInactive = new Date().getTime() > new Date(currentPeriodEnd).getTime();

      if (isInactive) {
        return 'Cancelled, inactive';
      }

      return 'Cancelled upcoming renewal';
    }}
  />
);

export default SubscriptionStatusField;
