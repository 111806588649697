/* eslint-disable react/jsx-wrap-multilines */
import React, { useCallback, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { CarmakersActions } from '../../../ra-queries/queries/carmakers';

const useStyles = makeStyles({
  root: {
    marginBottom: '20px',
  },
});

const Carmodels = (props: any) => {
  const carMaker = props.record;
  const models = carMaker.models;
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const classes = useStyles();

  const [modalAddOpen, setModalAddOpen] = useState(false);
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [newModelName, setNewModelName] = useState('');
  const [editedModel, setEditedModel] = useState<any>();

  const handleModalAddClose = () => {
    setModalAddOpen(false);
  };

  const handleModalEditClose = () => {
    setModalEditOpen(false);
  };

  const handleCarmodelAdd = useCallback(
    async (carName: string) => {
      return dataProvider
        .update('Carmakers', {
          id: carMaker.id,
          data: {
            ...carMaker,
            action: CarmakersActions.CreateCarModel,
            name: carName,
            carMakerId: carMaker.id,
          },
          previousData: carMaker,
        })
        .then(() => {
          refresh();
          notify('Car model has been created');
          handleModalAddClose();
        })
        .catch((error) => {
          refresh();
          notify(`Car model could not be created: ${error.message}`, 'warning');
        });
    },
    [dataProvider, carMaker, refresh, notify],
  );

  const handleCarmodelEdit = useCallback(
    async (updatedModel) => {
      return dataProvider
        .update('Carmakers', {
          id: carMaker.id,
          data: {
            ...carMaker,
            action: CarmakersActions.UpdateCarModel,
            name: updatedModel.name,
            visible: updatedModel.visible,
            carModelId: updatedModel.id,
          },
          previousData: carMaker,
        })
        .then(() => {
          refresh();
          notify('Car model has been updated');
          handleModalEditClose();
        })
        .catch((error) => {
          refresh();
          notify(`Car model could not be updated: ${error.message}`, 'warning');
        });
    },
    [dataProvider, carMaker, refresh, notify],
  );

  const handleCarmodelDelete = useCallback(
    async (carId: string) => {
      return dataProvider
        .update('Carmakers', {
          id: carMaker.id,
          data: {
            ...carMaker,
            action: CarmakersActions.DeleteCarModel,
            carId,
          },
          previousData: carMaker,
        })
        .then(() => {
          refresh();
          notify('Car model has been deleted');
          handleModalAddClose();
        })
        .catch((error) => {
          refresh();
          notify(`Car model could not be deleted: ${error.message}`, 'warning');
        });
    },
    [dataProvider, carMaker, refresh, notify],
  );

  const handleAddAction = () => {
    setModalAddOpen(true);
    setNewModelName('');
  };

  const handleEditAction = (modelId: string) => {
    const newEditedModel = models.find((model: any) => model.id === modelId);
    if (newEditedModel) {
      setEditedModel(newEditedModel);
    }
    setModalEditOpen(true);
  };

  return (
    <>
      <div style={{ margin: '20px 0 30px 0' }}>
        <Button variant="contained" color="primary" onClick={handleAddAction}>
          Add car model
        </Button>
      </div>
      {models.length > 0 ? (
        models.map((model: any) => (
          <Card key={model.id} className={classes.root}>
            <CardContent>
              <Typography variant="h6" display="block" gutterBottom>
                {model.name}
              </Typography>
              <Typography variant="overline" display="block" gutterBottom>
                visible: {String(model.visible)}
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={() => handleEditAction(model.id)}
                startIcon={<EditIcon />}
              >
                Edit model
              </Button>
              <Button
                size="small"
                style={{ background: 'none', color: '#f44336' }}
                onClick={() => {
                  handleCarmodelDelete(model.id);
                }}
                startIcon={<DeleteIcon />}
              >
                Delete model
              </Button>
            </CardActions>
          </Card>
        ))
      ) : (
        <div style={{ margin: '20px 0 30px 0' }}>No models added yet.</div>
      )}
      <Dialog open={modalAddOpen} onClose={handleModalAddClose} fullWidth>
        <DialogTitle id="responsive-dialog-title">Add car model</DialogTitle>
        <DialogContent>
          <TextField
            id="car-model-name"
            label="Car model name"
            variant="outlined"
            value={newModelName}
            onChange={(e) => {
              setNewModelName(e.target.value);
            }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleModalAddClose} color="primary">
            Cancel
          </Button>
          <Button
            color="primary"
            autoFocus
            onClick={() => {
              handleCarmodelAdd(newModelName);
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {editedModel && (
        <Dialog open={modalEditOpen} onClose={handleModalEditClose} fullWidth>
          <DialogTitle id="responsive-dialog-title">Edit car model</DialogTitle>
          <DialogContent>
            <TextField
              id="car-model-name"
              label="Car model name"
              variant="outlined"
              value={editedModel.name}
              onChange={(e) => {
                setEditedModel({
                  ...editedModel,
                  name: e.target.value,
                });
              }}
              fullWidth
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={editedModel.visible}
                  onChange={(e) => {
                    setEditedModel({ ...editedModel, visible: e.target.checked });
                  }}
                  name="active"
                />
              }
              label="Active"
            />
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleModalEditClose} color="primary">
              Cancel
            </Button>
            <Button
              color="primary"
              autoFocus
              onClick={() => {
                handleCarmodelEdit(editedModel);
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default Carmodels;
