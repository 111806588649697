import { RaListParams } from '../interfaces/RaQueries';
import { ApiListParams } from '../interfaces/ApiQueries';

interface SubscriptionsListParams extends ApiListParams {
  onlyDueToRenewal?: boolean;
  bundleTypeId?: string;
  resellerBusinessId?: string;
}

const getSubscriptionsListParams = ({
  pagination,
  filter,
}: RaListParams): SubscriptionsListParams => {
  const { perPage, page } = pagination;
  const limit = perPage;
  const offset = (page - 1) * (perPage || 1);

  return {
    limit,
    offset,
    onlyDueToRenewal: filter.onlyDueToRenewal,
    searchPhrase: filter.q,
    bundleTypeId: filter.bundleTypeId,
    resellerBusinessId: filter.resellerBusinessId,
  };
};

export default getSubscriptionsListParams;
