import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-admin';
import { displayDateFromTimestamp } from '../../../utils/stringHelpers';

const useStyles = makeStyles({
  root: {
    marginBottom: '20px',
  },
});

const UserSubscriptions = (props: any) => {
  const subscriptions = props.record.subscriptions;
  const classes = useStyles();

  return (
    <>
      {subscriptions.length > 0 ? (
        subscriptions.map((subscription: any) => (
          <Card key={subscription.id} className={classes.root}>
            <CardContent>
              <Typography variant="overline" display="block" gutterBottom>
                ID: <Link to={`/Subscriptions/${subscription.id}/show`}>{subscription.id}</Link>
              </Typography>
              <Typography variant="overline" display="block" gutterBottom>
                Status: {subscription.status}
              </Typography>
              <Typography variant="overline" display="block" gutterBottom>
                Created At: {displayDateFromTimestamp(subscription.createdAt)}
              </Typography>
              <Typography variant="overline" display="block" gutterBottom>
                Code: {subscription.code}
              </Typography>
            </CardContent>
          </Card>
        ))
      ) : (
        <div style={{ margin: '20px 0 30px 0' }}>User does not have any subscriptions.</div>
      )}
    </>
  );
};

export default UserSubscriptions;
