import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DeleteButton, SaveButton, Toolbar, ToolbarProps } from 'react-admin';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export interface SimpleConfirmDeleteToolbarProps extends ToolbarProps {
  onSave?: any;
  transform?: any;
}

export const SimpleConfirmDeleteToolbar = (props: SimpleConfirmDeleteToolbarProps) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton onSave={props.onSave} transform={props.transform} />
    <DeleteButton mutationMode="pessimistic" confirmTitle="Delete item" />
  </Toolbar>
);
