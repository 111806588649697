/* eslint-disable no-underscore-dangle */
import { gql } from '@apollo/client';
import _omit from 'lodash.omit';
import {
  RaCreateParams,
  RaFetchType,
  RaGetResponse,
  RaListParams,
  RaListResponse,
  RaQuery,
  RaUpdateParams,
} from '../../interfaces/RaQueries';
import { CarType } from '../../interfaces/types.generated';
import { ApiResponse } from '../../interfaces/ApiQueries';
import getCarsListParams from '../../utils/getCarsListParams';

interface ListResult {
  carsV2: {
    overallCount: number;
    cars: Array<CarType>;
  };
}

export enum CarActions {
  ApproveCarUpdate = 'APPROVE_CAR_UPDATE',
  RejectCarUpdate = 'REJECT_CAR_UPDATE',
  UpdateCarFeatures = 'UPDATE_CAR_FEATURES',
  RemoveCarReview = 'REMOVE_CAR_REVIEW',
  AddCarPhoto = 'ADD_CAR_PHOTO',
  RemoveCarPhoto = 'REMOVE_CAR_PHOTO',
  AddCarDamagePhoto = 'ADD_CAR_DAMAGE_PHOTO',
  RemoveCarDamagePhoto = 'REMOVE_CAR_DAMAGE_PHOTO',
  SetCarMainImage = 'SET_CAR_MAIN_IMAGE',
  AddAvailabilityPeriod = 'ADD_AVAILABILITY_PERIOD',
  RemoveAvailabilityPeriod = 'REMOVE_AVAILABILITY_PERIOD',
  UpdateCarGuide = 'UPDATE_CAR_GUIDE',
  UpdateCarLocationTips = 'UPDATE_CAR_LOCATION_TIPS',
  UpdateCarLocation = 'UPDATE_CAR_LOCATION',
  AddCarSalesInfo = 'ADD_CAR_SALES_INFO',
  UpdateCarSalesInfo = 'UPDATE_CAR_SALES_INFO',
  DeleteCarSalesInfo = 'DELETE_CAR_SALES_INFO',
}

const Cars = (raFetchType: RaFetchType, params: any): RaQuery => {
  switch (raFetchType) {
    case 'CREATE':
      return {
        query: gql`
          mutation ($businessId: String!, $registration: String!) {
            createCar(input: { businessId: $businessId, registration: $registration }) {
              id
            }
          }
        `,
        variables: {
          businessId: params.data.businessId,
          registration: params.data.registration,
        },
        parseResponse: ({ data }) => ({
          data: data.createCar,
        }),
      };
    case 'GET_LIST':
      return {
        query: gql`
          query (
            $limit: Int
            $offset: Int
            $sort: CarsSortEnum
            $searchPhrase: String
            $withPendingDetailsRequestedOnly: Boolean
            $withDrafts: Boolean
          ) {
            carsV2(
              params: {
                limit: $limit
                offset: $offset
                sort: $sort
                searchPhrase: $searchPhrase
                withPendingDetailsRequestedOnly: $withPendingDetailsRequestedOnly
                withDrafts: $withDrafts
              }
            ) {
              overallCount
              cars {
                id
                visible
                createdAt
                isDraft
                details {
                  id
                  registration
                  vin
                  type
                  motor
                  maker {
                    id
                    name
                  }
                  model {
                    id
                    name
                  }
                  approveStatus
                  rejectionReason
                }
                isAvailableToBuy
                detailsRequested {
                  approveStatus
                  rejectionReason
                }
                user {
                  id
                  details {
                    firstName
                    lastName
                  }
                }
                business {
                  name
                  displayName
                }
                availabilityPeriods {
                  id
                }
              }
            }
          }
        `,
        variables: getCarsListParams(params as RaListParams),
        parseResponse: ({ data }: ApiResponse<ListResult>): RaListResponse<CarType> => ({
          data: data.carsV2.cars,
          total: data.carsV2.overallCount,
        }),
      };
    case 'GET_ONE':
      return {
        query: gql`
          query ($id: String!) {
            carGuide(carId: $id)
            carLocationTips(carId: $id)
            carRealLocation(carId: $id) {
              lat
              lon
            }
            car(id: $id) {
              id
              visible
              reviewsAverageScore
              reviewsCount
              pricePerHour
              pricePerWeek
              pricePerDay
              details {
                id
                registration
                vin
                type
                motor
                maker {
                  id
                  name
                }
                model {
                  id
                  name
                }
                year
                value
                mileage
                doors
                seats
                transmission
                color
                range
                approveStatus
                rejectionReason
                images {
                  id
                  url
                }
                mainImageId
                description
                existingDamageDescription
                existingDamageImages {
                  id
                  url
                }
              }
              addressPublic
              addressPrivate
              detailsRequested {
                id
                registration
                vin
                type
                motor
                maker {
                  id
                  name
                }
                model {
                  id
                  name
                }
                year
                value
                mileage
                doors
                seats
                transmission
                color
                range
                approveStatus
                rejectionReason
                mainImageId
                images {
                  id
                  url
                }
                description
                existingDamageDescription
                existingDamageImages {
                  id
                  url
                }
              }
              reviews {
                id
                score
                note
                reviewType
                resourceId
                user {
                  id
                  details {
                    firstName
                    lastName
                  }
                }
                business {
                  id
                  name
                }
              }
              features {
                id
                name
                type
              }
              flags {
                isTaxed
                isMotValid
                isInsuredByOwner
              }
              isAvailableToBuy
              salesInfo {
                id
                price
                url
                email
                phoneNumber
                description
              }
              availabilityPeriods {
                id
                timeStart
                timeEnd
                allDayDate
                chainId
              }
            }
          }
        `,
        variables: params,
        parseResponse: ({ data }): RaGetResponse<CarType> => ({
          data: {
            ...data.car,
            pricePerHour: data.car.pricePerHour ? Number(data.car.pricePerHour) / 100 : null,
            pricePerWeek: data.car.pricePerWeek ? Number(data.car.pricePerWeek) / 100 : null,
            pricePerDay: data.car.pricePerDay ? Number(data.car.pricePerDay) / 100 : null,
            carGuide: data.carGuide,
            carLocationTips: data.carLocationTips,
            carRealLocation: data.carRealLocation,
          },
        }),
      };
    case 'UPDATE':
      if (params.data.action === CarActions.ApproveCarUpdate) {
        return {
          query: gql`
            mutation acceptCarDetailsUpdate($carId: String!) {
              acceptCarDetailsUpdate(carId: $carId) {
                id
              }
            }
          `,
          variables: { carId: params.id },
          parseResponse: (): RaGetResponse<CarType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === CarActions.RejectCarUpdate) {
        return {
          query: gql`
            mutation rejectCarDetailsUpdate($carId: String!, $rejectionReason: String!) {
              rejectCarDetailsUpdate(carId: $carId, rejectionReason: $rejectionReason) {
                id
              }
            }
          `,
          variables: { carId: params.id, rejectionReason: params.data.rejectionReason },
          parseResponse: (): RaGetResponse<CarType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === CarActions.UpdateCarFeatures) {
        return {
          query: gql`
            mutation updateCarFeatures($features: [String!]!, $carId: String!) {
              updateCarFeatures(carId: $carId, features: $features) {
                id
              }
            }
          `,
          variables: { carId: params.id, features: params.data.newFeatures },
          parseResponse: (): RaGetResponse<CarType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === CarActions.RemoveCarReview) {
        return {
          query: gql`
            mutation deleteReview($reviewId: String!) {
              deleteReview(reviewId: $reviewId)
            }
          `,
          variables: { reviewId: params.data.reviewId },
          parseResponse: (): RaGetResponse<CarType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === CarActions.RemoveCarPhoto) {
        return {
          query: gql`
            mutation updateCarDetails($updateInput: CarDetailsUpdateInputType!, $carId: String!) {
              updateCarDetails(updateInput: $updateInput, carId: $carId) {
                id
              }
            }
          `,
          variables: {
            updateInput: {
              images: params.data.details.images
                .map((image: any) => image.id)
                .filter((image: any) => image !== params.data.photoId),
              mainImageId: params.data.photoId === params.mainImageId ? null : params.mainImageId,
            },
            carId: params.id,
          },
          parseResponse: (): RaGetResponse<CarType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === CarActions.RemoveCarDamagePhoto) {
        return {
          query: gql`
            mutation updateCarDetails($updateInput: CarDetailsUpdateInputType!, $carId: String!) {
              updateCarDetails(updateInput: $updateInput, carId: $carId) {
                id
              }
            }
          `,
          variables: {
            updateInput: {
              existingDamageImages: params.data.details.existingDamageImages
                .map((image: any) => image.id)
                .filter((image: any) => image !== params.data.photoId),
            },
            carId: params.id,
          },
          parseResponse: (): RaGetResponse<CarType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === CarActions.SetCarMainImage) {
        return {
          query: gql`
            mutation updateCarDetails($updateInput: CarDetailsUpdateInputType!, $carId: String!) {
              updateCarDetails(updateInput: $updateInput, carId: $carId) {
                id
                details {
                  mainImageId
                }
              }
            }
          `,
          variables: {
            updateInput: {
              mainImageId: params.data.mainImageId,
            },
            carId: params.id,
          },
          parseResponse: (): RaGetResponse<CarType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === CarActions.AddAvailabilityPeriod) {
        return {
          query: gql`
            mutation addCarAvailabilityPeriod($input: AddCarAvailabilityPeriodInputType!) {
              addCarAvailabilityPeriod(input: $input) {
                id
              }
            }
          `,
          variables: { input: _omit(params.data, 'action') },
          parseResponse: (): RaGetResponse<CarType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === CarActions.RemoveAvailabilityPeriod) {
        return {
          query: gql`
            mutation deleteCarAvailabilityPeriod($periodId: String!) {
              deleteCarAvailabilityPeriod(periodId: $periodId) {
                id
              }
            }
          `,
          variables: { periodId: params.data.periodId },
          parseResponse: (): RaGetResponse<CarType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === CarActions.UpdateCarGuide) {
        return {
          query: gql`
            mutation updateCarGuide($guide: String!, $carId: String!) {
              updateCarGuide(guide: $guide, carId: $carId)
            }
          `,
          variables: { guide: params.data.carGuide, carId: params.id },
          parseResponse: (): RaGetResponse<CarType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === CarActions.UpdateCarLocationTips) {
        return {
          query: gql`
            mutation updateCarLocationTips($locationTips: String!, $carId: String!) {
              updateCarLocationTips(locationTips: $locationTips, carId: $carId)
            }
          `,
          variables: { locationTips: params.data.carLocationTips, carId: params.id },
          parseResponse: (): RaGetResponse<CarType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === CarActions.UpdateCarLocation) {
        return {
          query: gql`
            mutation updateCarLocation(
              $carId: String!
              $lat: Float!
              $lon: Float!
              $addressPublic: String!
              $addressPrivate: String!
            ) {
              updateCarLocation(
                carId: $carId
                lat: $lat
                lon: $lon
                addressPublic: $addressPublic
                addressPrivate: $addressPrivate
              ) {
                id
              }
            }
          `,
          variables: {
            carId: params.id,
            lat: Number(params.data.location.lat),
            lon: Number(params.data.location.lon),
            addressPublic: params.data.addressPublic,
            addressPrivate: params.data.addressPrivate,
          },
          parseResponse: (): RaGetResponse<CarType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === CarActions.AddCarSalesInfo) {
        return {
          query: gql`
            mutation createCarSalesInfo($carId: String!, $salesInfoInput: CarSalesInfoInputType!) {
              addCarSalesInfo(carId: $carId, salesInfoInput: $salesInfoInput) {
                id
              }
            }
          `,
          variables: {
            carId: params.id,
            salesInfoInput: {
              description: params.data.salesInfo.description,
              price: Number(params.data.salesInfo.price),
              url: params.data.salesInfo.url,
              email: params.data.salesInfo.email,
              phoneNumber: params.data.salesInfo.phoneNumber,
            },
          },
          parseResponse: (): RaGetResponse<CarType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === CarActions.UpdateCarSalesInfo) {
        return {
          query: gql`
            mutation updateCarSalesInfo($carId: String!, $salesInfoInput: CarSalesInfoInputType!) {
              updateCarSalesInfo(carId: $carId, salesInfoInput: $salesInfoInput) {
                id
              }
            }
          `,
          variables: {
            carId: params.id,
            salesInfoInput: {
              description: params.data.salesInfo.description,
              price: Number(params.data.salesInfo.price),
              url: params.data.salesInfo.url,
              email: params.data.salesInfo.email,
              phoneNumber: params.data.salesInfo.phoneNumber,
            },
          },
          parseResponse: (): RaGetResponse<CarType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === CarActions.DeleteCarSalesInfo) {
        return {
          query: gql`
            mutation deleteCarSalesInfo($carId: String!) {
              deleteCarSalesInfo(carId: $carId) {
                id
              }
            }
          `,
          variables: {
            carId: params.id,
          },
          parseResponse: (): RaGetResponse<CarType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      return {
        query: gql`
          mutation (
            $visible: Boolean!
            $updateInput: CarDetailsUpdateInputType!
            $flags: CarFlagsUpdateInput!
            $priceInput: UpdateCarPricesInputType!
            $id: String!
          ) {
            updateCarDetails(updateInput: $updateInput, carId: $id) {
              id
            }
            setCarVisible(visible: $visible, carId: $id) {
              id
            }
            updateCarFlags(flags: $flags, carId: $id) {
              id
            }
            updateCarPrices(input: $priceInput, carId: $id) {
              id
            }
          }
        `,
        variables: {
          updateInput: {
            ..._omit(params.data.details, [
              'id',
              'maker',
              'model',
              'approveStatus',
              'rejectionReason',
              'reviews',
              'features',
              'detailsRequested',
              'images',
              'existingDamageImages',
              'flags',
            ]),
            carMakerId: params.data.details.maker?.id,
            carModelId: params.data.details.model?.id,
          },
          visible: params.data.visible,
          flags: params.data.flags,
          priceInput: {
            pricePerHour: Math.round(Number(params.data.pricePerHour) * 100),
            pricePerWeek: Math.round(Number(params.data.pricePerWeek) * 100),
            pricePerDay: Math.round(Number(params.data.pricePerDay) * 100),
          },
          id: (params as RaUpdateParams<CarType>).id,
        },
        parseResponse: ({ updateCarDetails }): RaGetResponse<CarType> => ({
          data: { id: params.id, ...updateCarDetails },
        }),
      };
    case 'DELETE':
      return {
        query: gql`
          mutation ($carId: String!) {
            deleteCar(carId: $carId)
          }
        `,
        variables: { carId: (params as RaCreateParams<CarType>).id },
        parseResponse: (): any => ({
          data: {},
        }),
      };
    default:
      throw new Error(`Unknown fetch type: ${raFetchType}`);
  }
};

export default Cars;
