import React from 'react';
import { BooleanInput, Edit, EditProps, required, SimpleForm } from 'react-admin';
import { SimpleConfirmDeleteToolbar } from '../../components/SimpleConfirmDeleteToolbar';
import StyledGridLayout from '../../components/StyledGridLayout';
import { StyledTextInput } from '../../components/StyledInputs';

const CarmakerEdit = (props: EditProps) => {
  return (
    <>
      <Edit {...props} title="Car Makers" undoable={false}>
        <SimpleForm redirect="show" toolbar={<SimpleConfirmDeleteToolbar />}>
          <StyledGridLayout>
            <StyledTextInput source="name" validate={required()} />
            <BooleanInput source="visible" />
          </StyledGridLayout>
        </SimpleForm>
      </Edit>
    </>
  );
};
export default CarmakerEdit;
