import { RaListParams } from '../interfaces/RaQueries';
import { ApiListParams } from '../interfaces/ApiQueries';
import { BundleRenewalRequestQueryOrderEnum } from '../interfaces/types.generated';

interface RenewalRequestsListParams extends ApiListParams {
  onlyDueToRenewal?: boolean;
}

const getRenewalRequestsListParams = ({
  pagination,
  sort,
  filter,
}: RaListParams): RenewalRequestsListParams => {
  const { perPage, page } = pagination;
  const limit = perPage;
  const offset = (page - 1) * (perPage || 1);

  let order: string | undefined;
  switch (`${sort.field}${sort.order}`) {
    case 'createdAtASC':
      order = BundleRenewalRequestQueryOrderEnum.CreatedAtAsc;
      break;
    case 'createdAtDESC':
      order = BundleRenewalRequestQueryOrderEnum.CreatedAtDesc;
      break;
    case 'actionedDateASC':
      order = BundleRenewalRequestQueryOrderEnum.ActionedDateAsc;
      break;
    case 'actionedDateDESC':
      order = BundleRenewalRequestQueryOrderEnum.ActionedDateAsc;
      break;
    default:
      order = BundleRenewalRequestQueryOrderEnum.CreatedAtDesc;
      break;
  }

  return {
    limit,
    offset,
    order,
    searchPhrase: filter.q,
    onlyDueToRenewal: filter.onlyDueToRenewal,
  };
};

export default getRenewalRequestsListParams;
