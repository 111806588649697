import { RaListParams } from '../interfaces/RaQueries';
import { ApiListParams } from '../interfaces/ApiQueries';

interface BookingsListParams extends ApiListParams {
  status?: string;
}

const getBookingsListParams = ({ pagination, filter }: RaListParams): BookingsListParams => {
  const { perPage, page } = pagination;
  const limit = perPage;
  const offset = (page - 1) * (perPage || 1);

  return { limit, offset, status: filter.status };
};

export default getBookingsListParams;
