import React, { useCallback, useEffect, useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { gql, useMutation } from '@apollo/client';
import { useNotify } from 'react-admin';
import * as dateFns from 'date-fns';

const GET_SELECTED_REPORT = gql`
  mutation prepareReport(
    $timeEnd: Float
    $timeStart: Float
    $report: String!
    $generateAsync: Boolean!
  ) {
    prepareReport(
      timeEnd: $timeEnd
      timeStart: $timeStart
      report: $report
      generateAsync: $generateAsync
    ) {
      url
      sentToEmail
    }
  }
`;

const DownloadReportButton = ({ timeEnd, timeStart, report }: any) => {
  const [getDownloadLink, { loading, data }] = useMutation(GET_SELECTED_REPORT);
  const [raportReady, setRaportReady] = useState(false);

  const notify = useNotify();

  useEffect(() => {
    setRaportReady(false);
  }, [timeEnd, timeStart, report]);

  const generateLink = useCallback(() => {
    const getLink = async () => {
      const convertedTimeStart = new Date(timeStart).getTime();
      // adding 23 hours, 59 minutes and 59 seconds to count the full day of the end date.
      const timeEndFullDay = dateFns.addSeconds(
        dateFns.addMinutes(dateFns.addHours(new Date(timeEnd), 23), 59),
        59,
      );
      const convertedTimeEnd = new Date(timeEndFullDay).getTime();

      try {
        await getDownloadLink({
          variables: {
            timeEnd: convertedTimeEnd,
            timeStart: convertedTimeStart,
            report,
            generateAsync: false,
          },
        });
        setRaportReady(true);
      } catch (error: any) {
        notify(`Report can't be created: ${error.message}`, 'warning');
      }
    };
    getLink();
  }, [timeEnd, timeStart, report, getDownloadLink, notify]);

  if (loading) return <CircularProgress color="secondary" />;

  if (data && data.prepareReport.url && raportReady) {
    return (
      <Button
        variant="contained"
        color="primary"
        style={{ background: '#22aa4f', color: 'white' }}
        href={data.prepareReport.url}
      >
        Download Report
      </Button>
    );
  }
  if (data && data.prepareReport.sentToEmail && raportReady) {
    return (
      <Button
        variant="contained"
        color="primary"
        style={{ background: '#22aa4f', color: 'white' }}
        href={data.prepareReport.url}
      >
        Report Sent to Email
      </Button>
    );
  }
  return (
    <Button variant="contained" color="primary" onClick={generateLink} disabled={!report}>
      Generate Report
    </Button>
  );
};

export default DownloadReportButton;
