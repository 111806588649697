/* eslint-disable no-underscore-dangle */
import { gql } from '@apollo/client';
import {
  RaCreateParams,
  RaFetchType,
  RaGetResponse,
  RaListResponse,
  RaParams,
  RaQuery,
} from '../../interfaces/RaQueries';
import { TagType } from '../../interfaces/types.generated';
import { ApiResponse } from '../../interfaces/ApiQueries';

interface ListResult {
  tags: Array<TagType>;
}

const Tags = (raFetchType: RaFetchType, params: RaParams<TagType>): RaQuery => {
  switch (raFetchType) {
    case 'GET_LIST':
      return {
        query: gql`
          query {
            tags {
              id
              name
            }
          }
        `,
        variables: {},
        parseResponse: ({ data }: ApiResponse<ListResult>): RaListResponse<TagType> => ({
          data: data.tags,
          total: data.tags.length,
        }),
      };
    case 'CREATE':
      return {
        query: gql`
          mutation ($name: String!) {
            createTag(name: $name) {
              id
            }
          }
        `,
        variables: { name: params.data.name },
        parseResponse: ({ data }): RaGetResponse<TagType> => ({
          data: data.createTag,
        }),
      };
    case 'DELETE':
      return {
        query: gql`
          mutation ($tagId: String!) {
            deleteTag(tagId: $tagId) {
              id
            }
          }
        `,
        variables: { tagId: (params as RaCreateParams<TagType>).id },
        parseResponse: ({ data }): RaGetResponse<TagType> => ({
          data: data.deleteTag,
        }),
      };
    default:
      throw new Error(`Unknown fetch type: ${raFetchType}`);
  }
};

export default Tags;
