import React from 'react';
import { Show, ShowProps, Tab, TabbedShowLayout, TextField, useShowController } from 'react-admin';
import StripeTestClock from './elements/StripeTestClock';

const StripeTestClockShow = (props: ShowProps) => {
  const { record } = useShowController(props);

  if (!record) return null;

  return (
    <>
      <Show {...props} title="Stripe Test Clock">
        <TabbedShowLayout>
          <Tab label="summary" contentClassName="two-columns-layout">
            <TextField source="id" />
            <TextField source="name" />
          </Tab>
          <Tab label="Customers" path="customers">
            <StripeTestClock />
          </Tab>
        </TabbedShowLayout>
      </Show>
    </>
  );
};
export default StripeTestClockShow;
