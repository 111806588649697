import styled from 'styled-components';

export const StyledColorInputPopup = styled.div`
  position: absolute;
  z-index: 2;
`;

export const StyledColorInputCover = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
