import React, { useCallback, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import OpenInNew from '@material-ui/icons/OpenInNew';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useDataProvider, useNotify, useRefresh } from 'react-admin';
import { displayDateFromTimestamp } from '../../../utils/stringHelpers';
import { LeasingEnquiryActions } from '../../../ra-queries/queries/leasingEnquiries';

const useStyles = makeStyles({
  root: {
    marginBottom: '20px',
  },
});

const LeasingEnquiryNotes = (props: any) => {
  const notes = props.record.notes;
  const leasingEnquiry = props.leasingEnquiry;
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const classes = useStyles();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState<string>();
  const [editedNote, setEditedNote] = useState('');
  const [editedNoteId, setEditedNoteId] = useState('');

  const handleLeasingEnquiryNoteAdd = useCallback(
    async (note: string, isDraft) => {
      if (note.length <= 0) return;
      return dataProvider
        .update('LeasingEnquiries', {
          id: leasingEnquiry.id,
          data: { ...leasingEnquiry, action: LeasingEnquiryActions.AddNote, note, isDraft },
          previousData: leasingEnquiry,
        })
        .then(() => {
          refresh();
          notify('Note has been added');
        })
        .catch((error) => {
          refresh();
          notify(`Note could not be added: ${error.message}`, 'warning');
        });
    },
    [dataProvider, notify, refresh, leasingEnquiry],
  );

  const handleLeasingEnquiryNoteEdit = useCallback(
    async (note: string, noteId: string, isDraft: boolean) => {
      if (note.length <= 0) return;
      return dataProvider
        .update('LeasingEnquiries', {
          id: leasingEnquiry.id,
          data: {
            ...leasingEnquiry,
            action: LeasingEnquiryActions.EditNote,
            note,
            noteId,
            isDraft,
          },
          previousData: leasingEnquiry,
        })
        .then(() => {
          refresh();
          notify('Note has been updated');
        })
        .catch((error) => {
          refresh();
          notify(`Note could not be updated: ${error.message}`, 'warning');
        });
    },
    [dataProvider, notify, refresh, leasingEnquiry],
  );

  const handleLeasingEnquiryNoteDelete = useCallback(
    async (noteId: string) => {
      return dataProvider
        .update('LeasingEnquiries', {
          id: leasingEnquiry.id,
          data: { ...leasingEnquiry, action: LeasingEnquiryActions.RemoveNote, noteId },
          previousData: leasingEnquiry,
        })
        .then(() => {
          refresh();
          notify('Note has been deleted');
        })
        .catch((error) => {
          refresh();
          notify(`Note could not be deleted: ${error.message}`, 'warning');
        });
    },
    [dataProvider, notify, refresh, leasingEnquiry],
  );

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleEditAction = (noteId: string) => {
    const oldNote = notes.find((note: any) => note.id === noteId);
    if (oldNote) {
      setModalOpen(true);
      setModalAction('Edit');
      setEditedNote(oldNote.note);
      setEditedNoteId(oldNote.id);
    }
  };

  const handleAddAction = () => {
    setModalOpen(true);
    setModalAction('Add');
    setEditedNote('');
  };

  return (
    <>
      <div style={{ margin: '20px 0 30px 0' }}>
        <Button variant="contained" color="primary" onClick={handleAddAction}>
          Add note
        </Button>
      </div>

      {notes.map((note: any) =>
        note.inviteId === undefined ? (
          <Card key={note.id} className={classes.root}>
            <CardContent>
              {note.isDraft && (
                <Chip
                  label={'draft'}
                  variant="outlined"
                  style={{
                    borderColor: '#ff8a36',
                    color: '#ff8a36',
                  }}
                />
              )}
              <Typography variant="overline" display="block" gutterBottom>
                {displayDateFromTimestamp(note.createdAt)}
              </Typography>
              {note.note}
            </CardContent>
            <CardActions>
              <Button
                size="small"
                color="primary"
                startIcon={<EditIcon />}
                onClick={() => handleEditAction(note.id)}
              >
                Edit note
              </Button>
              <Button
                size="small"
                style={{ background: 'none', color: '#f44336' }}
                onClick={() => handleLeasingEnquiryNoteDelete(note.id)}
                startIcon={<DeleteIcon />}
              >
                Delete note
              </Button>
            </CardActions>
          </Card>
        ) : (
          <Card key={note.id} className={classes.root}>
            <CardContent>
              <Link to={`/Invites/${note.inviteId}/show`}>
                <Chip
                  label={'Invite'}
                  variant="outlined"
                  icon={<OpenInNew style={{ color: '#528eff' }} />}
                  style={{
                    borderColor: '#528eff',
                    color: '#528eff',
                  }}
                />
              </Link>
              <Typography variant="overline" display="block" gutterBottom>
                {displayDateFromTimestamp(note.createdAt)}
              </Typography>
              {note.note}
            </CardContent>
          </Card>
        ),
      )}

      <Dialog
        open={modalOpen}
        onClose={() => {
          if (modalAction === 'Edit') {
            handleLeasingEnquiryNoteEdit(editedNote, editedNoteId, true);
          } else {
            handleLeasingEnquiryNoteAdd(editedNote, true);
          }
          handleModalClose();
        }}
        fullWidth
      >
        <DialogTitle id="responsive-dialog-title">{modalAction} user note</DialogTitle>
        <DialogContent>
          <TextareaAutosize
            rowsMin={3}
            value={editedNote}
            onChange={(e) => {
              setEditedNote(e.target.value);
            }}
            style={{ width: '100%' }}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleModalClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (modalAction === 'Edit') {
                handleLeasingEnquiryNoteEdit(editedNote, editedNoteId, false);
              } else {
                handleLeasingEnquiryNoteAdd(editedNote, false);
              }
            }}
            color="primary"
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LeasingEnquiryNotes;
