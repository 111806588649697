import React from 'react';
import { BooleanInput, Create, EditProps, required, SimpleForm, Toolbar } from 'react-admin';
import StyledGridLayout from '../../components/StyledGridLayout';
import { StyledTextInput } from '../../components/StyledInputs';
import SaveButtonAware from '../../components/SaveButtonAware';

const LeasingOfferCreateToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButtonAware />
  </Toolbar>
);

const LeasingOfferCreate = (props: EditProps) => (
  <Create {...props} title="Leasing Offer">
    <SimpleForm toolbar={<LeasingOfferCreateToolbar />} redirect="show">
      <StyledGridLayout>
        <StyledTextInput source="title" validate={[required()]} />
        <StyledTextInput source="subTitle" validate={[required()]} />
        <StyledTextInput source="tagline" validate={[required()]} />
        <StyledTextInput source="fromPrice" validate={[required()]} />
        <StyledTextInput source="initialPayment" validate={[required()]} />
        <StyledTextInput source="contractLength" validate={[required()]} />
        <StyledTextInput source="miles" validate={[required()]} />
        <BooleanInput source="vatIncluded" label="VAT Included" validate={[required()]} />
      </StyledGridLayout>
    </SimpleForm>
  </Create>
);

export default LeasingOfferCreate;
