import React from 'react';
import {
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  List,
  ListProps,
  TextField,
} from 'react-admin';
import { StyledSelectInput } from '../../components/StyledInputs';

const BookingsFilter = (props: any) => (
  <Filter {...props}>
    <StyledSelectInput
      source="status"
      label="Status"
      choices={[
        { id: 'WaitingForAcceptance', name: 'Waiting For Acceptance' },
        { id: 'Accepted', name: 'Accepted' },
        { id: 'Rejected', name: 'Rejected' },
        { id: 'Finished', name: 'Finished' },
        { id: 'Incidented', name: 'Incidented' },
        { id: 'Cancelled', name: 'Cancelled' },
      ]}
      alwaysOn
    />
  </Filter>
);

const bookingRowStyle = (record: any) => ({
  backgroundColor: record.status === 'Incidented' ? '#eecdca' : 'white',
});

const BookingsList = (props: ListProps) => (
  <List {...props} bulkActionButtons={false} filters={<BookingsFilter />}>
    <Datagrid rowClick="show" rowStyle={bookingRowStyle}>
      <TextField source="displayId" sortable={false} label={'Booking No.'} />
      <FunctionField
        label="Owner"
        sortable={false}
        render={(record: any) => {
          const car = record.car;
          const isBusiness = Boolean(car.business);
          const isPrivate = Boolean(car.user && car.user.details);
          if (isBusiness) {
            return car.business.name;
          }
          if (isPrivate) {
            return `${car.user.details?.firstName} ${car.user.details?.lastName}`;
          }
          return '-';
        }}
      />
      <FunctionField
        label="Renter"
        sortable={false}
        render={(record: any) => {
          return `${record.user.details?.firstName} ${record.user.details?.lastName}`;
        }}
      />
      <TextField source="car.details.registration" label="Registration Plate" sortable={false} />
      <TextField source="status" sortable={false} />
      <TextField source="paymentStatus" sortable={false} />
      <DateField source="timeStart" sortable={false} showTime />
      <DateField source="timeEnd" sortable={false} showTime />
      <DateField source="createdAt" sortable={false} showTime />
    </Datagrid>
  </List>
);

export default BookingsList;
