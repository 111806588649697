import React, { SVGProps, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { Button, ButtonGroup, Grid, TextField } from '@material-ui/core';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { CarActions } from '../../../ra-queries/queries/cars';

interface LatLon {
  lat: number;
  lon: number;
}

const MarkIcon = (props: SVGProps<SVGSVGElement> & { lat?: number; lng?: number }) => {
  return (
    <svg
      fill="none"
      height="34"
      viewBox="0 0 23 34"
      width="23"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={{
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        bottom: '-15px',
      }}
    >
      <path
        d="M11.25 0C5.047 0 0 4.957 0 11.05c0 7.964 8.969 21.396 9.35 21.964.414.614 1.13.986 1.9.986s1.486-.372 1.9-.986c.381-.568 9.35-14 9.35-21.964C22.5 4.957 17.453 0 11.25 0zm0 15.298c-2.485 0-4.5-1.896-4.5-4.245 0-2.35 2.015-4.255 4.5-4.255 2.487 0 4.5 1.905 4.5 4.255 0 2.348-2.013 4.245-4.5 4.245z"
        fill="#000"
      />
    </svg>
  );
};

interface CarAddressInputProps {
  label: string;
  value: string;
  onChange: (value: string) => any;
}

const CarAddressInput = (props: CarAddressInputProps) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      label={props.label}
      value={props.value}
      onChange={(e) => props.onChange(e.currentTarget.value)}
      size="small"
    />
  );
};

export const CarMapLocation = (props: any) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const [newLocation, setNewLocation] = useState<LatLon | undefined>(undefined);
  const [newAddressPublic, setNewAddressPublic] = useState<string | undefined>(undefined);
  const [newAddressPrivate, setNewAddressPrivate] = useState<string | undefined>(undefined);

  const carRealLocation = props.record.carRealLocation as LatLon | undefined;

  const resetValues = () => {
    setNewLocation(undefined);
    setNewAddressPublic(undefined);
    setNewAddressPrivate(undefined);
  };

  const canBeSaved =
    newLocation !== undefined || newAddressPublic !== undefined || newAddressPrivate !== undefined;

  const saveChanges = async () => {
    if (!canBeSaved) {
      return;
    }

    await dataProvider
      .update('Cars', {
        id: props.record.id,
        data: {
          action: CarActions.UpdateCarLocation,
          location: newLocation || carRealLocation,
          addressPublic: newAddressPublic || props.record.addressPublic,
          addressPrivate: newAddressPrivate || props.record.addressPrivate,
        },
        previousData: props.record,
      })
      .then(() => {
        refresh();
        notify('Car location has been updated');
      })
      .catch((error) => {
        refresh();
        notify(`Car location could not be updated: ${error.message}`, 'warning');
      });
  };

  const parseGeocodeResults = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
    const ShouldBeComponent = {
      home: ['street_number'],
      postal_code: ['postal_code'],
      street: ['street_address', 'route'],
      region: [
        'administrative_area_level_1',
        'administrative_area_level_2',
        'administrative_area_level_3',
        'administrative_area_level_4',
        'administrative_area_level_5',
      ],
      city: [
        'locality',
        'sublocality',
        'sublocality_level_1',
        'sublocality_level_2',
        'sublocality_level_3',
        'sublocality_level_4',
      ],
      country: ['country'],
    };

    const address = {
      home: '',
      postal_code: '',
      street: '',
      region: '',
      city: '',
      country: '',
    };

    type Keys = keyof typeof ShouldBeComponent;

    addressComponents.forEach((component) => {
      Object.entries(ShouldBeComponent).forEach((key) => {
        key[1].forEach((entry: any) => {
          if (entry.indexOf(component.types[0]) !== -1) {
            address[key[0] as Keys] = component.long_name;
          }
        });
      });
    });
    return address;
  };

  const generateAddresses = (lat: number, lng: number) => {
    const latlng = {
      lat,
      lng,
    };
    new google.maps.Geocoder().geocode(
      { location: latlng, region: 'uk' },
      (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
        if (status === 'OK') {
          if (results[0]) {
            const { home, postal_code, street, city, country } = parseGeocodeResults(
              results[0].address_components,
            );
            const privateAdr = `${home && street ? `${home} ${street}, ` : ''}${
              city ? `${city}, ` : ''
            }${country ? `${country}, ` : ''}${postal_code ? `${postal_code}` : ''}`;

            const publicAdr = `${country ? `${country}, ` : ''}${
              postal_code ? `${postal_code}` : ''
            }`;

            setNewAddressPrivate(privateAdr.trim().replace(/(\r\n|\n|\r)/gm, ''));
            setNewAddressPublic(publicAdr.replace(/(\r\n|\n|\r)/gm, ''));
          }
        }
      },
    );
  };

  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={12}
        style={{
          paddingTop: 10,
          paddingBottom: 20,
        }}
      >
        <ButtonGroup>
          <Button variant="outlined" color="primary" disabled={!canBeSaved} onClick={saveChanges}>
            Save location
          </Button>
          <Button variant="outlined" color="secondary" disabled={!canBeSaved} onClick={resetValues}>
            Reset changes
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item container xs={12} spacing={1} style={{ marginBottom: 10 }}>
        <Grid item xs={12} md={3}>
          <TextField
            label="Latitude"
            value={newLocation?.lat || carRealLocation?.lat || 0}
            fullWidth
            variant="outlined"
            size="small"
            type="number"
            onChange={(event) => {
              setNewLocation({
                lat: event.target.value as unknown as number,
                // eslint-disable-next-line no-nested-ternary
                lon: newLocation ? newLocation.lon : carRealLocation ? carRealLocation.lon : 0,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            label="Longitude"
            value={newLocation?.lon || carRealLocation?.lon || 0}
            fullWidth
            variant="outlined"
            size="small"
            type="number"
            onChange={(event) => {
              setNewLocation({
                lon: event.target.value as unknown as number,
                // eslint-disable-next-line no-nested-ternary
                lat: newLocation ? newLocation.lat : carRealLocation ? carRealLocation.lat : 0,
              });
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <CarAddressInput
          label="Public Address"
          value={newAddressPublic || props.record.addressPublic || ''}
          onChange={(value: string) => setNewAddressPublic(value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CarAddressInput
          label="Private Address"
          value={newAddressPrivate || props.record.addressPrivate || ''}
          onChange={(value: string) => setNewAddressPrivate(value)}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          height: 600,
          marginTop: 30,
        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
          }}
          defaultZoom={10}
          defaultCenter={{
            lat: carRealLocation?.lat || 51.509865,
            lng: carRealLocation?.lon || -0.118092,
          }}
          onClick={({ lat, lng }) => {
            setNewLocation({
              lat,
              lon: lng,
            });
            generateAddresses(lat, lng);
          }}
        >
          {(newLocation || carRealLocation) && (
            <MarkIcon
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              lat={newLocation ? newLocation.lat : carRealLocation?.lat}
              lng={newLocation ? newLocation.lon : carRealLocation?.lon}
            />
          )}
        </GoogleMapReact>
      </Grid>
    </Grid>
  );
};
