import { gql } from '@apollo/client';
import {
  RaFetchType,
  RaGetResponse,
  RaListParams,
  RaListResponse,
  RaQuery,
  RaUpdateParams,
} from '../../interfaces/RaQueries';
import { BundleBenefitRequestType, User } from '../../interfaces/types.generated';
import { ApiResponse } from '../../interfaces/ApiQueries';
import getUserUpdateParams from '../../utils/getUserUpdateParams';
import getUsersListParams from '../../utils/getUsersListParams';

interface ListResult {
  usersV2: {
    users: Array<User>;
    overallCount: number;
  };
}

export enum UserActions {
  GrantAdminPrivileges = 'GRANT_ADMIN_PRIVILEGES',
  RevokeAdminPrivileges = 'REVOKE_ADMIN_PRIVILEGES',
  ApproveUserUpdate = 'APPROVE_USER_UPDATE',
  RejectUserUpdate = 'REJECT_USER_UPDATE',
  AddUserTag = 'ADD_USER_TAG',
  RemoveUserTag = 'REMOVE_USER_TAG',
  AddUserNote = 'ADD_USER_NOTE',
  EditUserNote = 'EDIT_USER_NOTE',
  RemoveUserNote = 'REMOVE_USER_NOTE',
  RemoveUserReview = 'REMOVE_USER_REVIEW',
  BlockUser = 'BLOCK_USER',
  UnblockUser = 'UNBLOCK_USER',
  DeleteUser = 'DELETE_USER',
  OrderPlugsurfingKey = 'ORDER_PLUGSURFING_KEY',
}

const Users = (raFetchType: RaFetchType, params: any): RaQuery => {
  switch (raFetchType) {
    case 'GET_LIST':
      return {
        query: gql`
          query (
            $limit: Int
            $offset: Int
            $order: UsersOrderEnum
            $searchPhrase: String
            $onlyWithRequestedChanges: Boolean
            $validAsCarOwner: Boolean
            $validAsCarRenter: Boolean
          ) {
            usersV2(
              params: {
                limit: $limit
                offset: $offset
                order: $order
                searchPhrase: $searchPhrase
                onlyWithRequestedChanges: $onlyWithRequestedChanges
                validAsCarOwner: $validAsCarOwner
                validAsCarRenter: $validAsCarRenter
              }
            ) {
              users {
                id
                email
                validAsCarOwner
                validAsCarRenter
                business {
                  id
                }
                details {
                  firstName
                  lastName
                  approvalStatus
                }
                detailsRequested {
                  approvalStatus
                }
                createdAt
              }
              overallCount
            }
          }
        `,
        variables: getUsersListParams(params as RaListParams),
        parseResponse: ({ data }: ApiResponse<ListResult>): RaListResponse<User> => ({
          data: data.usersV2.users,
          total: data.usersV2.overallCount,
        }),
      };
    case 'GET_ONE':
      return {
        query: gql`
          query ($id: String!) {
            user(id: $id) {
              id
              email
              createdAt
              isAdmin
              bannedAt
              validAsCarOwner
              validAsCarRenter
              communicationPreferences {
                email
                emailAllowed
                phoneAllowed
                smsAllowed
              }
              paymentMethods {
                brand
              }
              stripeConnectedAccountId
              stripeConnectedAccountUrl
              stripeCustomerId
              stripeCustomerUrl
              business {
                id
              }
              details {
                firstName
                lastName
                phoneNumber
                dateOfBirth
                addressLine1
                addressLine2
                city
                county
                postCode
                drivingLicenseNumber
                drivingLicenseValidFrom
                drivingLicenseValidTo
                drivingLicenseCountryOfIssue
                drivingRecordCheckCode
                drivingLicenseFrontImage {
                  url
                }
                drivingLicenseSelfieImage {
                  url
                }
                passportFrontImage {
                  url
                }
                avatarImage {
                  url
                }
                approvalStatus
                rejectionReason
              }
              detailsRequested {
                firstName
                lastName
                phoneNumber
                dateOfBirth
                addressLine1
                addressLine2
                city
                county
                postCode
                drivingLicenseNumber
                drivingLicenseValidFrom
                drivingLicenseValidTo
                drivingLicenseCountryOfIssue
                drivingRecordCheckCode
                drivingLicenseFrontImage {
                  url
                }
                drivingLicenseSelfieImage {
                  url
                }
                passportFrontImage {
                  url
                }
                avatarImage {
                  url
                }
                approvalStatus
                rejectionReason
              }
              internalDetails {
                restrictionsOnLicense
                numberOfPoints
                isThreeWayVerified
                notes {
                  id
                  note
                  isDraft
                  createdAt
                }
              }
              subscriptions {
                id
                status
                currentPeriodStart
                currentPeriodEnd
                code
                createdAt
              }
              invites {
                id
                status
                createdAt
                notes {
                  id
                  note
                  createdAt
                }
              }
              reviews {
                id
                score
                note
                reviewType
                resourceId
                user {
                  id
                  details {
                    firstName
                    lastName
                  }
                }
                business {
                  id
                  name
                }
              }
              tags {
                id
                name
              }
            }
          }
        `,
        variables: params,
        parseResponse: ({ data }): RaGetResponse<User> => ({
          data: data.user,
        }),
      };
    case 'UPDATE':
      if (params.data.action === UserActions.DeleteUser) {
        return {
          query: gql`
            mutation deleteUser($userId: String!) {
              deleteUser(userId: $userId) {
                deleted
              }
            }
          `,
          variables: { userId: params.id },
          parseResponse: ({ deleteUser }): RaGetResponse<User> => ({
            data: { id: params.id, ...deleteUser },
          }),
        };
      }
      if (params.data.action === UserActions.GrantAdminPrivileges) {
        return {
          query: gql`
            mutation grantAdminPrivileges($userId: String!) {
              grantAdminPrivileges(userId: $userId) {
                id
              }
            }
          `,
          variables: { userId: params.id },
          parseResponse: ({ grantAdminPrivileges }): RaGetResponse<User> => ({
            data: { id: params.id, ...grantAdminPrivileges },
          }),
        };
      }
      if (params.data.action === UserActions.RevokeAdminPrivileges) {
        return {
          query: gql`
            mutation revokeAdminPrivileges($userId: String!) {
              revokeAdminPrivileges(userId: $userId) {
                id
              }
            }
          `,
          variables: { userId: params.id },
          parseResponse: ({ revokeAdminPrivileges }): RaGetResponse<User> => ({
            data: { id: params.id, ...revokeAdminPrivileges },
          }),
        };
      }
      if (params.data.action === UserActions.ApproveUserUpdate) {
        return {
          query: gql`
            mutation approveUserDetailsChange($userId: String!) {
              approveUserDetailsChange(userId: $userId) {
                id
              }
            }
          `,
          variables: { userId: params.id },
          parseResponse: (): RaGetResponse<User> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === UserActions.RejectUserUpdate) {
        return {
          query: gql`
            mutation rejectUserDetailsChange($userId: String!, $rejectionReason: String!) {
              rejectUserDetailsChange(userId: $userId, rejectionReason: $rejectionReason) {
                id
              }
            }
          `,
          variables: { userId: params.id, rejectionReason: params.data.rejectionReason },
          parseResponse: (): RaGetResponse<User> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === UserActions.AddUserTag) {
        return {
          query: gql`
            mutation appendTagToUser($userId: String!, $tagId: String!) {
              appendTagToUser(userId: $userId, tagId: $tagId) {
                id
              }
            }
          `,
          variables: { userId: params.id, tagId: params.data.tagId },
          parseResponse: (): RaGetResponse<User> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === UserActions.RemoveUserTag) {
        return {
          query: gql`
            mutation detachTagFromUser($userId: String!, $tagId: String!) {
              detachTagFromUser(userId: $userId, tagId: $tagId) {
                id
              }
            }
          `,
          variables: { userId: params.id, tagId: params.data.tagId },
          parseResponse: (): RaGetResponse<User> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === UserActions.AddUserNote) {
        return {
          query: gql`
            mutation createInternalUserNote($userId: String!, $note: String!, $isDraft: Boolean!) {
              createInternalUserNote(userId: $userId, note: $note, isDraft: $isDraft) {
                id
                note
                isDraft
                createdAt
              }
            }
          `,
          variables: { userId: params.id, note: params.data.note, isDraft: params.data.isDraft },
          parseResponse: (): RaGetResponse<User> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === UserActions.EditUserNote) {
        return {
          query: gql`
            mutation updateInternalUserNote($noteId: String!, $note: String!, $isDraft: Boolean!) {
              updateInternalUserNote(noteId: $noteId, note: $note, isDraft: $isDraft) {
                id
                note
                isDraft
                createdAt
              }
            }
          `,
          variables: {
            noteId: params.data.noteId,
            note: params.data.note,
            isDraft: params.data.isDraft,
          },
          parseResponse: (): RaGetResponse<User> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === UserActions.RemoveUserNote) {
        return {
          query: gql`
            mutation deleteInternalUserNote($noteId: String!) {
              deleteInternalUserNote(noteId: $noteId) {
                id
              }
            }
          `,
          variables: { noteId: params.data.noteId },
          parseResponse: (): RaGetResponse<User> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === UserActions.RemoveUserReview) {
        return {
          query: gql`
            mutation deleteReview($reviewId: String!) {
              deleteReview(reviewId: $reviewId)
            }
          `,
          variables: { reviewId: params.data.reviewId },
          parseResponse: (): RaGetResponse<User> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === UserActions.BlockUser) {
        return {
          query: gql`
            mutation banUser($userId: String!) {
              banUser(userId: $userId) {
                id
              }
            }
          `,
          variables: { userId: params.id },
          parseResponse: (): RaGetResponse<User> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === UserActions.UnblockUser) {
        return {
          query: gql`
            mutation unbanUser($userId: String!) {
              unbanUser(userId: $userId) {
                id
              }
            }
          `,
          variables: { userId: params.id },
          parseResponse: (): RaGetResponse<User> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === UserActions.OrderPlugsurfingKey) {
        return {
          query: gql`
            mutation createPlugSurfingBenefitRequest($userId: String!) {
              createPlugSurfingBenefitRequest(userId: $userId) {
                id
                userId
                businessId
                createdAt
                details
                status
                type
              }
            }
          `,
          variables: { userId: params.id },
          parseResponse: (benefitRequest): RaGetResponse<BundleBenefitRequestType> => ({
            data: { id: params.id, ...params.data, benefitRequest },
          }),
        };
      }
      // STANDARD USER UPDATE ACTION
      return {
        query: gql`
          mutation (
            $updateUserDetailsInput: UserDetailsUpdateInput!
            $updateUserInternalDetailsInput: UserInternalDetailsUpdateInput!
            $preferencesInput: CommunicationPreferencesRequestInput!
            $id: String!
            $validAsCarOwner: Boolean!
            $validAsCarRenter: Boolean!
          ) {
            updateUserDetails(update: $updateUserDetailsInput, userId: $id) {
              firstName
            }
            updateUserInternalDetails(input: $updateUserInternalDetailsInput, userId: $id) {
              restrictionsOnLicense
            }
            setValidAsCarOwner(valid: $validAsCarOwner, userId: $id) {
              id
            }
            setValidAsCarRenter(valid: $validAsCarRenter, userId: $id) {
              id
            }
            updateCommunicationPreferences(preferencesInput: $preferencesInput) {
              email
              emailAllowed
              phoneAllowed
              smsAllowed
            }
          }
        `,
        variables: getUserUpdateParams(params as RaUpdateParams<User>),
        parseResponse: ({ updateUserDetails }): RaGetResponse<User> => ({
          data: { id: params.id, ...updateUserDetails },
        }),
      };
    default:
      throw new Error(`Unknown fetch type: ${raFetchType}`);
  }
};

export default Users;
