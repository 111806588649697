import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { UserActions } from '../../../ra-queries/queries/users';

const ToogleUserBanButton = (props: any) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const isBlocked = props.record.bannedAt;

  const handleAdminPrivilegesToggle = useCallback(async () => {
    const action = isBlocked ? UserActions.UnblockUser : UserActions.BlockUser;
    const message = `User has been ${isBlocked ? 'unblocked' : 'blocked'}`;

    return dataProvider
      .update('Users', {
        id: props.record.id,
        data: { ...props.record, action },
        previousData: props.record,
      })
      .then(() => {
        refresh();
        notify(message);
      })
      .catch((error) => {
        refresh();
        notify(error.message, 'warning');
      });
  }, [dataProvider, isBlocked, notify, props.record, refresh]);

  return (
    <div style={{ margin: '20px 0 30px 0' }}>
      <Button variant="contained" color="primary" onClick={handleAdminPrivilegesToggle}>
        {isBlocked ? 'Unblock' : 'Block'} User
      </Button>
    </div>
  );
};

export default ToogleUserBanButton;
