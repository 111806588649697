import { gql } from '@apollo/client';
import {
  RaFetchType,
  RaGetResponse,
  RaListParams,
  RaListResponse,
  RaQuery,
} from '../../interfaces/RaQueries';
import { BundleSubscriptionResellerDiscountType } from '../../interfaces/types.generated';
import { ApiResponse } from '../../interfaces/ApiQueries';
import getInvitesListParams from '../../utils/getInvitesListParams';

interface ListResult {
  grantedBundleSubscriptionResellerDiscountsV2: {
    invites: Array<BundleSubscriptionResellerDiscountType>;
    overallCount: number;
  };
}

export enum InvitesActions {
  RevokeInvite = 'REVOKE_INVITE',
  MarkAsCustomerDeniedInvite = 'CUSTOMER_DENIED_INVITE',
  RevertToPending = 'REVERT_TO_PENDING',
  DeleteInviteData = 'DELETE_INVITE_DATA',
  AddInviteNote = 'ADD_INVITE_NOTE',
  EditInviteNote = 'EDIT_INVITE_NOTE',
  RemoveInviteNote = 'REMOVE_INVITE_NOTE',
}

const Invites = (raFetchType: RaFetchType, params: any): RaQuery => {
  switch (raFetchType) {
    case 'GET_LIST':
      return {
        query: gql`
          query (
            $limit: Int
            $offset: Int
            $status: BundleSubscriptionResellerDiscountStatusEnum
            $sort: GrantedBundleSubscriptionsResellerDiscountsSortEnum!
            $searchPhrase: String
            $businessId: String
          ) {
            grantedBundleSubscriptionResellerDiscountsV2(
              limit: $limit
              skip: $offset
              status: $status
              sort: $sort
              searchPhrase: $searchPhrase
              businessId: $businessId
            ) {
              invites {
                id
                status
                resellerBusiness {
                  id
                  displayName
                }
                grantee {
                  email
                  firstName
                  lastName
                  phoneNumber
                  user {
                    id
                  }
                }
                createdAt
              }
              overallCount
            }
          }
        `,
        variables: getInvitesListParams(params as RaListParams),
        parseResponse: ({
          data,
        }: ApiResponse<ListResult>): RaListResponse<BundleSubscriptionResellerDiscountType> => ({
          data: data.grantedBundleSubscriptionResellerDiscountsV2.invites,
          total: data.grantedBundleSubscriptionResellerDiscountsV2.overallCount,
        }),
      };
    case 'GET_ONE':
      return {
        query: gql`
          query ($id: String!) {
            grantedBundleSubscriptionResellerDiscount(id: $id) {
              id
              status
              resellerBusiness {
                id
                name
                displayName
              }
              resellerPrice
              createdBy {
                id
                details {
                  firstName
                  lastName
                }
              }
              grantee {
                email
                firstName
                lastName
                user {
                  id
                }
              }
              bundleType {
                id
                name
              }
              createdBy {
                id
                details {
                  firstName
                  lastName
                }
              }
              createdAt
              carMakerName
              carModelName
              estimatedCarDeliveryDate
              isCarPartOfFleet
              proposalNumber
              notes {
                id
                note
                createdAt
              }
            }
          }
        `,
        variables: params,
        parseResponse: ({ data }): RaGetResponse<BundleSubscriptionResellerDiscountType> => ({
          data: {
            ...data.grantedBundleSubscriptionResellerDiscount,
            estimatedCarDeliveryDate: Number(
              data.grantedBundleSubscriptionResellerDiscount.estimatedCarDeliveryDate,
            ),
          },
        }),
      };
    case 'UPDATE':
      if (params.data.action === InvitesActions.AddInviteNote) {
        return {
          query: gql`
            mutation createBundleInviteInternalNote($bundleInviteId: String!, $note: String!) {
              createBundleInviteInternalNote(bundleInviteId: $bundleInviteId, note: $note) {
                id
                note
                createdAt
              }
            }
          `,
          variables: { bundleInviteId: params.id, note: params.data.note },
          parseResponse: (): RaGetResponse<BundleSubscriptionResellerDiscountType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === InvitesActions.EditInviteNote) {
        return {
          query: gql`
            mutation updateBundleInviteInternalNote($noteId: String!, $note: String!) {
              updateBundleInviteInternalNote(noteId: $noteId, note: $note) {
                id
                note
                createdAt
              }
            }
          `,
          variables: { noteId: params.data.noteId, note: params.data.note },
          parseResponse: (): RaGetResponse<BundleSubscriptionResellerDiscountType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === InvitesActions.RemoveInviteNote) {
        return {
          query: gql`
            mutation deleteBundleInviteInternalNote($noteId: String!) {
              deleteBundleInviteInternalNote(noteId: $noteId) {
                id
              }
            }
          `,
          variables: { noteId: params.data.noteId },
          parseResponse: (): RaGetResponse<BundleSubscriptionResellerDiscountType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === InvitesActions.RevokeInvite) {
        return {
          query: gql`
            mutation ($id: String!) {
              revokeBundleSubscriptionResellerDiscount(resellerDiscountId: $id) {
                id
              }
            }
          `,
          variables: { id: params.id },
          parseResponse: (): any => ({
            data: params.data,
          }),
        };
      }
      if (params.data.action === InvitesActions.MarkAsCustomerDeniedInvite) {
        return {
          query: gql`
            mutation ($id: String!) {
              markAsCustomerDeniedBundleSubscriptionResellerDiscount(resellerDiscountId: $id) {
                id
              }
            }
          `,
          variables: { id: params.id },
          parseResponse: (): any => ({
            data: params.data,
          }),
        };
      }
      if (params.data.action === InvitesActions.RevertToPending) {
        return {
          query: gql`
            mutation ($id: String!) {
              revertCustomerDeniedBundleSubscriptionResellerDiscount(resellerDiscountId: $id) {
                id
              }
            }
          `,
          variables: { id: params.id },
          parseResponse: (): any => ({
            data: params.data,
          }),
        };
      }
      if (params.data.action === InvitesActions.DeleteInviteData) {
        return {
          query: gql`
            mutation ($id: String!) {
              deleteBundleSubscriptionResellerInvitePersonalData(resellerDiscountId: $id) {
                id
              }
            }
          `,
          variables: { id: params.id },
          parseResponse: (): any => ({
            data: params.data,
          }),
        };
      }
      return {
        query: gql`
          query ($id: String!) {
            grantedBundleSubscriptionResellerDiscount(id: $id) {
              id
            }
          }
        `,
        variables: { id: params.id },
        parseResponse: (): any => ({
          data: {},
        }),
      };
    default:
      throw new Error(`Unknown fetch type: ${raFetchType}`);
  }
};

export default Invites;
