import React from 'react';
import { FunctionField, NumberField, Show, ShowProps, SimpleShowLayout } from 'react-admin';

const PricingShow = (props: ShowProps) => (
  <Show {...props} title="Pricings">
    <SimpleShowLayout className="two-columns-layout">
      <NumberField source="valueMin" />
      <NumberField source="valueMax" />
      <FunctionField
        source="id"
        label="Type"
        render={(record: any) => (record.id.split('-')[0] === 'business' ? 'Business' : 'Normal')}
      />
      <NumberField
        source="pricePerHour"
        locales="en-GB"
        options={{ style: 'currency', currency: 'GBP' }}
      />
      <NumberField
        source="pricePerDay"
        locales="en-GB"
        options={{ style: 'currency', currency: 'GBP' }}
      />
      <NumberField
        source="pricePerWeek"
        locales="en-GB"
        options={{ style: 'currency', currency: 'GBP' }}
      />
    </SimpleShowLayout>
  </Show>
);

export default PricingShow;
