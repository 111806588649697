import React, { useCallback, useState } from 'react';
import { Button, TextareaAutosize } from '@material-ui/core';
import { Check as ApproveIcon, Close as RejectIcon } from '@material-ui/icons';
import {
  FunctionField,
  ImageField,
  SimpleShowLayout,
  TextField,
  useDataProvider,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin';
import { CarActions } from '../../ra-queries/queries/cars';
import { parseSnakeCase } from '../../utils/stringHelpers';

const ApproveCarUpdate = (props: any) => {
  const car = props.record;
  const [rejectionReason, setRejectionReason] = useState('');

  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const handleApprove = useCallback(async () => {
    return dataProvider
      .update('Cars', {
        id: car.id,
        data: { ...car, action: CarActions.ApproveCarUpdate },
        previousData: car,
      })
      .then(() => {
        redirect(`/Cars/${car.id}/show`);
        refresh();
        notify('Car update has been approved');
      })
      .catch((error) => {
        refresh();
        notify(`Car update could not be approved: ${error.message}`, 'warning');
      });
  }, [car, dataProvider, notify, redirect, refresh]);

  const handleReject = useCallback(
    async (rejectionNote: string) => {
      return dataProvider
        .update('Cars', {
          id: car.id,
          data: { ...car, action: CarActions.RejectCarUpdate, rejectionReason: rejectionNote },
          previousData: car,
        })
        .then(() => {
          redirect(`/Cars/${car.id}/show`);
          refresh();
          notify('Car update has been rejected');
        })
        .catch((error) => {
          refresh();
          notify(`Car update could not be rejected: ${error.message}`, 'warning');
        });
    },
    [car, dataProvider, notify, redirect, refresh],
  );

  return (
    <>
      <div className="two-columns-layout">
        <div>
          <h5 style={{ marginLeft: '16px' }}>Old data</h5>
          <SimpleShowLayout {...props}>
            <TextField source="details.registration" label="Registration Plate no" />
            <TextField source="details.vin" label="VIN" />
            <TextField source="details.type" label="Type" />
            <TextField source="details.motor" label="Motor" />
            <TextField source="details.maker.name" label="Maker" />
            <TextField source="details.model.name" label="Model" />
            <TextField source="details.year" label="Year" />
            <TextField source="details.value" label="Value" />
            <TextField source="details.mileage" label="Mileage" />
            <TextField source="details.doors" label="Doors" />
            <TextField source="details.seats" label="Seats" />
            <TextField source="details.transmission" label="Transmission" />
            <TextField source="details.color" label="Colour" />
            <ImageField source="details.images" src="url" label="Images" />
            <TextField source="details.description" label="Description" />
            <FunctionField
              source="details.range"
              label="Range"
              render={(currentCar: any) => parseSnakeCase(currentCar.details.range)}
            />
            <TextField
              source="details.existingDamageDescription"
              label="Existing Damage Description"
            />
            <ImageField
              source="details.existingDamageImages"
              src="url"
              label="Existing Damage Images"
            />
          </SimpleShowLayout>
        </div>
        <div>
          <h5 style={{ marginLeft: '16px' }}>New data</h5>
          <SimpleShowLayout {...props}>
            <TextField source="detailsRequested.registration" label="Registration Plate no" />
            <TextField source="detailsRequested.vin" label="VIN" />
            <TextField source="detailsRequested.type" label="Type" />
            <TextField source="detailsRequested.motor" label="Motor" />
            <TextField source="detailsRequested.maker.name" label="Maker" />
            <TextField source="detailsRequested.model.name" label="Model" />
            <TextField source="detailsRequested.year" label="Year" />
            <TextField source="detailsRequested.value" label="Value" />
            <TextField source="detailsRequested.mileage" label="Mileage" />
            <TextField source="detailsRequested.doors" label="Doors" />
            <TextField source="detailsRequested.seats" label="Seats" />
            <TextField source="detailsRequested.transmission" label="Transmission" />
            <TextField source="detailsRequested.color" label="Colour" />
            <ImageField source="detailsRequested.images" src="url" label="Images" />
            <TextField source="detailsRequested.description" label="Description" />
            <FunctionField
              source="detailsRequested.range"
              label="Range"
              render={(currentCar: any) => parseSnakeCase(currentCar.details.range)}
            />
            <TextField
              source="detailsRequested.existingDamageDescription"
              label="Existing Damage Description"
            />
            <ImageField
              source="detailsRequested.existingDamageImages"
              src="url"
              label="Existing Damage Images"
            />
          </SimpleShowLayout>
        </div>
      </div>
      <div style={{ margin: '10px 16px 30px 16px' }}>
        <h3>Approve / Reject Car Update</h3>
        <TextareaAutosize
          rowsMin={3}
          value={rejectionReason}
          placeholder="Rejection reason (applies to rejection only)"
          onChange={(e) => {
            setRejectionReason(e.target.value);
          }}
          style={{ width: '100%' }}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            style={{ background: '#f44336', color: 'white' }}
            startIcon={<RejectIcon />}
            onClick={() => handleReject(rejectionReason)}
          >
            Reject
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<ApproveIcon />}
            onClick={handleApprove}
          >
            Approve
          </Button>
        </div>
      </div>
    </>
  );
};

export default ApproveCarUpdate;
