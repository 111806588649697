import React from 'react';
import {
  BooleanField,
  FunctionField,
  NumberField,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  useShowController,
} from 'react-admin';
import { parseSnakeCase } from '../../utils/stringHelpers';
import ApproveCarUpdate from './ApproveCarUpdate';
import CarAvailabilityPeriods from './elements/CarAvailabilityPeriods';
import CarFeatures from './elements/CarFeatures';
import CarGuide from './elements/CarGuide';
import CarPhotos from './elements/CarPhotos';
import CarReviews from './elements/CarReviews';
import CarLocationTips from './elements/CarLocationTips';
import { CarMapLocation } from './elements/CarMapLocation';
import CarSalesInfo from './elements/CarSalesInfo';
import CarDamagePhotos from './elements/CarDamagePhotos';

const CarShow = (props: ShowProps) => {
  const { record } = useShowController(props);

  if (!record) return null;

  return (
    <Show {...props} title="Cars">
      <TabbedShowLayout>
        <Tab label="summary" contentClassName="two-columns-layout">
          <TextField source="id" />
          <BooleanField source="visible" />
          <TextField source="details.registration" label="Registration Plate no" />
          <TextField source="details.vin" label="VIN" />
          <TextField source="details.type" label="Type" />
          <TextField source="details.motor" label="Motor" />
          <TextField source="details.maker.name" label="Maker" />
          <TextField source="details.model.name" label="Model" />
          <TextField source="details.year" label="Year" />
          <FunctionField
            source="details.value"
            label="Value"
            render={(car: any) => {
              if (!car.details?.value) {
                return '';
              }

              const options = [
                { id: 'Between10And20', name: 'Between 10 and 20' },
                { id: 'Between20And30', name: 'Between 20 and 30' },
                { id: 'Between30And40', name: 'Between 30 and 40' },
                { id: 'Between40And60', name: 'Between 40 and 60' },
                { id: 'Between60And75', name: 'Between 60 and 75' },
                { id: 'MoreThan75', name: 'More than 75' },
              ];

              return options.find((o) => o.id === car.details?.value)?.name || '';
            }}
          />
          <TextField source="details.mileage" label="Mileage" />
          <TextField source="details.doors" label="Doors" />
          <TextField source="details.seats" label="Seats" />
          <TextField source="details.transmission" label="Transmission" />
          <TextField source="details.color" label="Colour" />
          <FunctionField
            source="details.range"
            label="Range"
            render={(car: any) => parseSnakeCase(car.details?.range)}
          />
          <NumberField
            source="pricePerHour"
            locales="en-GB"
            options={{ style: 'currency', currency: 'GBP' }}
          />
          <NumberField
            source="pricePerDay"
            locales="en-GB"
            options={{ style: 'currency', currency: 'GBP' }}
          />
          <NumberField
            source="pricePerWeek"
            locales="en-GB"
            options={{ style: 'currency', currency: 'GBP' }}
          />
          <BooleanField source="flags.isTaxed" label="Vehicle Taxed" />
          <BooleanField source="flags.isMotValid" label="Vehicle MOT valid" />
          <BooleanField source="flags.isInsuredByOwner" label="Vehicle insured by the owner" />
          <TextField source="details.description" label="Description" />
          <TextField
            source="details.existingDamageDescription"
            label="Existing Damage Description"
          />
          {/* <TextField source="details.approveStatus" label="Status" /> */}
          <TextField source="details.approveStatus" label="Status" />
          {record.details?.rejectionReason && (
            <TextField source="details.rejectionReason" label="Status rejection reason" />
          )}
          {record.detailsRequested?.approveStatus && (
            <TextField source="detailsRequested.approveStatus" label="Update status" />
          )}
          {record.detailsRequested?.rejectionReason && (
            <TextField
              source="detailsRequested.rejectionReason"
              label="Update status rejection reason"
            />
          )}
        </Tab>
        {record.detailsRequested && (
          <Tab label="Approve Car Update" path="approve-car-update">
            <ApproveCarUpdate />
          </Tab>
        )}
        <Tab label="Features" path="features">
          <CarFeatures />
        </Tab>
        <Tab label="Photos" path="photos">
          <CarPhotos />
          <CarDamagePhotos />
        </Tab>
        <Tab label="Availability" path="availability">
          <CarAvailabilityPeriods />
        </Tab>
        <Tab label="Sales Info" path="sales-info">
          <CarSalesInfo />
        </Tab>
        <Tab label="Reviews" path="reviews">
          <CarReviews />
        </Tab>
        <Tab label="Guide" path="guide">
          <CarGuide />
        </Tab>
        <Tab label="Location Tips" path="locationTips">
          <CarLocationTips />
        </Tab>
        <Tab label="Map Location" path="map">
          <CarMapLocation />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
export default CarShow;
