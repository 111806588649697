import React from 'react';
import { Edit, EditProps, SimpleForm, useEditController } from 'react-admin';
import { NoDeleteEditToolbar } from '../../components/NoDeleteEditToolbar';
import StyledGridLayout from '../../components/StyledGridLayout';
import { StyledNumberInput, StyledTextInput } from '../../components/StyledInputs';
import { capitalize } from '../../utils/stringHelpers';

const PricingEdit = (props: EditProps) => {
  const { record } = useEditController(props);

  if (!record) return null;

  return (
    <Edit {...props} title="Pricings" undoable={false}>
      <SimpleForm toolbar={<NoDeleteEditToolbar />} redirect="show">
        <StyledGridLayout>
          <StyledNumberInput source="valueMin" disabled />
          <StyledNumberInput source="valueMax" disabled />
          <StyledTextInput
            source="pricing-type"
            label="Type"
            initialValue={capitalize(String(record.id).split('-')[0])}
            disabled
          />
          <StyledNumberInput source="pricePerHour" disabled />
          <StyledNumberInput source="pricePerDay" />
          <StyledNumberInput source="pricePerWeek" disabled />
        </StyledGridLayout>
      </SimpleForm>
    </Edit>
  );
};

export default PricingEdit;
