import React from 'react';
import {
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  Link,
  List,
  ListProps,
  TextField,
  TextInput,
} from 'react-admin';

const MyEvsFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput label="Car Registration" source="q" alwaysOn variant="outlined" />
    </Filter>
  );
};

const MyEvsList = (props: ListProps) => {
  return (
    <List {...props} bulkActionButtons={false} empty={false} filters={<MyEvsFilter />}>
      <Datagrid rowClick="show">
        <TextField source="registration" label="Car Registration" sortable={false} />
        <FunctionField
          label="Customer Name"
          render={(myEv: any) => {
            return (
              <Link to={`/Users/${myEv.user.id}/show`}>
                {myEv.user.details.firstName} {myEv.user.details.lastName}
              </Link>
            );
          }}
        />
        <FunctionField
          label="MOT Reminder Set"
          render={(myEv: any) => {
            return myEv.reminders.tax ? `Yes` : `No`;
          }}
        />
        <FunctionField
          label="Tax Reminder Set"
          render={(myEv: any) => {
            return myEv.reminders.mot ? `Yes` : `No`;
          }}
        />
        <DateField source="taxAndMot.motExpiryDate" label="MOT Expiry" sortable />
      </Datagrid>
    </List>
  );
};

export default MyEvsList;
