/* eslint-disable consistent-return */
import Axios from 'axios';
import { gql } from '@apollo/client';
import { DataProvider } from 'react-admin';
import _ from 'lodash';
import { buildApolloClient } from '../providers/DataProviderFactory';
import { CarActions } from '../ra-queries/queries/cars';

export const uploadFile = async ({
  file,
  s3credentials,
}: {
  file: File;
  s3credentials: { fields: string; url: string };
}) => {
  const formData = new FormData();
  formData.append('Content-Type', file.type);
  const parsedFields = JSON.parse(s3credentials.fields);
  Object.entries(parsedFields).forEach(([k, v]) => {
    formData.append(k, v as string);
  });
  formData.append('file', file);
  try {
    await Axios.post(s3credentials.url, formData);
    return true;
  } catch (err) {
    return false;
  }
};

const withFileUpload =
  (requestHandler: DataProvider) => async (type: any, resource: any, params: any) => {
    const apolloClient = buildApolloClient();
    if (resource === 'LeasingOffers' && type === 'UPDATE') {
      if (params.data.offerImageForUpload) {
        const {
          data: { signImageUploadCredentials },
        } = await apolloClient.mutate({
          mutation: gql`
            mutation signImageUploadCredentials($imagePurpose: ImagePurposeEnum!) {
              signImageUploadCredentials(imagePurpose: $imagePurpose) {
                imageId
                url
                fields
              }
            }
          `,
          variables: { imagePurpose: 'LeasingOfferImage' },
        });

        await uploadFile({
          file: params.data.offerImageForUpload.rawFile,
          s3credentials: signImageUploadCredentials,
        });

        await apolloClient.mutate({
          mutation: gql`
            mutation commitImageUpload($imageId: String!) {
              commitImageUpload(imageId: $imageId) {
                id
                url
              }
            }
          `,
          variables: { imageId: signImageUploadCredentials.imageId },
        });

        await apolloClient.mutate({
          mutation: gql`
            mutation updateLeasingOfferImage($imageId: String!, $offerId: String!) {
              updateLeasingOfferImage(imageId: $imageId, offerId: $offerId) {
                id
              }
            }
          `,
          variables: { imageId: signImageUploadCredentials.imageId, offerId: params.data.id },
        });
      }
    }
    // Updated Offer Images
    if (resource === 'Offers' && type === 'UPDATE') {
      if (
        params.data.heroContentLogoImage ||
        params.data.heroContentOfferImage ||
        params.data.secondaryContentLogoImage ||
        params.data.secondaryContentOfferImage ||
        params.data.tileContentLogoImage ||
        params.data.tileContentOfferImage
      ) {
        // create array of updated images
        const updatedOfferImages = [];
        if (params.data.heroContentLogoImage) {
          updatedOfferImages.push({
            s3ImageType: 'OfferHeroLogo',
            keyName: 'heroContentLogoImage',
            offerContentId: params.data.heroContent.id,
            imageType: 'Logo',
          });
        }
        if (params.data.heroContentOfferImage) {
          updatedOfferImages.push({
            s3ImageType: 'OfferHeroOffer',
            keyName: 'heroContentOfferImage',
            offerContentId: params.data.heroContent.id,
            imageType: 'Offer',
          });
        }
        if (params.data.secondaryContentLogoImage) {
          updatedOfferImages.push({
            s3ImageType: 'OfferSecondaryLogo',
            keyName: 'secondaryContentLogoImage',
            offerContentId: params.data.secondaryContent.id,
            imageType: 'Logo',
          });
        }
        if (params.data.secondaryContentOfferImage) {
          updatedOfferImages.push({
            s3ImageType: 'OfferSecondaryOffer',
            keyName: 'secondaryContentOfferImage',
            offerContentId: params.data.secondaryContent.id,
            imageType: 'Offer',
          });
        }
        if (params.data.tileContentLogoImage) {
          updatedOfferImages.push({
            s3ImageType: 'OfferTileLogo',
            keyName: 'tileContentLogoImage',
            offerContentId: params.data.tileContent.id,
            imageType: 'Logo',
          });
        }
        if (params.data.tileContentOfferImage) {
          updatedOfferImages.push({
            s3ImageType: 'OfferTileOffer',
            keyName: 'tileContentOfferImage',
            offerContentId: params.data.tileContent.id,
            imageType: 'Offer',
          });
        }

        for (const updatedImageType of updatedOfferImages) {
          const { s3ImageType, keyName, offerContentId, imageType } = updatedImageType;

          const {
            data: { signImageUploadCredentials },
          } = await apolloClient.mutate({
            mutation: gql`
              mutation signImageUploadCredentials($imagePurpose: ImagePurposeEnum!) {
                signImageUploadCredentials(imagePurpose: $imagePurpose) {
                  imageId
                  url
                  fields
                }
              }
            `,
            variables: { imagePurpose: s3ImageType },
          });
          await uploadFile({
            file: params.data[keyName].rawFile,
            s3credentials: signImageUploadCredentials,
          });
          await apolloClient.mutate({
            mutation: gql`
              mutation commitImageUpload($imageId: String!) {
                commitImageUpload(imageId: $imageId) {
                  id
                  url
                }
              }
            `,
            variables: { imageId: signImageUploadCredentials.imageId },
          });

          await apolloClient.mutate({
            mutation: gql`
              mutation updateOfferContentImage(
                $offerId: String!
                $offerContentId: String!
                $imageId: String!
                $offerContentImageType: OfferContentImageTypeEnum!
              ) {
                updateOfferContentImage(
                  offerId: $offerId
                  offerContentId: $offerContentId
                  imageId: $imageId
                  offerContentImageType: $offerContentImageType
                ) {
                  id
                  offerName
                }
              }
            `,
            variables: {
              imageId: signImageUploadCredentials.imageId,
              offerId: params.id,
              offerContentId,
              offerContentImageType: imageType,
            },
          });
        }
      }
    }
    // BUNDLES PDF
    if (resource === 'Bundles' && type === 'UPDATE') {
      if (params.data.pdfFile) {
        const {
          data: { signBundlePdfUploadCredentials },
        } = await apolloClient.mutate({
          mutation: gql`
            mutation signBundlePdfUploadCredentials($fileName: String!, $bundleTypeId: String!) {
              signBundlePdfUploadCredentials(fileName: $fileName, bundleTypeId: $bundleTypeId) {
                url
                fields
              }
            }
          `,
          variables: { fileName: params.data.pdfFile.rawFile.name, bundleTypeId: params.data.id },
        });

        await uploadFile({
          file: params.data.pdfFile.rawFile,
          s3credentials: signBundlePdfUploadCredentials,
        });

        await apolloClient.mutate({
          mutation: gql`
            mutation commitBundlePdfUpload($fileName: String!, $bundleTypeId: String!) {
              commitBundlePdfUpload(fileName: $fileName, bundleTypeId: $bundleTypeId)
            }
          `,
          variables: { fileName: params.data.pdfFile.rawFile.name, bundleTypeId: params.data.id },
        });
      }
    }

    // CAR PHOTO
    if (resource === 'Cars' && type === 'UPDATE' && params.data.action === CarActions.AddCarPhoto) {
      if (params.data.newPhoto) {
        const {
          data: { signImageUploadCredentials },
        } = await apolloClient.mutate({
          mutation: gql`
            mutation signImageUploadCredentials($imagePurpose: ImagePurposeEnum!) {
              signImageUploadCredentials(imagePurpose: $imagePurpose) {
                imageId
                url
                fields
              }
            }
          `,
          variables: { imagePurpose: 'CarImage' },
        });

        await uploadFile({
          file: params.data.newPhoto,
          s3credentials: signImageUploadCredentials,
        });

        await apolloClient.mutate({
          mutation: gql`
            mutation commitImageUpload($imageId: String!) {
              commitImageUpload(imageId: $imageId) {
                id
                url
              }
            }
          `,
          variables: { imageId: signImageUploadCredentials.imageId },
        });

        await apolloClient.mutate({
          mutation: gql`
            mutation updateCarDetails($updateInput: CarDetailsUpdateInputType!, $carId: String!) {
              updateCarDetails(updateInput: $updateInput, carId: $carId) {
                id
              }
            }
          `,
          variables: {
            updateInput: {
              images: [
                ...params.data.details.images.map((image: any) => image.id),
                signImageUploadCredentials.imageId,
              ],
            },
            carId: params.data.id,
          },
        });

        return { data: { id: params.id, ...params.data } };
      }
    }

    if (
      resource === 'Cars' &&
      type === 'UPDATE' &&
      params.data.action === CarActions.AddCarDamagePhoto
    ) {
      if (params.data.newPhoto) {
        const {
          data: { signImageUploadCredentials },
        } = await apolloClient.mutate({
          mutation: gql`
            mutation signImageUploadCredentials($imagePurpose: ImagePurposeEnum!) {
              signImageUploadCredentials(imagePurpose: $imagePurpose) {
                imageId
                url
                fields
              }
            }
          `,
          variables: { imagePurpose: 'CarExistingDamageImage' },
        });

        await uploadFile({
          file: params.data.newPhoto,
          s3credentials: signImageUploadCredentials,
        });

        await apolloClient.mutate({
          mutation: gql`
            mutation commitImageUpload($imageId: String!) {
              commitImageUpload(imageId: $imageId) {
                id
                url
              }
            }
          `,
          variables: { imageId: signImageUploadCredentials.imageId },
        });

        await apolloClient.mutate({
          mutation: gql`
            mutation updateCarDetails($updateInput: CarDetailsUpdateInputType!, $carId: String!) {
              updateCarDetails(updateInput: $updateInput, carId: $carId) {
                id
              }
            }
          `,
          variables: {
            updateInput: {
              existingDamageImages: [
                ...params.data.details.existingDamageImages.map((image: any) => image.id),
                signImageUploadCredentials.imageId,
              ],
            },
            carId: params.data.id,
          },
        });

        return { data: { id: params.id, ...params.data } };
      }
    }

    // BUSINESS LOGO IMAGE
    if (resource === 'Businesses' && type === 'UPDATE') {
      if (params.data.logo) {
        const {
          data: { signImageUploadCredentials },
        } = await apolloClient.mutate({
          mutation: gql`
            mutation signImageUploadCredentials($imagePurpose: ImagePurposeEnum!) {
              signImageUploadCredentials(imagePurpose: $imagePurpose) {
                imageId
                url
                fields
              }
            }
          `,
          variables: { imagePurpose: 'BusinessLogo' },
        });

        await uploadFile({
          file: params.data.logo.rawFile,
          s3credentials: signImageUploadCredentials,
        });

        await apolloClient.mutate({
          mutation: gql`
            mutation commitImageUpload($imageId: String!) {
              commitImageUpload(imageId: $imageId) {
                id
                url
              }
            }
          `,
          variables: { imageId: signImageUploadCredentials.imageId },
        });

        await apolloClient.mutate({
          mutation: gql`
            mutation updateBusinessLogoImage($imageId: String, $businessId: String!) {
              updateBusinessLogoImage(imageId: $imageId, businessId: $businessId) {
                id
              }
            }
          `,
          variables: { imageId: signImageUploadCredentials.imageId, businessId: params.data.id },
        });
      }

      // Dashboard background Image
      if (params.data.dashboardBackground) {
        const {
          data: { signImageUploadCredentials },
        } = await apolloClient.mutate({
          mutation: gql`
            mutation signImageUploadCredentials($imagePurpose: ImagePurposeEnum!) {
              signImageUploadCredentials(imagePurpose: $imagePurpose) {
                imageId
                url
                fields
              }
            }
          `,
          variables: { imagePurpose: 'BusinessDashboardBackground' },
        });

        await uploadFile({
          file: params.data.dashboardBackground.rawFile,
          s3credentials: signImageUploadCredentials,
        });

        await apolloClient.mutate({
          mutation: gql`
            mutation commitImageUpload($imageId: String!) {
              commitImageUpload(imageId: $imageId) {
                id
                url
              }
            }
          `,
          variables: { imageId: signImageUploadCredentials.imageId },
        });

        await apolloClient.mutate({
          mutation: gql`
            mutation updateBusinessDashboardBackgroundImage(
              $imageId: String
              $businessId: String!
            ) {
              updateBusinessDashboardBackgroundImage(imageId: $imageId, businessId: $businessId) {
                id
              }
            }
          `,
          variables: { imageId: signImageUploadCredentials.imageId, businessId: params.data.id },
        });
      }
    }

    // USER IMAGES
    if (resource === 'Users' && type === 'UPDATE') {
      if (
        params.data.avatarImage ||
        params.data.drivingLicenseFrontImage ||
        params.data.drivingLicenseSelfieImage ||
        params.data.passportFrontImage
      ) {
        // create array of updated images
        const updatedUserImages = [] as Array<string>;
        if (params.data.avatarImage) updatedUserImages.push('UserAvatar');
        if (params.data.drivingLicenseFrontImage) updatedUserImages.push('DrivingLicenseFront');
        if (params.data.drivingLicenseSelfieImage) updatedUserImages.push('DrivingLicenseSelfie');
        if (params.data.passportFrontImage) updatedUserImages.push('PassportFront');

        // for each of the updated images perform image upload
        updatedUserImages.forEach(async (updatedImageType) => {
          const {
            data: { signImageUploadCredentials },
          } = await apolloClient.mutate({
            mutation: gql`
              mutation signImageUploadCredentials($imagePurpose: ImagePurposeEnum!) {
                signImageUploadCredentials(imagePurpose: $imagePurpose) {
                  imageId
                  url
                  fields
                }
              }
            `,
            variables: { imagePurpose: updatedImageType },
          });

          let paramFile = '';

          if (updatedImageType === 'UserAvatar') paramFile = 'avatarImage';
          if (updatedImageType === 'DrivingLicenseFront') paramFile = 'drivingLicenseFrontImage';
          if (updatedImageType === 'DrivingLicenseSelfie') paramFile = 'drivingLicenseSelfieImage';
          if (updatedImageType === 'PassportFront') paramFile = 'passportFrontImage';

          await uploadFile({
            file: params.data[paramFile].rawFile,
            s3credentials: signImageUploadCredentials,
          });
          await apolloClient.mutate({
            mutation: gql`
              mutation commitImageUpload($imageId: String!) {
                commitImageUpload(imageId: $imageId) {
                  id
                  url
                }
              }
            `,
            variables: { imageId: signImageUploadCredentials.imageId },
          });

          if (updatedImageType === 'UserAvatar') {
            await apolloClient.mutate({
              mutation: gql`
                mutation updateUserDetails($imageId: String!, $userId: String!) {
                  updateUserDetails(update: { avatarImageId: $imageId }, userId: $userId) {
                    firstName
                  }
                }
              `,
              variables: { imageId: signImageUploadCredentials.imageId, userId: params.id },
            });
          }

          if (updatedImageType === 'DrivingLicenseFront') {
            await apolloClient.mutate({
              mutation: gql`
                mutation updateUserDetails($imageId: String!, $userId: String!) {
                  updateUserDetails(
                    update: { drivingLicenseFrontImageId: $imageId }
                    userId: $userId
                  ) {
                    firstName
                  }
                }
              `,
              variables: { imageId: signImageUploadCredentials.imageId, userId: params.id },
            });
          }

          if (updatedImageType === 'DrivingLicenseSelfie') {
            await apolloClient.mutate({
              mutation: gql`
                mutation updateUserDetails($imageId: String!, $userId: String!) {
                  updateUserDetails(
                    update: { drivingLicenseSelfieImageId: $imageId }
                    userId: $userId
                  ) {
                    firstName
                  }
                }
              `,
              variables: { imageId: signImageUploadCredentials.imageId, userId: params.id },
            });
          }

          if (updatedImageType === 'PassportFront') {
            await apolloClient.mutate({
              mutation: gql`
                mutation updateUserDetails($imageId: String!, $userId: String!) {
                  updateUserDetails(update: { passportFrontImageId: $imageId }, userId: $userId) {
                    firstName
                  }
                }
              `,
              variables: { imageId: signImageUploadCredentials.imageId, userId: params.id },
            });
          }
        });
      }
    }
    // for other request types and resources, fall back to the default request handler
    return requestHandler[_.camelCase(type)](resource, params);
  };

export default withFileUpload;
