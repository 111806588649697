import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { UserActions } from '../../../ra-queries/queries/users';

const OrderPlugsurfingKeyAction = (props: any) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const handleOrderPlugsurfingKeyAction = useCallback(async () => {
    const action = UserActions.OrderPlugsurfingKey;
    return dataProvider
      .update('Users', {
        id: props.record.id,
        data: { ...props.record, action },
        previousData: props.record,
      })
      .then(() => {
        refresh();
        notify(`Plugsurfing key has been ordered.`);
      })
      .catch((error) => {
        refresh();
        notify(`Could not order plugsurfing key for this user: ${error.message}`, 'warning');
      });
  }, [dataProvider, notify, props.record, refresh]);

  return (
    <div style={{ margin: '20px 0 30px 0' }}>
      <Button variant="contained" color="primary" onClick={handleOrderPlugsurfingKeyAction}>
        Order Plugsurfing Key
      </Button>
    </div>
  );
};

export default OrderPlugsurfingKeyAction;
