import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
  .two-columns-layout{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  .two-columns-layout h5, 
  .two-columns-layout .ra-field-undefined{
    width: 100%;
    grid-column: 1 / span 2;
  }
  .rmf-full-width>div{
    width: 100%;
  }
  .rbc-event.rbc-event-allday{
    min-height: 30px;
  }
`;

export default GlobalStyles;
