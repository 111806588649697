import React from 'react';
import {
  BooleanField,
  DateField,
  FunctionField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  UrlField,
  useShowController,
} from 'react-admin';
import { ImageFieldWithPreviewLink } from '../../components/ImageFieldWithPreviewLink';
import ApproveUserUpdate from './elements/ApproveUserUpdate';
import ToogleAdminButton from './elements/ToogleAdminButton';
import ToogleUserBanButton from './elements/ToogleUserBanButton';
import UserNotes from './elements/UserNotes';
import UserReviews from './elements/UserReviews';
import UserTags from './elements/UserTags';
import UserDeleteButton from './elements/UserDeleteButton';
import UserInvites from './elements/UserInvites';
import UserSubscriptions from './elements/UserSubscriptions';
import OrderPlugsurfingKeyAction from './elements/OrderPlugSurfingKeyAction';

const UserShow = (props: any) => {
  const { record } = useShowController(props);

  if (!record) return null;

  return (
    <Show {...props} title="Users">
      <TabbedShowLayout>
        <Tab label="summary" contentClassName="two-columns-layout">
          <TextField source="email" />
          <DateField source="createdAt" />
          <BooleanField source="validAsCarOwner" />
          <BooleanField source="validAsCarRenter" />
          <TextField source="details.firstName" label="First Name" />
          <TextField source="details.lastName" label="Last Name" />
          <DateField source="details.dateOfBirth" label="Date Of Birth" />
          <TextField source="details.phoneNumber" label="Phone number" />
          <TextField source="details.addressLine1" label="Address Line 1" />
          <TextField source="details.addressLine2" label="Address Line 2" />
          <TextField source="details.city" label="City" />
          <TextField source="details.postCode" label="Post Code" />
          <TextField source="details.county" label="County" />
          <h5>Communication Preferences</h5>
          <BooleanField
            source="communicationPreferences.emailAllowed"
            label="Marketing permissions - Email Allowed"
          />
          <BooleanField
            source="communicationPreferences.phoneAllowed"
            label="Marketing permissions - Phone Allowed"
          />
          <BooleanField
            source="communicationPreferences.smsAllowed"
            label="Marketing permissions - SMS Allowed"
          />

          <h5>User Profile Photo</h5>
          <ImageFieldWithPreviewLink source="details.avatarImage.url" label="Avatar" />
          <h5>Details Approval Status</h5>
          <TextField source="details.approvalStatus" label="Status" />
          {record.details?.rejectionReason && (
            <TextField source="details.rejectionReason" label="Status rejection reason" />
          )}
          {record.detailsRequested?.approvalStatus && (
            <TextField source="detailsRequested.approvalStatus" label="Update status" />
          )}
          {record.detailsRequested?.rejectionReason && (
            <TextField
              source="detailsRequested.rejectionReason"
              label="Update status rejection reason"
            />
          )}

          <h5>Driving data</h5>
          <TextField source="details.drivingLicenseNumber" label="Driving License Number" />
          <DateField source="details.drivingLicenseValidFrom" label="Driving License Valid From" />
          <DateField source="details.drivingLicenseValidTo" label="Driving License Valid To" />
          <TextField
            source="details.drivingLicenseCountryOfIssue"
            label="Driving License Country Of Issue"
          />
          <TextField source="details.drivingRecordCheckCode" label="Driving Record Check Code" />
          <TextField
            source="internalDetails.restrictionsOnLicense"
            label="Offenses and Restrictions on License"
          />
          <TextField source="internalDetails.numberOfPoints" label="Number Of Points on License" />
          <BooleanField
            source="internalDetails.isThreeWayVerified"
            label="Three way match verified"
          />
          <ImageFieldWithPreviewLink
            source="details.drivingLicenseFrontImage.url"
            label="Driving License Front"
          />
          <ImageFieldWithPreviewLink
            source="details.drivingLicenseSelfieImage.url"
            label="Driving License Selfie"
          />
          <ImageFieldWithPreviewLink
            source="details.passportFrontImage.url"
            label="Bank Account Statement or utility bill"
          />
          <h5>Payment data</h5>
          <TextField source="details.stripeConnectedAccountId" label="Stripe account ID" />
          <UrlField source="stripeCustomerId" label="Stripe customer ID" />
          <UrlField source="stripeConnectedAccountUrl" label="Stripe account URL" />
          <UrlField source="stripeCustomerUrl" label="Stripe customer URL" />
          <FunctionField
            source="paymentMethods"
            render={(currentUser: any) => {
              return currentUser.paymentMethods
                ? currentUser.paymentMethods.map((method: any) => method.brand).join(', ')
                : '';
            }}
          />
        </Tab>
        {String(record.detailsRequested?.approvalStatus) === 'Pending' && (
          <Tab label="Approve Profile Update" path="approve-profile-update">
            <ApproveUserUpdate />
          </Tab>
        )}
        <Tab label="Subscriptions" path="subscriptions">
          <UserSubscriptions user={record} />
        </Tab>
        <Tab label="Invites" path="invites">
          <UserInvites user={record} />
        </Tab>
        <Tab label="Reviews" path="reviews">
          <UserReviews user={record} />
        </Tab>
        <Tab label="Tags" path="tags">
          <UserTags user={record} />
        </Tab>
        <Tab label="Notes" path="notes">
          <UserNotes user={record} />
        </Tab>
        <Tab label="Actions" path="actions">
          <ToogleUserBanButton />
          <UserDeleteButton />
          <ToogleAdminButton />
          <OrderPlugsurfingKeyAction />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default UserShow;
