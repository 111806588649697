import { CallRosterRequestType } from '../../interfaces/types.generated';
import {
  RaFetchType,
  RaGetResponse,
  RaListParams,
  RaListResponse,
  RaQuery,
} from '../../interfaces/RaQueries';
import { gql } from '@apollo/client';
import { ApiResponse } from '../../interfaces/ApiQueries';
import getCallRosterListParams from '../../utils/getCallRosterListParams';

interface ListResult {
  callRequests: {
    requests: Array<CallRosterRequestType>;
    overallCount: number;
  };
}

export enum CallRosterActions {
  MarkAsDone = 'MARK_AS_DONE',
  MarkAsNoResponse = 'MARK_AS_NO_RESPONSE',
}

const CallRoster = (raFetchType: RaFetchType, params: any): RaQuery => {
  switch (raFetchType) {
    case 'GET_LIST':
      return {
        query: gql`
          query (
            $limit: Int
            $offset: Int
            $requestedTime: CallRequestRequestedTimeTypeEnum
            $dateFilter: CallRosterDateFilterEnum
            $order: CallRosterListOrderEnum!
            $showCompleted: Boolean!
          ) {
            callRequests(
              params: {
                limit: $limit
                offset: $offset
                requestedTime: $requestedTime
                dateFilter: $dateFilter
                order: $order
                showCompleted: $showCompleted
              }
            ) {
              requests {
                id
                requestedDate
                requestedTime
                createdAt
                requestStatus
                resellerName
                user {
                  id
                  email
                  details {
                    firstName
                    lastName
                    phoneNumber
                  }
                }
              }
              overallCount
            }
          }
        `,
        variables: getCallRosterListParams(params as RaListParams),
        parseResponse: ({
          data,
        }: ApiResponse<ListResult>): RaListResponse<CallRosterRequestType> => ({
          data: data.callRequests.requests,
          total: data.callRequests.overallCount,
        }),
      };
    case 'GET_ONE':
      return {
        query: gql`
          query ($id: String!) {
            callRequest(id: $id) {
              id
              requestedDate
              requestedTime
              createdAt
              requestStatus
              resellerName
              user {
                id
                email
                details {
                  firstName
                  lastName
                  phoneNumber
                }
              }
            }
          }
        `,
        variables: { id: params.id },
        parseResponse: ({ data }): RaGetResponse<CallRosterRequestType> => ({
          data: {
            ...data.callRequest,
          },
        }),
      };
    case 'UPDATE':
      if (params.data.action === CallRosterActions.MarkAsDone) {
        return {
          query: gql`
            mutation ($id: String!) {
              markCallRequestAsDone(id: $id) {
                id
                requestedDate
                requestedTime
                createdAt
                requestStatus
                resellerName
                user {
                  id
                  email
                  details {
                    firstName
                    lastName
                    phoneNumber
                  }
                }
              }
            }
          `,
          variables: { id: params.id },
          parseResponse: ({ data }): RaGetResponse<CallRosterRequestType> => ({
            data: {
              ...data.markCallRequestAsDone,
            },
          }),
        };
      }
      if (params.data.action === CallRosterActions.MarkAsNoResponse) {
        return {
          query: gql`
            mutation ($id: String!) {
              markCallRequestAsNoResponse(id: $id) {
                id
                requestedDate
                requestedTime
                createdAt
                requestStatus
                resellerName
                user {
                  id
                  email
                  details {
                    firstName
                    lastName
                    phoneNumber
                  }
                }
              }
            }
          `,
          variables: { id: params.id },
          parseResponse: ({ data }): RaGetResponse<CallRosterRequestType> => ({
            data: {
              ...data.markCallRequestAsNoResponse,
            },
          }),
        };
      }
      return {
        query: gql`
          query ($id: String!) {
            callRequest(id: $id) {
              id
              requestedDate
              requestedTime
              createdAt
              requestStatus
              resellerName
              user {
                id
                email
                details {
                  firstName
                  lastName
                  phoneNumber
                }
              }
            }
          }
        `,
        variables: { id: params.id },
        parseResponse: (): any => ({
          data: { id: params.id },
        }),
      };
    default:
      throw new Error(`Unknown fetch type: ${raFetchType}`);
  }
};

export default CallRoster;
