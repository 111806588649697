import React, { useCallback, useState } from 'react';
import { Button, TextareaAutosize } from '@material-ui/core';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import CancelIcon from '@material-ui/icons/Close';
import CompleteIcon from '@material-ui/icons/Check';
import ReportIcon from '@material-ui/icons/Report';
import { BookingActions } from '../../../ra-queries/queries/bookings';

const BookingStatusChange = (props: any) => {
  const record = props.record;
  const [note, setNote] = useState('');
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const handleBookingComplete = useCallback(
    async (currentRecord: any) => {
      return dataProvider
        .update('Bookings', {
          id: currentRecord.id,
          data: { ...currentRecord, action: BookingActions.CompleteBooking },
          previousData: currentRecord,
        })
        .then(() => {
          refresh();
          notify('Booking has been set as completed');
        })
        .catch((error) => {
          refresh();
          notify(`Booking could not be set as completed: ${error.message}`, 'warning');
        });
    },
    [dataProvider, notify, refresh],
  );

  const handleBookingReport = useCallback(
    async (currentRecord: any, incidentDescription: string) => {
      return dataProvider
        .update('Bookings', {
          id: currentRecord.id,
          data: {
            ...currentRecord,
            action: BookingActions.ReportIncident,
            incidentDescription: incidentDescription,
          },
          previousData: currentRecord,
        })
        .then(() => {
          refresh();
          notify('Incident has been reported for this booking.');
        })
        .catch((error) => {
          refresh();
          notify(`Incident can not be reported against this booking: ${error.message}`, 'warning');
        });
    },
    [dataProvider, notify, refresh],
  );

  const handleBookingCancel = useCallback(
    async (currentRecord: any, cancelNote: string) => {
      return dataProvider
        .update('Bookings', {
          id: currentRecord.id,
          data: { ...currentRecord, action: BookingActions.CancelBooking, note: cancelNote },
          previousData: currentRecord,
        })
        .then(() => {
          refresh();
          notify('Booking has been canceled');
        })
        .catch((error) => {
          refresh();
          notify(`Booking could not be canceled: ${error.message}`, 'warning');
        });
    },
    [dataProvider, notify, refresh],
  );

  return (
    <div>
      <TextareaAutosize
        rowsMin={3}
        value={note}
        placeholder="Cancel booking note (applies to booking cancellation)"
        onChange={(e) => {
          setNote(e.target.value);
        }}
        style={{ width: '100%' }}
      />
      <div style={{ margin: '20px 0', display: 'flex' }}>
        <div style={{ marginRight: '20px' }}>
          <Button
            variant="contained"
            style={{ background: '#ffa000', color: 'white' }}
            startIcon={<ReportIcon />}
            onClick={() => handleBookingReport(record, note)}
          >
            Report Incident
          </Button>
        </div>
        <div style={{ marginRight: '20px' }}>
          <Button
            variant="contained"
            style={{ background: '#f44336', color: 'white' }}
            startIcon={<CancelIcon />}
            onClick={() => handleBookingCancel(record, note)}
          >
            Cancel booking
          </Button>
        </div>
        <Button
          variant="contained"
          color="primary"
          style={{ background: '#22aa4f', color: 'white' }}
          startIcon={<CompleteIcon />}
          onClick={() => handleBookingComplete(record)}
        >
          Complete booking
        </Button>
      </div>
    </div>
  );
};

export default BookingStatusChange;
