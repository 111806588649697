const getUserUpdateParams = ({ id, data }: any): any => {
  const {
    firstName,
    lastName,
    phoneNumber,
    dateOfBirth,
    addressLine1,
    addressLine2,
    city,
    postCode,
    county,
    drivingLicenseNumber,
    drivingLicenseValidFrom,
    drivingLicenseValidTo,
    drivingLicenseCountryOfIssue,
    drivingRecordCheckCode,
    drivingLicenseFrontImageId,
    drivingLicenseSelfieImageId,
    passportFrontImageId,
    avatarImageId,
  } = data.details;

  const { restrictionsOnLicense, numberOfPoints, isThreeWayVerified } = data.internalDetails;

  const updateUserDetailsInput = {
    firstName,
    lastName,
    phoneNumber,
    dateOfBirth,
    addressLine1,
    addressLine2,
    city,
    postCode,
    county,
    drivingLicenseNumber,
    drivingLicenseValidFrom,
    drivingLicenseValidTo,
    drivingLicenseCountryOfIssue,
    drivingRecordCheckCode,
    drivingLicenseFrontImageId,
    drivingLicenseSelfieImageId,
    passportFrontImageId,
    avatarImageId,
  };

  const updateUserInternalDetailsInput = {
    restrictionsOnLicense,
    numberOfPoints,
    isThreeWayVerified,
  };

  const { email, emailAllowed, phoneAllowed, smsAllowed } = data.communicationPreferences;

  const preferencesInput = {
    userId: id,
    email,
    emailAllowed,
    phoneAllowed,
    smsAllowed,
  };

  return {
    updateUserDetailsInput,
    updateUserInternalDetailsInput,
    preferencesInput,
    id,
    email: data.email,
    validAsCarOwner: data.validAsCarOwner,
    validAsCarRenter: data.validAsCarRenter,
  };
};

export default getUserUpdateParams;
