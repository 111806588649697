import React from 'react';
import { Create, CreateProps, required, SimpleForm } from 'react-admin';
import { gql, useQuery } from '@apollo/client';
import StyledGridLayout from '../../components/StyledGridLayout';
import { StyledSelectInput, StyledTextInput } from '../../components/StyledInputs';

const GET_BUSINESSES = gql`
  query Businesses {
    businesses {
      id
      displayName
    }
  }
`;

const CarCreate = (props: CreateProps) => {
  const { data } = useQuery(GET_BUSINESSES);
  return (
    <Create {...props} title="Car">
      <SimpleForm redirect="show">
        <StyledGridLayout>
          <StyledTextInput source="registration" validate={[required()]} />
          <StyledSelectInput
            source="businessId"
            validate={[required()]}
            choices={
              data && data.businesses
                ? data.businesses.map(
                    ({ id, displayName: name }: { id: string; displayName: string }) => ({
                      id,
                      name,
                    }),
                  )
                : []
            }
          />
        </StyledGridLayout>
      </SimpleForm>
    </Create>
  );
};

export default CarCreate;
