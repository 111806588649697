import React, { useCallback } from 'react';
import { Button, Card, CardActions, CardContent, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { BusinessActions } from '../../../ra-queries/queries/businesses';

const useStyles = makeStyles({
  root: {
    marginBottom: '20px',
  },
});

const BusinessReviews = (props: any) => {
  const reviews = props.record.reviews;
  const business = props.business;
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const classes = useStyles();

  const handleBusinessReviewDelete = useCallback(
    async (reviewId: string) => {
      return dataProvider
        .update('Businesses', {
          id: business.id,
          data: { ...business, action: BusinessActions.RemoveBusinessReview, reviewId },
          previousData: business,
        })
        .then(() => {
          refresh();
          notify('Review has been deleted');
        })
        .catch((error) => {
          refresh();
          notify(`Review could not be deleted: ${error.message}`, 'warning');
        });
    },
    [dataProvider, notify, refresh, business],
  );

  return (
    <>
      {reviews.length > 0 ? (
        reviews.map((review: any) => (
          <Card key={review.id} className={classes.root}>
            <CardContent>
              <Typography variant="overline" display="block" gutterBottom>
                score: {review.score}
              </Typography>
              {review.note}
            </CardContent>
            <CardActions>
              <Button
                size="small"
                style={{ background: 'none', color: '#f44336' }}
                onClick={() => handleBusinessReviewDelete(review.id)}
                startIcon={<DeleteIcon />}
              >
                Delete review
              </Button>
            </CardActions>
          </Card>
        ))
      ) : (
        <div style={{ margin: '20px 0 30px 0' }}>No reviews added yet.</div>
      )}
    </>
  );
};

export default BusinessReviews;
