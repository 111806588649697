import React from 'react';
import {
  DateField,
  FunctionField,
  Link,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  useShowController,
} from 'react-admin';

const CallRosterShow = (props: ShowProps) => {
  const { record } = useShowController(props);
  if (!record) return null;

  return (
    <Show {...props} title="Renewal Request">
      <TabbedShowLayout>
        <Tab label="summary" contentClassName="two-columns-layout">
          <TextField source="status" label="Status" />
          <TextField source="subscription.bundleType.name" label="Bundle" />
          <FunctionField
            label="Subscriber"
            render={(request: any) => {
              return (
                <Link to={`/Users/${request.user.id}/show`}>
                  {request.user.details.firstName} {request.user.details.lastName}
                </Link>
              );
            }}
          />
          <TextField source="subscription.resellerBusiness.name" label="Reseller" />
          <FunctionField
            label="Requested Call"
            render={(request: any) => {
              return request.user.callRequest ? (
                <Link to={`/CallRoster/${request.user.callRequest.id}/show`}>Yes</Link>
              ) : (
                'No'
              );
            }}
          />
          <TextField source="subscription.yearOfSubscription" label="Year of Subscription" />
          <DateField source="createdAt" showTime />
          <DateField source="subscription.currentPeriodEnd" label="Start Date" showTime />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
export default CallRosterShow;
