import { createTheme, Theme } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import baseStyled, { ThemedStyledInterface } from 'styled-components';

const theme = {
  palette: {
    primary: blue,
    secondary: blue,
  },
  shape: {
    borderRadius: 5,
  },
  overrides: {
    RaMenuItemLink: {
      root: {
        borderLeft: '5px solid transparent',
      },
      active: {
        borderLeft: `5px solid ${blue['500']}`,
      },
    },
    MuiPaper: {
      root: {
        padding: 0,
      },
    },
  },
};

export const defaultTheme = createTheme(theme);

export const styled = baseStyled as ThemedStyledInterface<Theme>;
