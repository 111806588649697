import React, { useCallback, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { displayDateFromTimestamp } from '../../../utils/stringHelpers';
import { InvitesActions } from '../../../ra-queries/queries/invites';

const useStyles = makeStyles({
  root: {
    marginBottom: '20px',
  },
});

const InviteNotes = (props: any) => {
  const notes = props.record.notes;
  const invite = props.invite;
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const classes = useStyles();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState<string>();
  const [editedNote, setEditedNote] = useState('');
  const [editedNoteId, setEditedNoteId] = useState('');

  const handleBundleInviteNoteAdd = useCallback(
    async (note: string) => {
      return dataProvider
        .update('Invites', {
          id: invite.id,
          data: { ...invite, action: InvitesActions.AddInviteNote, note },
          previousData: invite,
        })
        .then(() => {
          refresh();
          notify('Note has been added');
        })
        .catch((error) => {
          refresh();
          notify(`Note could not be added: ${error.message}`, 'warning');
        });
    },
    [dataProvider, notify, refresh, invite],
  );

  const handleBundleInviteNoteEdit = useCallback(
    async (note: string, noteId: string) => {
      return dataProvider
        .update('Invites', {
          id: invite.id,
          data: { ...invite, action: InvitesActions.EditInviteNote, note, noteId },
          previousData: invite,
        })
        .then(() => {
          refresh();
          notify('Note has been updated');
        })
        .catch((error) => {
          refresh();
          notify(`Note could not be updated: ${error.message}`, 'warning');
        });
    },
    [dataProvider, notify, refresh, invite],
  );

  const handleBundleInviteNoteDelete = useCallback(
    async (noteId: string) => {
      return dataProvider
        .update('Invites', {
          id: invite.id,
          data: { ...invite, action: InvitesActions.RemoveInviteNote, noteId },
          previousData: invite,
        })
        .then(() => {
          refresh();
          notify('Note has been deleted');
        })
        .catch((error) => {
          refresh();
          notify(`Note could not be deleted: ${error.message}`, 'warning');
        });
    },
    [dataProvider, notify, refresh, invite],
  );

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleEditAction = (noteId: string) => {
    const oldNote = notes.find((note: any) => note.id === noteId);
    if (oldNote) {
      setModalOpen(true);
      setModalAction('Edit');
      setEditedNote(oldNote.note);
      setEditedNoteId(oldNote.id);
    }
  };

  const handleAddAction = () => {
    setModalOpen(true);
    setModalAction('Add');
    setEditedNote('');
  };

  return (
    <>
      <div style={{ margin: '20px 0 30px 0' }}>
        <Button variant="contained" color="primary" onClick={handleAddAction}>
          Add note
        </Button>
      </div>
      {notes.map((note: any) => (
        <Card key={note.id} className={classes.root}>
          <CardContent>
            <Typography variant="overline" display="block" gutterBottom>
              {displayDateFromTimestamp(note.createdAt)}
            </Typography>
            {note.note}
          </CardContent>
          <CardActions>
            <Button
              size="small"
              color="primary"
              startIcon={<EditIcon />}
              onClick={() => handleEditAction(note.id)}
            >
              Edit note
            </Button>
            <Button
              size="small"
              style={{ background: 'none', color: '#f44336' }}
              onClick={() => handleBundleInviteNoteDelete(note.id)}
              startIcon={<DeleteIcon />}
            >
              Delete note
            </Button>
          </CardActions>
        </Card>
      ))}
      <Dialog open={modalOpen} onClose={handleModalClose} fullWidth>
        <DialogTitle id="responsive-dialog-title">{modalAction} user note</DialogTitle>
        <DialogContent>
          <TextareaAutosize
            rowsMin={3}
            value={editedNote}
            onChange={(e) => {
              setEditedNote(e.target.value);
            }}
            style={{ width: '100%' }}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleModalClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (modalAction === 'Edit') {
                handleBundleInviteNoteEdit(editedNote, editedNoteId);
              } else {
                handleBundleInviteNoteAdd(editedNote);
              }
            }}
            color="primary"
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InviteNotes;
