import React from 'react';
import {
  BooleanInput,
  Edit,
  EditProps,
  email,
  FileField,
  FileInput,
  required,
  SimpleForm,
} from 'react-admin';
import StyledGridLayout from '../../components/StyledGridLayout';
import { StyledSelectInput, StyledTextInput } from '../../components/StyledInputs';
import { SimpleConfirmDeleteToolbar } from '../../components/SimpleConfirmDeleteToolbar';
import { ColorInput } from '../../components/ColorInput';

const BusinessEdit = (props: EditProps) => (
  <Edit {...props} title="Businesses">
    <SimpleForm redirect="show" toolbar={<SimpleConfirmDeleteToolbar />}>
      <StyledGridLayout>
        <StyledTextInput source="displayName" />
        <StyledTextInput source="name" label="Company name" />
        <StyledSelectInput
          source="type"
          label="Type"
          choices={[
            { id: 'Reseller', name: 'Reseller' },
            { id: 'BundlePartner', name: 'Bundle Partner' },
            { id: 'Insurer', name: 'Insurer' },
          ]}
          validate={[required()]}
        />
        <StyledTextInput source="brandName" />
        <StyledTextInput source="companyNumber" />
        <StyledTextInput source="locationName" />
        <StyledTextInput source="emailAddress" validate={email()} />
        <StyledTextInput source="phoneNumber" />
        <StyledTextInput source="address" />
        <StyledTextInput source="tradingHours" />
        <FileInput source="logo" accept="image/*">
          <FileField source="src" title="BusinessLogo" />
        </FileInput>
        <BooleanInput source="isFleetInsuranceInPlace" label="Is Business insurance in place" />
        <ColorInput source="anchorColor" picker={'Sketch'} />
        <BooleanInput source="brandedEmails" label="Brand emails" />
        <StyledTextInput source="dashboardTagline" label="Dashboard Tagline" />
        <FileInput source="dashboardBackground" accept="image/*">
          <FileField source="src" title="BusinessDashboardBackground" />
        </FileInput>
        <BooleanInput
          source="proposalNumberRequired"
          label="Proposal Number Required for Invites"
        />
        <BooleanInput
          source="tintDashboardBackground"
          label="Tint dashboard background with Anchor color"
        />
      </StyledGridLayout>
    </SimpleForm>
  </Edit>
);

export default BusinessEdit;
