import React from 'react';
import {
  Datagrid,
  EditButton,
  Filter,
  FunctionField,
  List,
  ListProps,
  NumberField,
} from 'react-admin';
import { StyledSelectInput } from '../../components/StyledInputs';

const PricingFilter = (props: any) => (
  <Filter {...props}>
    <StyledSelectInput
      source="type"
      label="Type"
      choices={[
        { id: 'Business', name: 'Business' },
        { id: 'Normal', name: 'Normal' },
      ]}
      alwaysOn
    />
  </Filter>
);

const PricingsList = (props: ListProps) => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={<PricingFilter />}
    filterDefaultValues={{ type: 'Business' }}
    pagination={false}
  >
    <Datagrid rowClick="show">
      <FunctionField
        label="Value"
        render={(pricing: any) => `From ${pricing.valueMin} to ${pricing.valueMax}`}
      />
      <NumberField
        source="pricePerHour"
        sortable={false}
        locales="en-GB"
        options={{ style: 'currency', currency: 'GBP' }}
      />
      <NumberField
        source="pricePerDay"
        sortable={false}
        locales="en-GB"
        options={{ style: 'currency', currency: 'GBP' }}
      />
      <NumberField
        source="pricePerWeek"
        sortable={false}
        locales="en-GB"
        options={{ style: 'currency', currency: 'GBP' }}
      />
      <EditButton />
    </Datagrid>
  </List>
);

export default PricingsList;
