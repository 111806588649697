import React from 'react';
import {
  DateField,
  FunctionField,
  Link,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  useShowController,
} from 'react-admin';

const MyEvShow = (props: ShowProps) => {
  const { record } = useShowController(props);

  if (!record) return null;

  return (
    <Show {...props} title="EV Detail">
      <TabbedShowLayout>
        <Tab label="summary" contentClassName="two-columns-layout">
          <TextField source="registration" label="Registration" sortable={false} />
          <FunctionField
            label="Customer Name"
            render={(myEv: any) => {
              return (
                <Link
                  to={`/Users/${myEv.user.id}/show`}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {myEv.user.details.firstName} {myEv.user.details.lastName}
                </Link>
              );
            }}
          />
          <FunctionField
            label="MOT Reminder Set"
            render={(myEv: any) => {
              return myEv.reminders.tax ? `Yes` : `No`;
            }}
          />
          <FunctionField
            label="Tax Reminder Set"
            render={(myEv: any) => {
              return myEv.reminders.mot ? `Yes` : `No`;
            }}
          />
          <DateField source="taxAndMot.motExpiryDate" label="MOT Expiry" sortable />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
export default MyEvShow;
