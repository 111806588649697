import React from 'react';
import { Create, CreateProps, required, SimpleForm, Toolbar } from 'react-admin';
import SaveButtonAware from '../../components/SaveButtonAware';
import StyledGridLayout from '../../components/StyledGridLayout';
import { StyledTextInput } from '../../components/StyledInputs';

const TagCreateToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButtonAware />
  </Toolbar>
);

const TagCreate = (props: CreateProps) => (
  <Create {...props} title="Tags">
    <SimpleForm toolbar={<TagCreateToolbar />}>
      <StyledGridLayout>
        <StyledTextInput source="name" validate={[required()]} />
      </StyledGridLayout>
    </SimpleForm>
  </Create>
);

export default TagCreate;
