import { RaListParams } from '../interfaces/RaQueries';
import { ApiListParams } from '../interfaces/ApiQueries';
import { capitalize } from './stringHelpers';

interface CodesListParams extends ApiListParams {
  assignedToSubscription?: boolean;
  unavailable?: boolean;
  sort?: string;
}

const getCodesListParams = ({ pagination, sort, filter }: RaListParams): CodesListParams => {
  const { perPage, page } = pagination;
  const limit = perPage;
  const offset = (page - 1) * (perPage || 1);
  const sortConverted = `CreatedAt${capitalize(sort.order)}`;

  return {
    limit,
    offset,
    sort: sortConverted,
    searchPhrase: filter.q,
    unavailable: filter.unavailable,
    assignedToSubscription: filter.unavailable,
  };
};

export default getCodesListParams;
