import React from 'react';
import { Datagrid, DeleteButton, List, ListProps, TextField } from 'react-admin';

const TagsList = (props: ListProps) => (
  <List {...props} bulkActionButtons={false} pagination={false}>
    <Datagrid rowClick="show">
      <TextField source="name" sortable={false} />
      <DeleteButton mutationMode="pessimistic" confirmTitle="Delete item" />
    </Datagrid>
  </List>
);

export default TagsList;
