import { RaListParams } from '../interfaces/RaQueries';
import { ApiListParams } from '../interfaces/ApiQueries';
import { OfferQueryOrderEnum } from '../interfaces/types.generated';

interface LeasingEnquiriesListParams extends ApiListParams {
  leasingOfferId?: string;
  showAll: boolean;
}

const getLeasingEnquiriesListParams = ({
  pagination,
  sort,
  filter,
}: RaListParams): LeasingEnquiriesListParams => {
  const { perPage, page } = pagination;
  const limit = perPage;
  const offset = (page - 1) * (perPage || 1);

  let order: string | undefined;
  switch (`${sort.field}${sort.order}`) {
    case 'createdAtASC':
      order = OfferQueryOrderEnum.CreatedAtAsc;
      break;
    case 'createdAtDESC':
      order = OfferQueryOrderEnum.CreatedAtDesc;
      break;
    default:
      order = OfferQueryOrderEnum.CreatedAtDesc;
      break;
  }

  return {
    limit,
    offset,
    order,
    searchPhrase: filter.q,
    leasingOfferId: filter.leasingOfferId,
    showAll: filter.showAll ? filter.showAll : false,
  };
};

export default getLeasingEnquiriesListParams;
