/* eslint-disable no-underscore-dangle */
import { gql } from '@apollo/client';
import {
  RaFetchType,
  RaGetResponse,
  RaListParams,
  RaListResponse,
  RaQuery,
} from '../../interfaces/RaQueries';
import { BundleSubscriptionType, BundleTypeType } from '../../interfaces/types.generated';
import { ApiResponse } from '../../interfaces/ApiQueries';
import getSubscriptionsListParams from '../../utils/getSubscriptionsListParams';

interface ListResult {
  bundleSubscriptionsV2: {
    subscriptions: Array<BundleSubscriptionType>;
    overallCount: number;
  };
}

export enum SubscriptionActions {
  CancelSubscription = 'CANCEL_SUBSCRIPTION',
  DeleteSubscription = 'DELETE_SUBSCRIPTION',
  IssueRenewal = 'ISSUE_RENEWAL',
  TriggerProductUpdate = 'TRIGGER_PRODUCT_UPDATE',
}

const Subscriptions = (raFetchType: RaFetchType, params: any): RaQuery => {
  switch (raFetchType) {
    case 'GET_LIST':
      return {
        query: gql`
          query (
            $limit: Int
            $offset: Int
            $onlyDueToRenewal: Boolean
            $searchPhrase: String
            $bundleTypeId: String
            $resellerBusinessId: String
          ) {
            bundleSubscriptionsV2(
              params: {
                limit: $limit
                offset: $offset
                onlyDueToRenewal: $onlyDueToRenewal
                searchPhrase: $searchPhrase
                bundleTypeId: $bundleTypeId
                resellerBusinessId: $resellerBusinessId
              }
            ) {
              subscriptions {
                id
                code
                status
                createdAt
                currentPeriodEnd
                yearOfSubscription
                user {
                  details {
                    firstName
                    lastName
                  }
                  callRequest {
                    id
                  }
                }
                resellerBusiness {
                  id
                  name
                  displayName
                }
                bundleType {
                  id
                  name
                  price
                  description
                  periodDuration
                }
                additionalData {
                  carMakerName
                  carModelName
                  estimatedCarDeliveryDate
                  isCarPartOfFleet
                }
                stripeInvoices {
                  id
                  status
                  stripeInvoiceUrl
                }
              }
              overallCount
            }
          }
        `,
        variables: getSubscriptionsListParams(params as RaListParams),
        parseResponse: ({
          data,
        }: ApiResponse<ListResult>): RaListResponse<BundleSubscriptionType> => ({
          data: data.bundleSubscriptionsV2.subscriptions.map((sub: any) => {
            return {
              ...sub,
              currentPeriodEnd: sub.currentPeriodEnd ? sub.currentPeriodEnd * 1000 : null,
            };
          }),
          total: data.bundleSubscriptionsV2.overallCount,
        }),
      };
    case 'GET_ONE':
      return {
        query: gql`
          query ($id: String!) {
            bundleSubscription(id: $id) {
              id
              code
              status
              createdAt
              currentPeriodPrice
              usedPromoCode
              yearOfSubscription
              stripeSubscriptionId
              stripeSubscriptionUrl
              stripeInvoices {
                id
                status
                stripeInvoiceUrl
              }
              currentPeriod {
                timeStart
                timeEnd
              }
              resellerBusiness {
                id
                name
                displayName
              }
              user {
                id
                details {
                  firstName
                  lastName
                }
                callRequest {
                  id
                }
              }
              bundleType {
                id
                name
                price
                description
                periodDuration
              }
              additionalData {
                carMakerName
                carModelName
                estimatedCarDeliveryDate
                isCarPartOfFleet
              }
              subscriberDetails {
                phoneNumber
                addressLine1
                addressLine2
                town
                county
                postCode
              }
              renewalRequests {
                id
                status
                createdAt
              }
            }
          }
        `,
        variables: params,
        parseResponse: ({ data }): RaGetResponse<BundleTypeType> => ({
          data: {
            ...data.bundleSubscription,
            currentPeriodPrice: Number(data.bundleSubscription.currentPeriodPrice) / 100,
            currentPeriod: {
              timeStart: data.bundleSubscription.currentPeriod
                ? data.bundleSubscription.currentPeriod.timeStart * 1000
                : undefined,
              timeEnd: data.bundleSubscription.currentPeriod
                ? data.bundleSubscription.currentPeriod.timeEnd * 1000
                : undefined,
            },
            bundleType: {
              ...data.bundleSubscription.bundleType,
              price: Number(data.bundleSubscription.bundleType.price) / 100,
            },
          },
        }),
      };
    case 'CREATE':
      return {
        query: gql`
          mutation ($input: BundleSubscriptionCreateInput!) {
            createBundleSubscription(input: $input) {
              id
            }
          }
        `,
        variables: { input: params.data },
        parseResponse: ({ data }): RaGetResponse<BundleTypeType> => ({
          data: data.createBundleSubscription,
        }),
      };
    case 'UPDATE':
      if (params.data.action === SubscriptionActions.CancelSubscription) {
        return {
          query: gql`
            mutation cancelBundleSubscription($bundleSubscriptionId: String!) {
              cancelBundleSubscription(bundleSubscriptionId: $bundleSubscriptionId)
            }
          `,
          variables: { bundleSubscriptionId: params.id },
          parseResponse: (): RaGetResponse<BundleTypeType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }

      if (params.data.action === SubscriptionActions.TriggerProductUpdate) {
        return {
          query: gql`
            mutation triggerSubscriptionScheduleUpdate($bundleSubscriptionId: String!) {
              triggerSubscriptionScheduleUpdate(bundleSubscriptionId: $bundleSubscriptionId)
            }
          `,
          variables: { bundleSubscriptionId: params.id },
          parseResponse: (): RaGetResponse<BundleTypeType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }

      if (params.data.action === SubscriptionActions.IssueRenewal) {
        return {
          query: gql`
            mutation issueRenewal($bundleSubscriptionId: String!) {
              issueRenewal(bundleSubscriptionId: $bundleSubscriptionId)
            }
          `,
          variables: { bundleSubscriptionId: params.id },
          parseResponse: (): RaGetResponse<BundleTypeType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }

      // STANDARD UPDATE ACTION
      // DELETE SUBSCRIPTION
      return {
        query: gql`
          mutation deleteBundleSubscription($bundleSubscriptionId: String!) {
            deleteBundleSubscription(bundleSubscriptionId: $bundleSubscriptionId)
          }
        `,
        variables: { bundleSubscriptionId: params.id },
        parseResponse: (): RaGetResponse<BundleTypeType> => ({
          data: { id: params.id, ...params.data },
        }),
      };
    default:
      throw new Error(`Unknown fetch type: ${raFetchType}`);
  }
};

export default Subscriptions;
