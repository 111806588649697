/* eslint-disable no-underscore-dangle */
import { gql } from '@apollo/client';
import {
  RaFetchType,
  RaGetResponse,
  RaListParams,
  RaListResponse,
  RaQuery,
} from '../../interfaces/RaQueries';
import { BundleRenewalRequestType } from '../../interfaces/types.generated';
import { ApiResponse } from '../../interfaces/ApiQueries';
import getRenewalRequestsListParams from '../../utils/getRenewalRequestListParams';

interface ListResult {
  renewalRequests: {
    requests: Array<BundleRenewalRequestType>;
    overallCount: number;
  };
}

const RenewalRequests = (raFetchType: RaFetchType, params: any): RaQuery => {
  switch (raFetchType) {
    case 'GET_LIST':
      return {
        query: gql`
          query (
            $limit: Int
            $offset: Int
            $searchPhrase: String
            $order: BundleRenewalRequestQueryOrderEnum!
            $onlyDueToRenewal: Boolean
          ) {
            renewalRequests(
              params: {
                limit: $limit
                offset: $offset
                searchPhrase: $searchPhrase
                order: $order
                onlyDueToRenewal: $onlyDueToRenewal
              }
            ) {
              requests {
                id
                createdAt
                status
                user {
                  id
                  email
                  details {
                    firstName
                    lastName
                  }
                  callRequest {
                    id
                  }
                }
                subscription {
                  yearOfSubscription
                  bundleType {
                    name
                  }
                  resellerBusiness {
                    name
                  }
                  currentPeriodEnd
                }
              }
              overallCount
            }
          }
        `,
        variables: getRenewalRequestsListParams(params as RaListParams),
        parseResponse: ({
          data,
        }: ApiResponse<ListResult>): RaListResponse<BundleRenewalRequestType> => ({
          data: data.renewalRequests.requests.map((item) => {
            return {
              ...item,
              subscription: {
                ...item.subscription,
                currentPeriodEnd: item.subscription.currentPeriodEnd
                  ? item.subscription.currentPeriodEnd * 1000
                  : null,
              },
            };
          }),
          total: data.renewalRequests.overallCount,
        }),
      };
    case 'GET_ONE':
      return {
        query: gql`
          query ($id: String!) {
            renewalRequest(id: $id) {
              id
              createdAt
              status
              user {
                id
                email
                details {
                  firstName
                  lastName
                }
                callRequest {
                  id
                }
              }
              subscription {
                yearOfSubscription
                bundleType {
                  name
                }
                resellerBusiness {
                  name
                }
                currentPeriodEnd
              }
            }
          }
        `,
        variables: { id: params.id },
        parseResponse: ({ data }): RaGetResponse<BundleRenewalRequestType> => ({
          data: {
            ...data.renewalRequest,
            subscription: {
              ...data.renewalRequest.subscription,
              currentPeriodEnd: data.renewalRequest.subscription.currentPeriodEnd
                ? data.renewalRequest.subscription.currentPeriodEnd * 1000
                : null,
            },
          },
        }),
      };
    case 'CREATE':
    default:
      throw new Error(`Unknown fetch type: ${raFetchType}`);
  }
};

export default RenewalRequests;
