import React, { useCallback, useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { Button } from '@material-ui/core';
import 'quill/dist/quill.snow.css'; // Add css for snow theme
import { useQuill } from 'react-quilljs';
import EditIcon from '@material-ui/icons/Edit';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { CarActions } from '../../../ra-queries/queries/cars';

const CarGuideEditor = (props: any) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: [['bold', 'italic']],
    },
  });

  useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(props.record.carGuide);
    }
  }, [props.record.carGuide, quill]);

  const handleCarGuideSave = useCallback(
    async (carGuide: string) => {
      return dataProvider
        .update('Cars', {
          id: props.record.id,
          data: { ...props.record, action: CarActions.UpdateCarGuide, carGuide },
          previousData: props.record,
        })
        .then(() => {
          refresh();
          notify('Car guide has been updated');
        })
        .catch((error) => {
          refresh();
          notify(`Car guide could not be updated: ${error.message}`, 'warning');
        });
    },
    [dataProvider, notify, props.record, refresh],
  );

  return (
    <div>
      <div style={{ width: '100%', height: '300px' }}>
        <div ref={quillRef} />
      </div>
      <div style={{ margin: '60px 0 20px 0', display: 'flex' }}>
        <div style={{ marginRight: '20px' }}>
          <Button onClick={() => props.setIsEditing(false)}>Cancel</Button>
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleCarGuideSave(quillRef.current.firstChild.innerHTML);
          }}
        >
          Save car guide
        </Button>
      </div>
    </div>
  );
};

const CarGuide = (props: any) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      {isEditing ? (
        <CarGuideEditor {...props} setIsEditing={setIsEditing} />
      ) : (
        <div style={{ marginTop: '20px', fontSize: '12px', lineHeight: '1.3' }}>
          {props.record.carGuide ? parse(props.record.carGuide) : '-'}
          <div style={{ margin: '20px 0', display: 'flex' }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<EditIcon />}
              onClick={() => setIsEditing(true)}
            >
              Update car guide
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default CarGuide;
