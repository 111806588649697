/* eslint-disable no-underscore-dangle */
import { gql } from '@apollo/client';
import {
  RaFetchType,
  RaGetResponse,
  RaListParams,
  RaListResponse,
  RaParams,
  RaQuery,
} from '../../interfaces/RaQueries';
import { ApiResponse } from '../../interfaces/ApiQueries';
import getPricingsListParams from '../../utils/getPricingsListParams';

interface ListResult {
  carPricing: Array<any>;
}

const Pricings = (raFetchType: RaFetchType, params: RaParams<any>): RaQuery => {
  switch (raFetchType) {
    case 'GET_LIST':
      return {
        query: gql`
          query ($business: Boolean!) {
            carPricing(business: $business) {
              valueMin
              valueMax
              pricePerDay
              pricePerHour
              pricePerWeek
            }
          }
        `,
        variables: getPricingsListParams(params as RaListParams),
        parseResponse: ({ data }: ApiResponse<ListResult>): RaListResponse<any> => ({
          data: data.carPricing.map((pricing, i) => {
            const isBusiness = getPricingsListParams(params as RaListParams).business;
            return {
              id: `${isBusiness ? 'business' : 'normal'}-${i}`,
              ...pricing,
              pricePerDay: Number(pricing.pricePerDay) / 100,
              pricePerHour: Number(pricing.pricePerHour) / 100,
              pricePerWeek: Number(pricing.pricePerWeek) / 100,
            };
          }),
          total: data.carPricing.length,
        }),
      };
    case 'GET_ONE':
      return {
        query: gql`
          query ($business: Boolean!) {
            carPricing(business: $business) {
              valueMin
              valueMax
              pricePerDay
              pricePerHour
              pricePerWeek
            }
          }
        `,
        variables: { business: (params as any).id.split('-')[0] === 'business' },
        parseResponse: ({ data }): RaGetResponse<any> => {
          const index = (params as any).id.split('-')[1];
          const pricing = data.carPricing[index];
          const convertedPricing = {
            ...pricing,
            pricePerDay: Number(pricing.pricePerDay) / 100,
            pricePerHour: Number(pricing.pricePerHour) / 100,
            pricePerWeek: Number(pricing.pricePerWeek) / 100,
          };
          return {
            data: {
              id: (params as any).id,
              ...convertedPricing,
            },
          };
        },
      };
    case 'UPDATE':
      return {
        query: gql`
          mutation ($input: CarPricingUpdateThresholdInputType!) {
            updateCarPricingThreshold(input: $input) {
              valueMin
              valueMax
            }
          }
        `,
        variables: {
          input: {
            valueMin: params.data.valueMin,
            valueMax: params.data.valueMax,
            business: (params as any).id.split('-')[0] === 'business',
            pricePerDay: Number(params.data.pricePerDay) * 100,
          },
        },
        parseResponse: (): RaGetResponse<any> => ({
          data: { id: (params as any).id, ...params.data },
        }),
      };
    default:
      throw new Error(`Unknown fetch type: ${raFetchType}`);
  }
};

export default Pricings;
