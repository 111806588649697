import React from 'react';
import { Datagrid, FunctionField, List, ListProps, TextField } from 'react-admin';

const StripeTestClockList = (props: ListProps) => (
  <List {...props} bulkActionButtons={false} pagination={false}>
    <Datagrid rowClick="show">
      <TextField source="id" sortable={false} />
      <TextField source="name" sortable={false} />
      <FunctionField
        label="Customers"
        render={(record: any) => (record.customers ? record.customers.length : 0)}
      />
    </Datagrid>
  </List>
);

export default StripeTestClockList;
