import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-admin';

const useStyles = makeStyles({
  root: {
    marginBottom: '20px',
  },
});

const AttachedOffers = (props: any) => {
  const bundle = props.record;
  const attachedOffers = props.record.attachedOffers;
  const classes = useStyles();

  return (
    <>
      {attachedOffers.length <= 0 && (
        <Typography variant="h6" display="block" gutterBottom>
          No offers attached to this bundle.
        </Typography>
      )}
      {attachedOffers.map((offer: any) => (
        <Card key={bundle.id} className={classes.root}>
          <CardContent>
            <Typography variant="overline" display="block" gutterBottom>
              Attachment Type: {offer.offerType}
            </Typography>
            <Typography variant="overline" display="block" gutterBottom>
              Rank: {offer.rank}
            </Typography>
            <Typography variant="h6" display="block" gutterBottom>
              <Link to={`/Offers/${offer.offer.id}/show`}>{offer.offer.offerName}</Link>
            </Typography>
          </CardContent>
        </Card>
      ))}
    </>
  );
};

export default AttachedOffers;
