import React, { useCallback } from 'react';
import {
  DateField,
  FunctionField,
  Link,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  useDataProvider,
  useNotify,
  useRefresh,
  useShowController,
} from 'react-admin';
import { Button } from '@material-ui/core';
import { CallRosterActions } from '../../ra-queries/queries/callroster';
import { CallRequestStatusEnum } from '../../interfaces/types.generated';

const CallRosterShow = (props: ShowProps) => {
  const { record } = useShowController(props);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const handleMarkAsComplete = useCallback(
    async (currentRecord: any) => {
      return dataProvider
        .update('CallRoster', {
          id: currentRecord.id,
          data: { ...currentRecord, action: CallRosterActions.MarkAsDone },
          previousData: currentRecord,
        })
        .then(() => {
          refresh();
          notify('Call Request has been marked as complete');
        })
        .catch((error) => {
          refresh();
          notify(`Call Request could not be marked as complete: ${error.message}`, 'warning');
        });
    },
    [dataProvider, notify, refresh],
  );

  const handleMarkAsNoResponse = useCallback(
    async (currentRecord: any) => {
      return dataProvider
        .update('CallRoster', {
          id: currentRecord.id,
          data: { ...currentRecord, action: CallRosterActions.MarkAsNoResponse },
          previousData: currentRecord,
        })
        .then(() => {
          refresh();
          notify('Call Request has been marked as No Response');
        })
        .catch((error) => {
          refresh();
          notify(`Call Request could not be marked as No Response: ${error.message}`, 'warning');
        });
    },
    [dataProvider, notify, refresh],
  );

  if (!record) return null;

  return (
    <Show {...props} title="Bookings">
      <TabbedShowLayout>
        <Tab label="summary" contentClassName="two-columns-layout">
          <TextField source="id" sortable={false} />
          <DateField source="createdAt" sortable={false} showTime />
          <FunctionField
            label="Customer Name"
            sortable={false}
            render={(request: any) => {
              return `${request.user.details?.firstName} ${request.user.details?.lastName}`;
            }}
          />
          <FunctionField
            label="Customer Email"
            sortable={false}
            render={(request: any) => {
              return <Link to={`/Users/${request.user.id}/show`}>{request.user.email}</Link>;
            }}
          />
          <TextField
            source="user.details.phoneNumber"
            label="Customer Phone Number"
            sortable={false}
          />
          <DateField source="requestedDate" label="Requested Date" sortable={false} />
          <TextField source="requestedTime" label="Requested Time" sortable={false} />
          <TextField source="requestStatus" label="Request Status" />
          <TextField source="resellerName" label="Reseller Name" sortable={false} />
          {record.requestStatus === CallRequestStatusEnum.Pending && (
            <>
              <div style={{ marginBottom: '20px', display: 'flex' }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: '10px', display: 'flex' }}
                  onClick={() => handleMarkAsComplete(record)}
                >
                  Mark as Complete
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleMarkAsNoResponse(record)}
                >
                  Mark as No Response
                </Button>
              </div>
            </>
          )}
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
export default CallRosterShow;
