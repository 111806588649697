import React from 'react';
import {
  BooleanInput,
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  Link,
  List,
  ListProps,
  TextField,
} from 'react-admin';
import { StyledSelectInput } from '../../components/StyledInputs';

const CallRosterFilter = (props: any) => (
  <Filter {...props}>
    <StyledSelectInput
      source="dateFilter"
      label="Date Filter"
      choices={[
        { id: 'THIS_DAY', name: 'Today' },
        { id: 'THIS_WEEK', name: 'This Week' },
        { id: 'THIS_MONTH', name: 'This Month' },
      ]}
      alwaysOn
    />
    <StyledSelectInput
      source="requestedTime"
      label="Requested Time"
      choices={[
        { id: 'AM', name: 'AM' },
        { id: 'PM', name: 'PM' },
      ]}
      alwaysOn
    />
    <BooleanInput
      label="Show Completed/No Response"
      alwaysOn
      source="showCompleted"
      defaultValue={false}
    />
  </Filter>
);

const callRequestRowStyle = () => ({
  backgroundColor: 'white',
});

const CallRosterList = (props: ListProps) => {
  return (
    <List {...props} bulkActionButtons={false} empty={false} filters={<CallRosterFilter />}>
      <Datagrid rowClick="show" rowStyle={callRequestRowStyle}>
        <DateField source="createdAt" sortable showTime />
        <FunctionField
          label="Customer Name"
          sortable={false}
          render={(record: any) => {
            return `${record.user.details?.firstName} ${record.user.details?.lastName}`;
          }}
        />
        <FunctionField
          label="Customer Email"
          sortable={false}
          render={(record: any) => {
            return <Link to={`/Users/${record.user.id}/show`}>{record.user.email}</Link>;
          }}
        />
        <TextField
          source="user.details.phoneNumber"
          label="Customer Phone Number"
          sortable={false}
        />
        <DateField source="requestedDate" label="Requested Date" sortable />
        <TextField source="requestedTime" label="Requested Time" sortable={false} />
        <TextField source="resellerName" label="Reseller Name" sortable={false} />
      </Datagrid>
    </List>
  );
};

export default CallRosterList;
