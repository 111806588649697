import React, { useState } from 'react';
import { FieldTitle, useInput, Validator } from 'ra-core';
import * as ReactColor from 'react-color';
import { StyledColorInputCover, StyledColorInputPopup } from './StyledColorInput';
import TextField from '@material-ui/core/TextField';

interface ColorInputProps {
  resource?: string;
  label?: string;
  options?: {
    disableAlpha: true;
  };
  source: string;
  helperText?: string;
  className?: string;
  isRequired?: boolean;
  validate?: Validator | Validator[] | undefined;
  picker?:
    | 'Alpha'
    | 'Block'
    | 'Check'
    | 'Chrome'
    | 'Circle'
    | 'Compact'
    | 'Github'
    | 'Hue'
    | 'Material'
    | 'Photoshop'
    | 'Sketch'
    | 'Slider'
    | 'Swatches'
    | 'Twitter'
    | 'Custom';
}

export const ColorInput = (props: ColorInputProps) => {
  const [show, setShow] = useState(false);

  const {
    label,
    source,
    className,
    options,
    picker = 'Chrome',
    resource,
    helperText,
    validate,
  } = props;

  const {
    // eslint-disable-next-line no-unused-vars
    input: { name, value, onChange, onFocus, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput({
    ...props,
    validate,
  });

  const handleOpen = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleChange = ({ hex }: any) => {
    onChange(hex);
  };

  // eslint-disable-next-line import/namespace
  const Picker = ReactColor[`${picker}Picker` as keyof typeof ReactColor];

  return (
    <div>
      <TextField
        name={name}
        onFocus={handleOpen}
        onChange={onChange}
        value={value}
        label={
          <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
        }
        error={!!(touched && error)}
        helperText={helperText}
        className={className}
        variant={'outlined'}
        {...rest}
      />
      {show ? (
        <StyledColorInputPopup>
          {/* eslint-disable-next-line */}
          <StyledColorInputCover onClick={handleClose} />
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <Picker {...options} color={value} onChange={handleChange} />
        </StyledColorInputPopup>
      ) : null}
    </div>
  );
};
