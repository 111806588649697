import React from 'react';
import { Edit, EditProps, required, SimpleForm } from 'react-admin';
import { NoDeleteEditToolbar } from '../../components/NoDeleteEditToolbar';
import StyledGridLayout from '../../components/StyledGridLayout';
import { StyledNumberInput, StyledTextInput } from '../../components/StyledInputs';

const BundleEdit = (props: EditProps) => (
  <Edit {...props} title="Bundles" undoable={false}>
    <SimpleForm toolbar={<NoDeleteEditToolbar />} redirect="show">
      <StyledGridLayout>
        <StyledTextInput source="name" />
        <StyledTextInput source="internalName" label="Internal Name" validate={[required()]} />
        <StyledNumberInput source="price" step={1} />
        <StyledTextInput source="description" />
        <StyledTextInput source="contentPageUrl" label="Content Page URL" />
        <StyledTextInput
          source="contentfulBenefitsComponentId"
          label="Contentful Benefits Component Id"
        />
        <StyledTextInput
          source="contentfulBannerComponentId"
          label="Contentful Banner Component Id"
        />
      </StyledGridLayout>
    </SimpleForm>
  </Edit>
);

export default BundleEdit;
