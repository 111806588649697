import React, { useCallback, useState } from 'react';
import { Button, TextareaAutosize } from '@material-ui/core';
import { Check as ApproveIcon, Close as RejectIcon } from '@material-ui/icons';
import {
  BooleanField,
  DateField,
  SimpleShowLayout,
  TextField,
  useDataProvider,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin';
import { UserActions } from '../../../ra-queries/queries/users';
import { ImageFieldWithPreviewLink } from '../../../components/ImageFieldWithPreviewLink';

const ApproveUserUpdate = (props: any) => {
  const user = props.record;
  const [rejectionReason, setRejectionReason] = useState('');

  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const handleApprove = useCallback(async () => {
    return dataProvider
      .update('Users', {
        id: user.id,
        data: { ...user, action: UserActions.ApproveUserUpdate },
        previousData: user,
      })
      .then(() => {
        redirect(`/Users/${user.id}/show`);
        refresh();
        notify('User profile update has been approved');
      })
      .catch((error) => {
        refresh();
        notify(`User profile update could not be approved: ${error.message}`, 'warning');
      });
  }, [dataProvider, notify, redirect, refresh, user]);

  const handleReject = useCallback(
    async (rejectionNote: string) => {
      return dataProvider
        .update('Users', {
          id: user.id,
          data: { ...user, action: UserActions.RejectUserUpdate, rejectionReason: rejectionNote },
          previousData: user,
        })
        .then(() => {
          redirect(`/Users/${user.id}/show`);
          refresh();
          notify('User profile update has been rejected');
        })
        .catch((error) => {
          refresh();
          notify(`User profile update could not be rejected: ${error.message}`, 'warning');
        });
    },
    [dataProvider, notify, redirect, refresh, user],
  );

  return (
    <>
      <div className="two-columns-layout">
        <div>
          <h5 style={{ marginLeft: '16px' }}>Old data</h5>
          <SimpleShowLayout {...props}>
            <TextField source="details.firstName" label="First Name" />
            <TextField source="details.lastName" label="Last Name" />
            <DateField source="details.dateOfBirth" label="Date Of Birth" />
            <TextField source="details.phoneNumber" label="Phone number" />
            <TextField source="details.addressLine1" label="Address Line 1" />
            <TextField source="details.addressLine2" label="Address Line 2" />
            <TextField source="details.city" label="City" />
            <TextField source="details.postCode" label="Post Code" />
            <TextField source="details.county" label="County" />
            <BooleanField
              source="marketingPermissionsProvided"
              label="Marketing permissions provided"
            />
            <TextField source="details.drivingLicenseNumber" label="Driving License Number" />
            <DateField
              source="details.drivingLicenseValidFrom"
              label="Driving License Valid From"
            />
            <DateField source="details.drivingLicenseValidTo" label="Driving License Valid To" />
            <TextField
              source="details.drivingLicenseCountryOfIssue"
              label="Driving License Country Of Issue"
            />
            <TextField source="details.drivingRecordCheckCode" label="Driving Record Check Code" />
            <ImageFieldWithPreviewLink
              source="details.drivingLicenseFrontImage.url"
              label="Driving License Front"
            />
            <ImageFieldWithPreviewLink
              source="details.drivingLicenseSelfieImage.url"
              label="Driving License Selfie"
            />
            <ImageFieldWithPreviewLink source="details.avatarImage.url" label="Avatar" />
            <ImageFieldWithPreviewLink
              source="details.passportFrontImage.url"
              label="Bank Account Statement or utility bill"
            />
          </SimpleShowLayout>
        </div>
        <div>
          <h5 style={{ marginLeft: '16px' }}>New data</h5>
          <SimpleShowLayout {...props}>
            <TextField source="detailsRequested.firstName" label="First Name" />
            <TextField source="detailsRequested.lastName" label="Last Name" />
            <DateField source="detailsRequested.dateOfBirth" label="Date Of Birth" />
            <TextField source="detailsRequested.phoneNumber" label="Phone number" />
            <TextField source="detailsRequested.addressLine1" label="Address Line 1" />
            <TextField source="detailsRequested.addressLine2" label="Address Line 2" />
            <TextField source="detailsRequested.city" label="City" />
            <TextField source="detailsRequested.postCode" label="Post Code" />
            <TextField source="detailsRequested.county" label="County" />
            <BooleanField
              source="marketingPermissionsProvided"
              label="Marketing permissions provided"
            />
            <TextField
              source="detailsRequested.drivingLicenseNumber"
              label="Driving License Number"
            />
            <DateField
              source="detailsRequested.drivingLicenseValidFrom"
              label="Driving License Valid From"
            />
            <DateField
              source="detailsRequested.drivingLicenseValidTo"
              label="Driving License Valid To"
            />
            <TextField
              source="detailsRequested.drivingLicenseCountryOfIssue"
              label="Driving License Country Of Issue"
            />
            <TextField
              source="detailsRequested.drivingRecordCheckCode"
              label="Driving Record Check Code"
            />
            <ImageFieldWithPreviewLink
              source="detailsRequested.drivingLicenseFrontImage.url"
              label="Driving License Front"
            />
            <ImageFieldWithPreviewLink
              source="detailsRequested.drivingLicenseSelfieImage.url"
              label="Driving License Selfie"
            />
            <ImageFieldWithPreviewLink source="detailsRequested.avatarImage.url" label="Avatar" />
            <ImageFieldWithPreviewLink
              source="detailsRequested.passportFrontImage.url"
              label="Bank Account Statement or utility bill"
            />
          </SimpleShowLayout>
        </div>
      </div>
      <div style={{ margin: '10px 16px 30px 16px' }}>
        <h3>Approve / Reject Profile Update</h3>
        <TextareaAutosize
          rowsMin={3}
          value={rejectionReason}
          placeholder="Rejection reason (applies to rejection only)"
          onChange={(e) => {
            setRejectionReason(e.target.value);
          }}
          style={{ width: '100%' }}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            style={{ background: '#f44336', color: 'white' }}
            startIcon={<RejectIcon />}
            onClick={() => handleReject(rejectionReason)}
          >
            Reject
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<ApproveIcon />}
            onClick={handleApprove}
          >
            Approve
          </Button>
        </div>
      </div>
    </>
  );
};

export default ApproveUserUpdate;
