import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  AllInbox as BundleIcon,
  ArrowDropDown as OpenedDropdownIcon,
  ArrowRight as ClosedDropdownIcon,
  Assessment as ReportIcon,
  Assignment as BookingsIcon,
  AttachMoney as PricingIcon,
  BusinessCenter as BusinessIcon,
  DriveEta as CarIcon,
  EmojiTransportation as CarMakersIcon,
  Group as UsersIcon,
  LocalMall as CodeIcon,
  LocalOffer as TagsIcon,
  Loyalty as SubscriptionIcon,
  PhoneCallback as CallRosterIcon,
  QuestionAnswer as LeasingEnquiriesIcon,
  Receipt as DiscountIcon,
  Redeem as OffersIcon,
  Redeem as LeasingOffersIcon,
  Schedule as StripeTestClockIcon,
  Settings as SettingsIcon,
} from '@material-ui/icons';
import { Box, Collapse, List, Theme, useMediaQuery } from '@material-ui/core';
import { MenuItemLink, MenuProps, ReduxState } from 'react-admin';

interface MenuItem {
  slug: string;
  label: string;
  icon: any;
  name?: string;
  subitems?: MenuItem[];
}

const Menu = ({ onMenuClick, logout: Logout, dense = false }: MenuProps) => {
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const open = useSelector((raState: ReduxState) => raState.admin.ui.sidebarOpen);
  const enableStripeTestClock = process.env.REACT_APP_ENABLE_STRIPE_TEST_CLOCK === 'true';
  const menuItems: MenuItem[] = [
    {
      slug: '/users',
      label: 'Users',
      icon: <UsersIcon />,
    },
    {
      slug: '/tags',
      label: 'Tags',
      icon: <TagsIcon />,
    },
    {
      slug: '/businesses',
      label: 'Businesses',
      icon: <BusinessIcon />,
    },
    {
      slug: '/bundles',
      label: 'Bundles',
      icon: <BundleIcon />,
    },
    {
      slug: '/invites',
      label: 'Invites',
      icon: <DiscountIcon />,
    },
    {
      slug: '/subscriptions',
      label: 'Subscriptions',
      icon: <SubscriptionIcon />,
    },
    {
      slug: '/renewalRequests',
      label: 'Renewal Requests',
      icon: <SubscriptionIcon />,
    },
    ...(() => {
      return enableStripeTestClock
        ? [
            {
              slug: '/stripeTestClock',
              label: 'Stripe Test Clock',
              icon: <StripeTestClockIcon />,
            },
          ]
        : [];
    })(),
    {
      slug: '/codes',
      label: 'Codes',
      icon: <CodeIcon />,
    },
    {
      slug: '/reports',
      label: 'Reports',
      icon: <ReportIcon />,
    },
    {
      slug: '/cars',
      label: 'Cars',
      icon: <CarIcon />,
    },
    {
      slug: '/myEvs',
      label: 'My EVs',
      icon: <CarIcon />,
    },
    {
      slug: '/carmakers',
      label: 'Car Makers',
      icon: <CarMakersIcon />,
    },
    {
      slug: '/bookings',
      label: 'Bookings',
      icon: <BookingsIcon />,
    },
    {
      slug: '/callroster',
      label: 'Call Roster',
      icon: <CallRosterIcon />,
    },
    {
      slug: '/pricings',
      label: 'Pricing',
      icon: <PricingIcon />,
    },
    {
      slug: '/offers',
      label: 'Offers',
      icon: <OffersIcon />,
    },
    {
      slug: '/leasingOffers',
      label: 'Leasing Offers',
      icon: <LeasingOffersIcon />,
    },
    {
      slug: '/leasingEnquiries',
      label: 'Leasing Enquiries',
      icon: <LeasingEnquiriesIcon />,
    },
  ];

  const [openedSubmenu, setOpenedSubmenu] = useState<string>();

  return (
    <Box mt={3}>
      {menuItems.map((item) =>
        item.subitems ? (
          <React.Fragment key={`wrapper-${item.label}`}>
            <MenuItemLink
              to={item.slug}
              key={item.label}
              primaryText={item.label}
              leftIcon={
                openedSubmenu === item.name ? <OpenedDropdownIcon /> : <ClosedDropdownIcon />
              }
              onClick={(e: any) => {
                e.preventDefault();
                setOpenedSubmenu(openedSubmenu === item.name ? undefined : item.name);
              }}
              sidebarIsOpen={open}
              dense={dense}
            />
            <Collapse
              in={openedSubmenu === item.name}
              timeout="auto"
              unmountOnExit
              key={`collapse-${item.label}`}
            >
              <List>
                {item.subitems.map((subitem) => (
                  <MenuItemLink
                    key={subitem.label}
                    to={subitem.slug}
                    primaryText={subitem.label}
                    leftIcon={subitem.icon}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                  />
                ))}
              </List>
            </Collapse>
          </React.Fragment>
        ) : (
          <MenuItemLink
            key={item.label}
            to={item.slug}
            primaryText={item.label}
            leftIcon={item.icon}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
        ),
      )}

      {isXSmall && (
        <>
          <MenuItemLink
            to="/settings"
            primaryText="Settings"
            leftIcon={<SettingsIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          {Logout}
        </>
      )}
    </Box>
  );
};

export default Menu;
