/* eslint-disable no-underscore-dangle */
import { gql } from '@apollo/client';
import { RaFetchType, RaGetResponse, RaListResponse, RaQuery } from '../../interfaces/RaQueries';
import {
  CarMakerType,
  StripeSubscriptionTestType,
  StripeTestClockType,
} from '../../interfaces/types.generated';
import { ApiResponse } from '../../interfaces/ApiQueries';

interface ListResult {
  stripeTestClocks: Array<StripeTestClockType>;
}

export enum StripeTestClockActions {
  AddCustomerToTestClock = 'ADD_CUSTOMER_TO_TEST_CLOCK',
}

const StripeTestClocks = (raFetchType: RaFetchType, params: any): RaQuery => {
  switch (raFetchType) {
    case 'GET_LIST':
      return {
        query: gql`
          query {
            stripeTestClocks {
              id
              name
              customers {
                id
                email
                status
              }
            }
          }
        `,
        variables: {},
        parseResponse: ({
          data,
        }: ApiResponse<ListResult>): RaListResponse<StripeTestClockType> => ({
          data: data.stripeTestClocks,
          total: data.stripeTestClocks.length,
        }),
      };
    case 'GET_ONE':
      return {
        query: gql`
          query ($id: String!) {
            stripeTestClock(id: $id) {
              id
              name
              customers {
                id
                email
                status
              }
            }
          }
        `,
        variables: params,
        parseResponse: ({ data }): RaGetResponse<StripeTestClockType> => ({
          data: data.stripeTestClock,
        }),
      };
    case 'CREATE':
      return {
        query: gql`
          mutation ($name: String!) {
            createSubscriptionTestClock(name: $name) {
              id
              name
            }
          }
        `,
        variables: { name: params.data.name },
        parseResponse: ({ data }): RaGetResponse<StripeTestClockType> => ({
          data: data.createSubscriptionTestClock,
        }),
      };
    case 'DELETE':
      return {
        query: gql`
          mutation ($id: String!) {
            deleteSubscriptionTestClock(id: $id) {
              id
            }
          }
        `,
        variables: { id: params.id },
        parseResponse: ({ data }): RaGetResponse<StripeTestClockType> => ({
          data: data.deleteSubscriptionTestClock,
        }),
      };
    case 'UPDATE':
      if (params.data.action === StripeTestClockActions.AddCustomerToTestClock) {
        return {
          query: gql`
            mutation addCustomerToSubscriptionTestClock(
              $input: StripeSubscriptionTestCreateInput!
            ) {
              addCustomerToSubscriptionTestClock(input: $input) {
                id
              }
            }
          `,
          variables: {
            input: {
              email: params.data.email,
              stripeTestClockId: params.data.stripeTestClockId,
            },
          },
          parseResponse: (): RaGetResponse<StripeSubscriptionTestType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      // STANDARD CAR MAKER UPDATE ACTION
      return {
        query: gql`
          mutation updateCarMaker($visible: Boolean, $name: String, $carMakerId: String!) {
            updateCarMaker(visible: $visible, name: $name, carMakerId: $carMakerId) {
              id
            }
          }
        `,
        variables: {
          visible: params.data.visible,
          name: params.data.name,
          carMakerId: params.id,
        },
        parseResponse: ({ data }): RaGetResponse<CarMakerType> => ({
          data: data.updateCarMaker,
        }),
      };
    default:
      throw new Error(`Unknown fetch type: ${raFetchType}`);
  }
};

export default StripeTestClocks;
