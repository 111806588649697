import { gql } from '@apollo/client';
import {
  RaFetchType,
  RaGetResponse,
  RaListParams,
  RaListResponse,
  RaQuery,
} from '../../interfaces/RaQueries';
import { MyEvType } from '../../interfaces/types.generated';
import { ApiResponse } from '../../interfaces/ApiQueries';
import getListParams from '../../utils/getListParams';

interface ListResult {
  myEvs: {
    myEvs: Array<MyEvType>;
    overallCount: number;
  };
}

const MyEvs = (raFetchType: RaFetchType, params: any): RaQuery => {
  switch (raFetchType) {
    case 'GET_LIST':
      return {
        query: gql`
          query ($limit: Int, $offset: Int, $searchPhrase: String) {
            myEvs(params: { limit: $limit, offset: $offset, searchPhrase: $searchPhrase }) {
              myEvs {
                id
                registration
                user {
                  id
                  details {
                    firstName
                    lastName
                  }
                }
                taxAndMot {
                  motExpiryDate
                }
                reminders {
                  tax {
                    nextReminderOn
                  }
                  mot {
                    nextReminderOn
                  }
                }
              }
              overallCount
            }
          }
        `,
        variables: getListParams(params as RaListParams),
        parseResponse: ({ data }: ApiResponse<ListResult>): RaListResponse<MyEvType> => ({
          data: data.myEvs.myEvs.map((item) => item),
          total: data.myEvs.overallCount,
        }),
      };
    case 'GET_ONE':
      return {
        query: gql`
          query ($id: String!) {
            ev(id: $id) {
              id
              registration
              user {
                id
                details {
                  firstName
                  lastName
                }
              }
              taxAndMot {
                motExpiryDate
              }
              reminders {
                tax {
                  nextReminderOn
                }
                mot {
                  nextReminderOn
                }
              }
            }
          }
        `,
        variables: { id: params.id },
        parseResponse: ({ data }): RaGetResponse<MyEvType> => ({
          data: {
            ...data.ev,
          },
        }),
      };
    default:
      throw new Error(`Unknown fetch type: ${raFetchType}`);
  }
};

export default MyEvs;
