import { RaListParams } from '../interfaces/RaQueries';
import { ApiListParams } from '../interfaces/ApiQueries';
import { capitalize } from './stringHelpers';

interface CarsListParams extends ApiListParams {
  withPendingDetailsRequestedOnly?: boolean;
  withDrafts?: boolean;
  sort?: string;
}

const getCarsListParams = ({ pagination, sort, filter }: RaListParams): CarsListParams => {
  const { perPage, page } = pagination;
  const limit = perPage;
  const offset = (page - 1) * (perPage || 1);
  const sortField = sort.field === 'details.registration' ? 'Registration' : 'CreatedAt';

  const sortValue = `${sortField}${capitalize(sort.order)}`;

  return {
    limit,
    offset,
    withPendingDetailsRequestedOnly: filter.pendingApproval,
    withDrafts: filter.withDrafts,
    sort: String(sortField) !== 'id' ? sortValue : undefined,
    searchPhrase: filter.q || '',
  };
};

export default getCarsListParams;
