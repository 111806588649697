import React from 'react';
import {
  BooleanInput,
  Edit,
  EditProps,
  FileField,
  FileInput,
  required,
  SimpleForm,
} from 'react-admin';
import { NoDeleteEditToolbar } from '../../components/NoDeleteEditToolbar';
import StyledGridLayout from '../../components/StyledGridLayout';
import { StyledDateInput, StyledTextInput } from '../../components/StyledInputs';

const UserEdit = (props: EditProps) => (
  <Edit {...props} title="Users" undoable={false}>
    <SimpleForm toolbar={<NoDeleteEditToolbar />} redirect="show">
      <StyledGridLayout>
        <StyledTextInput source="email" validate={required()} disabled />
        <StyledDateInput source="createdAt" disabled />
        <BooleanInput source="validAsCarOwner" />
        <BooleanInput source="validAsCarRenter" />
        <StyledTextInput source="details.firstName" label="First Name" />
        <StyledTextInput source="details.lastName" label="Last Name" />
        <StyledDateInput source="details.dateOfBirth" label="Date Of Birth" />
        <StyledTextInput source="details.phoneNumber" label="Phone number" />
        <StyledTextInput source="details.addressLine1" label="Address Line 1" />
        <StyledTextInput source="details.addressLine2" label="Address Line 2" />
        <StyledTextInput source="details.city" label="City" />
        <StyledTextInput source="details.postCode" label="Post Code" />
        <StyledTextInput source="details.county" label="County" />
        <h5>Communication Preferences</h5>
        <BooleanInput
          source="communicationPreferences.emailAllowed"
          label="Marketing permissions - Email Allowed"
        />
        <BooleanInput
          source="communicationPreferences.phoneAllowed"
          label="Marketing permissions - Phone Allowed"
        />
        <BooleanInput
          source="communicationPreferences.smsAllowed"
          label="Marketing permissions - SMS Allowed"
        />

        <h5>User Profile Photo</h5>
        <FileInput source="avatarImage" accept="image/*">
          <FileField source="src" title="avatarImageFile" />
        </FileInput>

        <h5>Driving data</h5>
        <StyledTextInput source="details.drivingLicenseNumber" label="Driving License Number" />
        <StyledDateInput
          source="details.drivingLicenseValidFrom"
          label="Driving License Valid From"
        />
        <StyledDateInput source="details.drivingLicenseValidTo" label="Driving License Valid To" />
        <StyledTextInput
          source="details.drivingLicenseCountryOfIssue"
          label="Driving License Country Of Issue"
        />
        <StyledTextInput
          source="details.drivingRecordCheckCode"
          label="Driving Record Check Code"
        />
        <StyledTextInput
          source="internalDetails.restrictionsOnLicense"
          label="Offenses and Restrictions on License"
        />
        <StyledTextInput
          source="internalDetails.numberOfPoints"
          label="Number Of Points on License"
        />
        <BooleanInput
          source="internalDetails.isThreeWayVerified"
          label="Three way match verified"
        />
        <FileInput source="drivingLicenseFrontImage" accept="image/*">
          <FileField source="src" title="drivingLicenseFrontImageFile" />
        </FileInput>
        <FileInput source="drivingLicenseSelfieImage" accept="image/*">
          <FileField source="src" title="drivingLicenseSelfieImageFile" />
        </FileInput>
        <FileInput
          source="passportFrontImage"
          accept="image/*"
          label="Bank Account Statement or utility bill"
        >
          <FileField source="src" title="passportFrontImageFile" />
        </FileInput>
      </StyledGridLayout>
    </SimpleForm>
  </Edit>
);

export default UserEdit;
