import styled from 'styled-components';

const StyledGridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  width: 100% !important;

  h5 {
    width: 100%;
    grid-column: 1 / span 2;
  }
`;

export default StyledGridLayout;
