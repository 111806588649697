import React, { useCallback, useState } from 'react';
import { Button } from '@material-ui/core';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { UserActions } from '../../../ra-queries/queries/users';
import ConfirmDialog from '../../../components/ConfirmDialog';

const UserDeleteButton = (props: any) => {
  const notify = useNotify();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const handleUserDelete = useCallback(async () => {
    const action = UserActions.DeleteUser;
    const message = `User has been deleted`;

    return dataProvider
      .update('Users', {
        id: props.record.id,
        data: { ...props.record, action },
        previousData: props.record,
      })
      .then(() => {
        refresh();
        notify(message);
      })
      .catch((error) => {
        refresh();
        notify(error.message, 'warning');
      });
  }, [dataProvider, notify, props.record, refresh]);

  return (
    <div>
      <Button variant="contained" color="primary" onClick={() => setConfirmOpen(true)}>
        Delete User
      </Button>
      <ConfirmDialog
        title="Delete User?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleUserDelete}
      >
        Do you want to permanently delete this customer and all associated data in the system?
      </ConfirmDialog>
    </div>
  );
};

export default UserDeleteButton;
