import React, { useCallback, useEffect, useState } from 'react';
import { Button, TextareaAutosize } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { BookingActions } from '../../../ra-queries/queries/bookings';

const BookingNote = (props: any) => {
  const [isEditing, setIsEditing] = useState(false);
  const [note, setNote] = useState('');
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  useEffect(() => {
    setNote(props.record.internalNote);
  }, [props.record.internalNote]);

  const handleinternalNoteSave = useCallback(
    async (internalNote: string) => {
      return dataProvider
        .update('Bookings', {
          id: props.record.id,
          data: { ...props.record, action: BookingActions.UpdateBookingNote, internalNote },
          previousData: props.record,
        })
        .then(() => {
          refresh();
          notify('Internal note has been updated');
        })
        .catch((error) => {
          refresh();
          notify(`Internal note could not be updated: ${error.message}`, 'warning');
        });
    },
    [dataProvider, notify, props.record, refresh],
  );

  return (
    <>
      {isEditing ? (
        <div>
          <TextareaAutosize
            rowsMin={3}
            value={note}
            placeholder="Internal note"
            onChange={(e) => {
              setNote(e.target.value);
            }}
            style={{ width: '100%', marginTop: '20px' }}
          />
          <div style={{ margin: '20px 0', display: 'flex' }}>
            <div style={{ marginRight: '20px' }}>
              <Button onClick={() => setIsEditing(false)}>Cancel</Button>
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleinternalNoteSave(note)}
            >
              Save internal note
            </Button>
          </div>
        </div>
      ) : (
        <div style={{ marginTop: '20px' }}>
          {props.record.internalNote || '-'}
          <div style={{ margin: '20px 0', display: 'flex' }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<EditIcon />}
              onClick={() => setIsEditing(true)}
            >
              Update internal note
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default BookingNote;
