import { RaListParams } from '../interfaces/RaQueries';
import { ApiListParams } from '../interfaces/ApiQueries';
import { OfferQueryOrderEnum } from '../interfaces/types.generated';

const getOffersListParams = ({ pagination, sort, filter }: RaListParams): ApiListParams => {
  const { perPage, page } = pagination;
  const limit = perPage;
  const offset = (page - 1) * (perPage || 1);

  let order: string | undefined;
  switch (`${sort.field}${sort.order}`) {
    case 'createdAtASC':
      order = OfferQueryOrderEnum.CreatedAtAsc;
      break;
    case 'createdAtDESC':
      order = OfferQueryOrderEnum.CreatedAtDesc;
      break;
    default:
      order = OfferQueryOrderEnum.CreatedAtDesc;
      break;
  }

  return {
    limit,
    offset,
    order,
    searchPhrase: filter.q,
  };
};

export default getOffersListParams;
