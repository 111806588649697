import React from 'react';
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  ListProps,
  TextField,
  TextInput,
} from 'react-admin';

const LeasingOffersFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput label="Leasing Offer name" source="q" alwaysOn variant="outlined" />
      <BooleanInput
        label="Only show visible to public"
        source="onlyShowVisibleToPublic"
        alwaysOn
        variant="outlined"
      />
    </Filter>
  );
};

const LeasingOfferList = (props: ListProps) => (
  <List
    {...props}
    pagination={false}
    bulkActionButtons={false}
    empty={false}
    filters={<LeasingOffersFilter />}
  >
    <Datagrid rowClick="show">
      <TextField source="id" label="Id" />
      <TextField source="title" label="Title" />
      <TextField source="subTitle" label="Subtitle" />
      <BooleanField source="visibleToPublic" label="Visible to Public" />
      <DateField source="updatedAt" label="Updated At" />
      <EditButton />
    </Datagrid>
  </List>
);

export default LeasingOfferList;
