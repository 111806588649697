import React from 'react';
import {
  DateField,
  FunctionField,
  NumberField,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  UrlField,
  useShowController,
} from 'react-admin';
import BookingNote from './elements/BookingNote';
import BookingStatusChange from './elements/BookingStatusChange';
import BookingOperations from './elements/BookingOperations';

const BookingShow = (props: ShowProps) => {
  const { record } = useShowController(props);

  if (!record) return null;

  return (
    <Show {...props} title="Bookings">
      <TabbedShowLayout>
        <Tab label="summary" contentClassName="two-columns-layout">
          <TextField source="id" />
          <TextField source="displayId" label={'Booking No.'} />
          <TextField source="status" />
          <FunctionField
            label="Owner"
            source="owner"
            render={(booking: any) => {
              const car = booking.car;
              const isBusiness = Boolean(car.business);
              const isPrivate = Boolean(car.user && car.user.details);
              if (isBusiness) {
                return car.business.name;
              }
              if (isPrivate) {
                return `${car.user.details?.firstName} ${car.user.details?.lastName}`;
              }
              return '-';
            }}
          />
          <FunctionField
            label="Renter"
            source="renter"
            render={(booking: any) => {
              return `${booking.user.details?.firstName} ${booking.user.details?.lastName}`;
            }}
          />
          <TextField source="car.details.registration" label="Registration Plate" />
          <DateField source="createdAt" showTime />
          <DateField source="timeStart" label="Start" showTime />
          <DateField source="timeEnd" label="End" showTime />
          <NumberField
            source="rentalFeeSum"
            locales="en-GB"
            options={{ style: 'currency', currency: 'GBP' }}
            label="Total"
          />
          <NumberField
            source="rentalFeeOwner"
            locales="en-GB"
            options={{ style: 'currency', currency: 'GBP' }}
            label="Host earnings"
          />
          <NumberField
            source="rentalFeeZoom"
            locales="en-GB"
            options={{ style: 'currency', currency: 'GBP' }}
            label="Zoom EV earnings"
          />
          <NumberField
            source="additionalFee"
            locales="en-GB"
            options={{ style: 'currency', currency: 'GBP' }}
            label="Operational cost recovered"
          />
          <TextField source="usedPromoCode" label="Used Promo Code" />
          <TextField source="incidentDescription" />
          <TextField source="acceptanceNote" />
          <TextField source="cancellationNote" />
          <TextField source="rejectionNote" />
          <TextField source="paymentStatus" sortable={false} />
          <TextField source="stripeSetupIntentId" />
          <UrlField source="stripeSetupIntentUrl" label="Stripe setup intent URL" />
          <TextField source="stripePaymentIntentId" />
          <UrlField
            source="stripePaymentIntentUrl"
            label="Stripe payment intent URL (charged to renter)"
          />
          <TextField source="stripePaymentTransferId" />
          <UrlField
            source="stripePaymentTransferUrl"
            label="Stripe payment Transfer URL (transfer to host)"
          />
          <BookingOperations />
        </Tab>
        <Tab label="change status" path="change-status">
          <BookingStatusChange {...props} />
        </Tab>
        <Tab label="internal note" path="internal-note">
          <BookingNote />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
export default BookingShow;
