import React from 'react';
import { ImageField, ImageFieldProps } from 'react-admin';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    marginBottom: '12px',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '12px',
    marginBottom: '10px',
    display: 'inline-block',
  },
});

export const ImageFieldWithPreviewLink = (props: ImageFieldProps) => {
  const classes = useStyles();
  const sourceValue = get(props.record, props.source || '');
  return (
    <div className={classes.root}>
      <span className={classes.label}>{props.label}</span>
      <a href={sourceValue} target="_blank" rel="noreferrer">
        <ImageField {...props} />
      </a>
    </div>
  );
};
