import React from 'react';
import {
  BooleanField,
  Datagrid,
  EditButton,
  FunctionField,
  List,
  ListProps,
  TextField,
} from 'react-admin';

const CarmakersList = (props: ListProps) => (
  <List {...props} bulkActionButtons={false} pagination={false}>
    <Datagrid rowClick="show">
      <TextField source="name" sortable={false} />
      <FunctionField
        label="Models"
        render={(record: any) => (record.models ? record.models.length : 0)}
      />
      <BooleanField source="visible" sortable={false} />
      <EditButton />
    </Datagrid>
  </List>
);

export default CarmakersList;
