const getLeasingOffersUpdateParams = (data: any) => {
  const {
    fromPrice,
    title,
    subTitle,
    tagline,
    initialPayment,
    contractLength,
    miles,
    visibleToPublic,
    vatIncluded,
  } = data.data;

  const finalInput = {
    fromPrice,
    title,
    subTitle,
    tagline,
    initialPayment,
    contractLength,
    miles,
    visibleToPublic,
    vatIncluded,
  };

  return {
    id: data.id,
    input: finalInput,
  };
};

export default getLeasingOffersUpdateParams;
