import { RaListParams } from '../interfaces/RaQueries';
import { ApiListParams } from '../interfaces/ApiQueries';

const getListParams = ({ pagination, filter }: RaListParams): ApiListParams => {
  const { perPage, page } = pagination;
  const limit = perPage;
  const offset = (page - 1) * (perPage || 1);

  return { limit, offset, searchPhrase: filter.q };
};

export default getListParams;
