import React, { ChangeEvent, useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { BooleanInput, Edit, EditProps, SimpleForm, useEditController } from 'react-admin';
import StyledGridLayout from '../../components/StyledGridLayout';
import {
  StyledNumberInput,
  StyledSelectInput,
  StyledTextInput,
} from '../../components/StyledInputs';

const GET_CARMAKERS = gql`
  query carMakers {
    carMakers {
      id
      name
      models {
        id
        name
      }
    }
  }
`;

const CarEdit = (props: EditProps) => {
  const { data: carmakersData } = useQuery(GET_CARMAKERS);
  const { record } = useEditController(props);

  const [selectedMakerId, setSelectedMakerId] = useState(record?.details?.maker?.id);
  const [availableModels, setAvailableModels] = useState([]);

  useEffect(() => {
    if (!carmakersData || !selectedMakerId) return;
    const selectedMaker = carmakersData.carMakers.find(
      (carMaker: any) => carMaker.id === selectedMakerId,
    );
    if (selectedMaker) {
      setAvailableModels(selectedMaker.models);
    }
  }, [carmakersData, selectedMakerId]);

  if (!carmakersData) return null;

  return (
    <>
      <Edit {...props} title="Cars" undoable={false}>
        <SimpleForm redirect="show">
          <StyledGridLayout>
            <StyledTextInput source="id" disabled />
            <BooleanInput source="visible" />
            <StyledTextInput source="details.registration" label="Registration Plate no" />
            <StyledTextInput source="details.vin" label="VIN" />
            <StyledSelectInput
              source="details.type"
              label="Type"
              choices={[
                { id: 'Car', name: 'Car' },
                { id: 'Van', name: 'Van' },
              ]}
            />
            <StyledSelectInput
              source="details.motor"
              label="Motor"
              choices={[
                { id: 'Electric', name: 'Electric' },
                { id: 'Hybrid', name: 'Hybrid' },
              ]}
            />
            <StyledSelectInput
              source="details.maker.id"
              label="Maker"
              choices={carmakersData.carMakers.map((carMaker: any) => {
                return { id: carMaker.id, name: carMaker.name };
              })}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setSelectedMakerId(e.target.value)}
            />
            <StyledSelectInput source="details.model.id" label="Model" choices={availableModels} />
            <StyledNumberInput source="details.year" label="Year" />
            <StyledSelectInput
              source="details.value"
              label="Value"
              choices={[
                { id: 'Between10And20', name: 'Between 10 and 20' },
                { id: 'Between20And30', name: 'Between 20 and 30' },
                { id: 'Between30And40', name: 'Between 30 and 40' },
                { id: 'Between40And60', name: 'Between 40 and 60' },
                { id: 'Between60And75', name: 'Between 60 and 75' },
                { id: 'MoreThan75', name: 'More than 75' },
              ]}
            />
            <StyledNumberInput source="details.mileage" label="Mileage" />
            <StyledNumberInput source="details.doors" label="Doors" />
            <StyledNumberInput source="details.seats" label="Seats" />
            <StyledSelectInput
              source="details.transmission"
              label="Transmission"
              choices={[
                { id: 'Automatic', name: 'Automatic' },
                { id: 'Manual', name: 'Manual' },
              ]}
            />
            <StyledTextInput source="details.color" label="Colour" />
            <StyledSelectInput
              source="details.range"
              label="Range"
              choices={[
                { id: 'UP_TO_200', name: 'Up to 200' },
                { id: 'UP_TO_300', name: 'Up to 300' },
                { id: 'UP_TO_400', name: 'Up to 400' },
              ]}
            />
            <StyledTextInput source="pricePerHour" />
            <StyledTextInput source="pricePerDay" />
            <StyledTextInput source="pricePerWeek" />
            <BooleanInput source="flags.isTaxed" label="Vehicle Taxed" />
            <BooleanInput source="flags.isMotValid" label="Vehicle MOT valid" />
            <BooleanInput source="flags.isInsuredByOwner" label="Vehicle insured by the owner" />
            <StyledTextInput label="Description" source="details.description" rows={4} multiline />
            <StyledTextInput
              label="Existing Damage Description"
              source="details.existingDamageDescription"
              rows={4}
              multiline
            />
          </StyledGridLayout>
        </SimpleForm>
      </Edit>
    </>
  );
};
export default CarEdit;
