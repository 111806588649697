const getOfferUpdateParams = (data: any) => {
  const {
    contentfulEntityId,
    offerName,
    offerDetailSlug,
    offerType,
    heroContent,
    secondaryContent,
    tileContent,
  } = data.data;

  const finalInput = {
    offerName,
    offerType,
    offerDetailSlug,
    contentfulEntityId,
    heroContent: heroContent
      ? {
          title: heroContent?.title,
          content: heroContent?.content,
          actionTitle: heroContent?.offerAction.title,
          actionLink: heroContent?.offerAction.link,
        }
      : null,
    secondaryContent: secondaryContent
      ? {
          title: secondaryContent?.title,
          content: secondaryContent?.content,
          actionTitle: secondaryContent?.offerAction.title,
          actionLink: secondaryContent?.offerAction.link,
          cardColor: secondaryContent?.cardColor,
        }
      : null,
    tileContent: tileContent
      ? {
          title: tileContent?.title,
          content: tileContent?.content,
          actionTitle: tileContent?.offerAction.title,
          actionLink: tileContent?.offerAction.link,
        }
      : null,
  };

  return {
    id: data.id,
    input: finalInput,
  };
};

export default getOfferUpdateParams;
