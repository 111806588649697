import React from 'react';
import {
  BooleanField,
  EditProps,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useShowController,
} from 'react-admin';
import { ImageFieldWithPreviewLink } from '../../components/ImageFieldWithPreviewLink';
import BusinessReviews from './elements/BusinessReviews';
import BusinessUsers from './elements/BusinessUsers';
import { ColorField } from '../../components/ColorField';
import BusinessActionsList from './elements/BusinessActionsList';

const BusinessShow = (props: EditProps) => {
  const { record } = useShowController(props);

  if (!record) return null;

  return (
    <Show {...props} title="Businesses">
      <TabbedShowLayout>
        <Tab label="summary" contentClassName="two-columns-layout">
          <TextField source="type" />
          <TextField source="displayName" />
          <TextField source="name" label="Company name" />
          <TextField source="brandName" />
          <TextField source="companyNumber" />
          <TextField source="locationName" />
          <TextField source="emailAddress" />
          <TextField source="phoneNumber" />
          <TextField source="address" />
          <TextField source="tradingHours" />
          <ImageFieldWithPreviewLink source="logoImage.url" label="Logo" />
          <BooleanField source="isFleetInsuranceInPlace" label="Is Business insurance in place" />
          <BooleanField
            source="proposalNumberRequired"
            label="Proposal Number Required for Invites"
          />
          <TextField source="apiKey" />
        </Tab>
        <Tab label="Reviews" path="reviews">
          <BusinessReviews business={record} />
        </Tab>
        <Tab label="Users" path="users">
          <BusinessUsers users={record} />
        </Tab>
        <Tab label="Actions" path="actions">
          <BusinessActionsList business={record} />
        </Tab>
        <Tab label="Branding" path="branding" contentClassName="two-columns-layout">
          <ColorField source="anchorColor" label="Anchor Color" />
          <BooleanField source="brandedEmails" label="Branded emails" />
          <TextField source="dashboardTagline" label="Dashboard Tagline" />
          <ImageFieldWithPreviewLink
            source="dashboardBackgroundImage.url"
            label="Background Image"
          />
          <BooleanField
            source="tintDashboardBackground"
            label="Tint dashboard background with Anchor color"
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
export default BusinessShow;
