import React from 'react';
import { useRecordContext } from 'ra-core';
import get from 'lodash/get';

interface ColorFieldProps {
  source: string;
  // eslint-disable-next-line react/no-unused-prop-types
  label?: string;
  className?: string;
}

export const ColorField = ({ source, className, label }: ColorFieldProps) => {
  const record = useRecordContext();

  return (
    <>
      <span
        className={'MuiFormLabel-root'}
        style={{
          fontSize: '12px',
        }}
      >
        {label}
      </span>
      <div style={{ display: 'flex', paddingTop: '10px', paddingBottom: '10px' }}>
        <div
          style={{
            width: '20px',
            height: '20px',
            background: get(record, source),
            marginRight: '5px',
          }}
        />
        <span className={className}>{get(record, source)}</span>
      </div>
    </>
  );
};
