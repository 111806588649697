import { RaListParams } from '../interfaces/RaQueries';
import { ApiListParams } from '../interfaces/ApiQueries';
import { UsersOrderEnum } from '../interfaces/types.generated';

interface UsersListParams extends ApiListParams {
  onlyWithRequestedChanges?: boolean;
  validAsCarOwner?: boolean;
  validAsCarRenter?: boolean;
}

const getUsersListParams = ({ pagination, sort, filter }: RaListParams): UsersListParams => {
  const { perPage, page } = pagination;
  const limit = perPage;
  const offset = (page - 1) * (perPage || 1);

  let order: string | undefined;
  switch (`${sort.field}${sort.order}`) {
    case 'createdAtASC':
      order = UsersOrderEnum.CreatedAtAsc;
      break;
    case 'createdAtDESC':
      order = UsersOrderEnum.CreatedAtDesc;
      break;
    case 'emailASC':
      order = UsersOrderEnum.EmailAsc;
      break;
    case 'emailDESC':
      order = UsersOrderEnum.EmailDesc;
      break;
    default:
      order = undefined;
      break;
  }

  return {
    limit,
    offset,
    order,
    searchPhrase: filter.q,
    onlyWithRequestedChanges: filter.pendingApproval,
    validAsCarOwner: filter.validAsCarOwner,
    validAsCarRenter: filter.validAsCarRenter,
  };
};

export default getUsersListParams;
