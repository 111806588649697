import { gql } from '@apollo/client';
import _omit from 'lodash.omit';
import {
  RaFetchType,
  RaGetResponse,
  RaListResponse,
  RaQuery,
  RaUpdateParams,
} from '../../interfaces/RaQueries';
import { BusinessType } from '../../interfaces/types.generated';
import { ApiResponse } from '../../interfaces/ApiQueries';

interface ListResult {
  businesses: Array<BusinessType>;
  businessesCount: number;
}

export enum BusinessActions {
  AddUserToBusiness = 'ADD_USER_TO_BUSINESS',
  RemoveUserFromBusiness = 'REMOVE_USER_FROM_BUSINESS',
  RemoveBusinessReview = 'REMOVE_BUSINESS_REVIEW',
  GenerateApiKey = 'GENERATE_API_KEY',
}

const Businesses = (raFetchType: RaFetchType, params: any): RaQuery => {
  switch (raFetchType) {
    case 'GET_LIST':
      return {
        query: gql`
          query {
            businesses {
              id
              displayName
              name
              type
              brandName
              locationName
            }
          }
        `,
        variables: {},
        parseResponse: ({ data }: ApiResponse<ListResult>): RaListResponse<BusinessType> => ({
          data: data.businesses,
          total: data.businesses.length,
        }),
      };
    case 'GET_ONE':
      return {
        query: gql`
          query ($id: String!) {
            business(id: $id) {
              id
              name
              type
              brandName
              locationName
              displayName
              emailAddress
              phoneNumber
              address
              apiKey
              tradingHours
              companyNumber
              anchorColor
              brandedEmails
              dashboardTagline
              users {
                id
                email
                businessUserRole
                details {
                  firstName
                  lastName
                }
              }
              isFleetInsuranceInPlace
              proposalNumberRequired
              tintDashboardBackground
              logoImage {
                url
              }
              dashboardBackgroundImage {
                url
              }
              reviews {
                id
                score
                note
                reviewType
                resourceId
                user {
                  id
                  details {
                    firstName
                    lastName
                  }
                }
                business {
                  id
                  name
                }
              }
            }
          }
        `,
        variables: params,
        parseResponse: ({ data }): RaGetResponse<BusinessType> => ({
          data: data.business,
        }),
      };
    case 'CREATE':
      return {
        query: gql`
          mutation ($input: BusinessCreateInput!) {
            createBusiness(input: $input) {
              id
              type
            }
          }
        `,
        variables: { input: params.data },
        parseResponse: ({ data }): RaGetResponse<BusinessType> => ({
          data: data.createBusiness,
        }),
      };
    case 'DELETE':
      return {
        query: gql`
          mutation ($businessId: String!) {
            deleteBusiness(businessId: $businessId)
          }
        `,
        variables: { businessId: params.id },
        parseResponse: ({ data }): RaGetResponse<BusinessType> => ({
          data: data.deleteBusiness,
        }),
      };
    case 'UPDATE':
      if (params.data.action === BusinessActions.GenerateApiKey) {
        return {
          query: gql`
            mutation regenerateBusinessApiKey($businessId: String!) {
              regenerateBusinessApiKey(businessId: $businessId)
            }
          `,
          variables: {
            businessId: params.id,
          },
          parseResponse: (): RaGetResponse<string> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === BusinessActions.AddUserToBusiness) {
        return {
          query: gql`
            mutation attachUserToBusiness(
              $userId: String!
              $businessId: String!
              $businessRole: BusinessUserRoleEnum!
            ) {
              attachUserToBusiness(
                userId: $userId
                businessId: $businessId
                businessRole: $businessRole
              ) {
                id
              }
            }
          `,
          variables: {
            businessId: params.id,
            userId: params.data.userId,
            businessRole: params.data.businessRole,
          },
          parseResponse: (): RaGetResponse<BusinessType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === BusinessActions.RemoveUserFromBusiness) {
        return {
          query: gql`
            mutation detachUserFromBusiness($userId: String!) {
              detachUserFromBusiness(userId: $userId) {
                id
              }
            }
          `,
          variables: {
            userId: params.data.userId,
          },
          parseResponse: (): RaGetResponse<BusinessType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === BusinessActions.RemoveBusinessReview) {
        return {
          query: gql`
            mutation deleteReview($reviewId: String!) {
              deleteReview(reviewId: $reviewId)
            }
          `,
          variables: { reviewId: params.data.reviewId },
          parseResponse: (): RaGetResponse<BusinessType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      // STANDARD BUSINESS UPDATE ACTION
      return {
        query: gql`
          mutation ($updateInput: BusinessUpdateInput!, $id: String!) {
            updateBusiness(updateInput: $updateInput, businessId: $id) {
              id
            }
          }
        `,
        variables: {
          updateInput: _omit(params.data, [
            'id',
            'logoImage',
            'dashboardBackground',
            'dashboardBackgroundImage',
            'logo',
            'users',
            'reviews',
            'apiKey',
          ]),
          id: (params as RaUpdateParams<BusinessType>).id,
        },
        parseResponse: ({ data }): RaGetResponse<BusinessType> => ({
          data: data.updateBusiness,
        }),
      };
    default:
      throw new Error(`Unknown fetch type: ${raFetchType}`);
  }
};

export default Businesses;
