import React from 'react';
import {
  BooleanInput,
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  Link,
  List,
  ListProps,
  TextField,
  TextInput,
} from 'react-admin';

const RenewalRequestFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput label="Name/email/phone" source="q" alwaysOn variant="outlined" />
      <BooleanInput label="Due to renewal only" alwaysOn source="onlyDueToRenewal" />
    </Filter>
  );
};

const RenewalRequestList = (props: ListProps) => {
  return (
    <List {...props} bulkActionButtons={false} empty={false} filters={<RenewalRequestFilter />}>
      <Datagrid rowClick="show">
        <TextField source="status" label="Status" sortable={false} />
        <TextField source="subscription.bundleType.name" label="Bundle" sortable={false} />
        <FunctionField
          label="Subscriber"
          sortable={false}
          render={(record: any) => {
            return `${record.user.details?.firstName} ${record.user.details?.lastName}`;
          }}
        />
        <TextField source="subscription.resellerBusiness.name" label="Reseller" sortable={false} />
        <FunctionField
          label="Requested Call"
          sortable={false}
          render={(record: any) => {
            return record.user.callRequest ? (
              <Link to={`/CallRoster/${record.user.callRequest.id}/show`}>Yes</Link>
            ) : (
              'No'
            );
          }}
        />
        <TextField
          source="subscription.yearOfSubscription"
          sortable={false}
          label="Year of Subscription"
        />
        <DateField source="createdAt" sortable showTime />
        <DateField
          source="subscription.currentPeriodEnd"
          label="Renewal start date"
          sortable={false}
          showTime
        />
      </Datagrid>
    </List>
  );
};

export default RenewalRequestList;
