import React from 'react';
import {
  BooleanField,
  DateField,
  EditProps,
  Link,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useShowController,
} from 'react-admin';
import { ImageFieldWithPreviewLink } from '../../components/ImageFieldWithPreviewLink';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { LeasingEnquiryType } from '../../interfaces/types.generated';

const LeasingOfferShow = (props: EditProps) => {
  const { record } = useShowController(props);
  if (!record) return null;

  return (
    <Show {...props} title="Leasing Offers">
      <TabbedShowLayout>
        <Tab label="summary" contentClassName="two-columns-layout">
          <TextField source="id" label="Id" />
          <TextField source="title" label="Title" />
          <TextField source="subTitle" label="Subtitle" />
          <TextField source="fromPrice" label="From price" />
          <TextField source="tagline" label="Tagline" />
          <TextField source="initialPayment" label="Initial Payment" />
          <TextField source="contractLength" label="Contract Length" />
          <TextField source="miles" label="Miles" />
          <BooleanField source="visibleToPublic" label="Is visible to public" />
          <BooleanField source="vatIncluded" label="VAT Included" />
          <DateField source="updatedAt" label="Updated At" />
          <ImageFieldWithPreviewLink source="offerImage.url" label="Offer Image" />
        </Tab>
        <Tab label="Enquiries">
          {record?.enquiries?.length > 0 && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Contact Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone Number</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {record?.enquiries.map((enquiry: LeasingEnquiryType) => (
                    <TableRow key={enquiry.id}>
                      <TableCell>{enquiry.contactName}</TableCell>
                      <TableCell>{enquiry.email}</TableCell>
                      <TableCell>{enquiry.phoneNumber}</TableCell>
                      <TableCell>
                        <Link to={`/LeasingEnquiries/${enquiry.id}/show`}>Show Details</Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {record?.enquiries?.length === 0 && (
            <p>No Enquiries have been made for this leasing offer yet.</p>
          )}
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
export default LeasingOfferShow;
