/* eslint-disable react/jsx-wrap-multilines */
import React, { useCallback, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { StripeTestClockActions } from '../../../ra-queries/queries/stripeTestClocks';

const useStyles = makeStyles({
  root: {
    marginBottom: '20px',
  },
});

const StripeTestClock = (props: any) => {
  const testClock = props.record;
  const customers = testClock.customers;
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const classes = useStyles();

  const [modalAddOpen, setModalAddOpen] = useState(false);
  const [newCustomerEmail, setNewCustomerEmail] = useState('');

  const handleModalAddClose = () => {
    setModalAddOpen(false);
  };

  const handleCustomerAdd = useCallback(
    async (customerEmail: string) => {
      return dataProvider
        .update('StripeTestClock', {
          id: testClock.id,
          data: {
            ...testClock,
            action: StripeTestClockActions.AddCustomerToTestClock,
            email: customerEmail,
            stripeTestClockId: testClock.id,
          },
          previousData: testClock,
        })
        .then(() => {
          refresh();
          notify('Customer has been added to the test clock');
          handleModalAddClose();
        })
        .catch((error) => {
          refresh();
          notify(`Customer has not been added to the test clock: ${error.message}`, 'warning');
        });
    },
    [dataProvider, testClock, refresh, notify],
  );

  const handleAddAction = () => {
    setModalAddOpen(true);
    setNewCustomerEmail('');
  };

  return (
    <>
      <div style={{ margin: '20px 0 30px 0' }}>
        <Button variant="contained" color="primary" onClick={handleAddAction}>
          Add Customer
        </Button>
      </div>
      {customers.length > 0 ? (
        customers.map((customer: any) => (
          <Card key={customer.id} className={classes.root}>
            <CardContent>
              <Typography variant="h6" display="block" gutterBottom>
                {customer.email}
              </Typography>
              <Typography variant="overline" display="block" gutterBottom>
                status: {customer.status}
              </Typography>
            </CardContent>
          </Card>
        ))
      ) : (
        <div style={{ margin: '20px 0 30px 0' }}>No models added yet.</div>
      )}
      <Dialog open={modalAddOpen} onClose={handleModalAddClose} fullWidth>
        <DialogTitle id="responsive-dialog-title">Add customer to Test Clock</DialogTitle>
        <DialogContent>
          <TextField
            id="customer-email"
            label="Customer Email"
            variant="outlined"
            value={newCustomerEmail}
            onChange={(e) => {
              setNewCustomerEmail(e.target.value);
            }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleModalAddClose} color="primary">
            Cancel
          </Button>
          <Button
            color="primary"
            autoFocus
            onClick={() => {
              handleCustomerAdd(newCustomerEmail);
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StripeTestClock;
