export const capitalize = (s: string) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

export const displayDateFromTimestamp = (timestamp: number) => {
  const currentDate = new Date(timestamp);

  const currentDayOfMonth = currentDate.getDate();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();

  return `${currentDayOfMonth}/${
    currentMonth < 10 ? `0${currentMonth}` : currentMonth
  }/${currentYear}`;
};

export const parseSnakeCase = (s: string) => {
  if (typeof s !== 'string') return '';
  return capitalize(s.toLowerCase().replaceAll('_', ' '));
};
