import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import React, { useCallback, useState } from 'react';
import { Button } from '@material-ui/core';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { SubscriptionActions } from '../../../ra-queries/queries/subscriptions';

const SubscriptionOperations = (props: any) => {
  const notify = useNotify();
  const [confirmOpenCancel, setConfirmOpenCancel] = useState(false);
  const [confirmOpenDelete, setConfirmOpenDelete] = useState(false);
  const [confirmOpenTrigger, setConfirmOpenTrigger] = useState(false);
  const [confirmOpenRenewal, setConfirmOpenRenewal] = useState(false);
  const stripeTestClockEnabled = process.env.REACT_APP_ENABLE_STRIPE_TEST_CLOCK === 'true';
  const status = props.record.status;

  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const handleSubscriptionCancel = useCallback(async () => {
    const action = SubscriptionActions.CancelSubscription;
    const message = `Subscription has been cancelled`;

    return dataProvider
      .update('Subscriptions', {
        id: props.record.id,
        data: { ...props.record, action },
        previousData: props.record,
      })
      .then(() => {
        refresh();
        notify(message);
      })
      .catch((error) => {
        refresh();
        notify(error.message, 'warning');
      });
  }, [dataProvider, notify, props.record, refresh]);

  const handleTriggerProductUpdate = useCallback(async () => {
    const action = SubscriptionActions.TriggerProductUpdate;
    const message = `Subscription Schedule has been created for Product Update`;

    return dataProvider
      .update('Subscriptions', {
        id: props.record.id,
        data: { ...props.record, action },
        previousData: props.record,
      })
      .then(() => {
        refresh();
        notify(message);
      })
      .catch((error) => {
        refresh();
        notify(error.message, 'warning');
      });
  }, [dataProvider, notify, props.record, refresh]);

  const handleSubscriptionDelete = useCallback(async () => {
    const action = SubscriptionActions.DeleteSubscription;
    const message = `Subscription has been deleted`;

    return dataProvider
      .update('Subscriptions', {
        id: props.record.id,
        data: { ...props.record, action },
        previousData: props.record,
      })
      .then(() => {
        refresh();
        notify(message);
      })
      .catch((error) => {
        refresh();
        notify(error.message, 'warning');
      });
  }, [dataProvider, notify, props.record, refresh]);

  const handleSubscriptionIssueRenewal = useCallback(async () => {
    const action = SubscriptionActions.IssueRenewal;
    const message = `Renewal Request has been issued for this Subscription`;

    return dataProvider
      .update('Subscriptions', {
        id: props.record.id,
        data: { ...props.record, action },
        previousData: props.record,
      })
      .then(() => {
        refresh();
        notify(message);
      })
      .catch((error) => {
        refresh();
        notify(error.message, 'warning');
      });
  }, [dataProvider, notify, props.record, refresh]);

  return (
    <div style={{ margin: 10 }}>
      <Button variant="contained" color="primary" onClick={() => setConfirmOpenCancel(true)}>
        Cancel Upcoming Subscription
      </Button>
      <ConfirmDialog
        title="Cancel Upcoming Subscription?"
        open={confirmOpenCancel}
        setOpen={setConfirmOpenCancel}
        onConfirm={handleSubscriptionCancel}
      >
        Do you want to cancel this subscription?
      </ConfirmDialog>

      <Button
        style={{ marginLeft: 10 }}
        variant="contained"
        color="primary"
        onClick={() => setConfirmOpenDelete(true)}
      >
        Delete Current Subscription
      </Button>
      <ConfirmDialog
        title="Delete Current Subscription?"
        open={confirmOpenDelete}
        setOpen={setConfirmOpenDelete}
        onConfirm={handleSubscriptionDelete}
      >
        Do you want to delete this subscription? This will expire the subscription immediately and
        the associated Zoom code.
      </ConfirmDialog>

      {status === 'Active' && (
        <>
          <Button
            style={{ marginLeft: 10 }}
            variant="contained"
            color="primary"
            onClick={() => setConfirmOpenRenewal(true)}
          >
            Issue Free Renewal Bundle
          </Button>
          <ConfirmDialog
            title="Issue Free Renewal Bundle"
            open={confirmOpenRenewal}
            setOpen={setConfirmOpenRenewal}
            onConfirm={handleSubscriptionIssueRenewal}
          >
            This action will give the User another year of free subscription renewal. Do you want to
            continue?
          </ConfirmDialog>
        </>
      )}

      {stripeTestClockEnabled && (
        <>
          <Button
            style={{ marginLeft: 10 }}
            variant="contained"
            color="primary"
            onClick={() => setConfirmOpenTrigger(true)}
          >
            Trigger Subscription Schedule
          </Button>
          <ConfirmDialog
            title="Trigger Subscription Schedule Creation?"
            open={confirmOpenTrigger}
            setOpen={setConfirmOpenTrigger}
            onConfirm={handleTriggerProductUpdate}
          >
            Make sure that your subscription is under a test clock and you have advanced the clock
            to couple seconds after creating the subscription before triggering the product update.
            Continue?
          </ConfirmDialog>
        </>
      )}
    </div>
  );
};

export default SubscriptionOperations;
