import React from 'react';
import { DateInput, NumberInput, SelectInput, TextInput } from 'react-admin';

export const StyledTextInput = (props: any) => (
  <TextInput {...props} variant="outlined" fullWidth />
);

export const StyledDateInput = (props: any) => (
  <DateInput {...props} variant="outlined" fullWidth />
);

export const StyledNumberInput = (props: any) => (
  <NumberInput {...props} variant="outlined" fullWidth />
);

export const StyledSelectInput = (props: any) => (
  <SelectInput {...props} variant="outlined" fullWidth />
);
