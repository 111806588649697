import React from 'react';
import {
  Datagrid,
  EditButton,
  FunctionField,
  List,
  ListProps,
  NumberField,
  TextField,
} from 'react-admin';

const BundlesList = (props: ListProps) => (
  <List {...props} pagination={false} bulkActionButtons={false}>
    <Datagrid rowClick="show">
      <TextField source="internalName" sortable={false} />
      <TextField source="name" sortable={false} label="Display Name" />

      <NumberField
        source="price"
        sortable={false}
        locales="en-GB"
        options={{ style: 'currency', currency: 'GBP' }}
        textAlign="left"
      />
      <FunctionField
        label="Type"
        render={(record: any) => (record.isDefault ? 'Zoom' : 'Partner')}
      />
      <EditButton />
    </Datagrid>
  </List>
);

export default BundlesList;
