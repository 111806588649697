import React, { ReactNode } from 'react';
import {
  ApolloClient,
  ApolloProvider,
  concat,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Auth } from '@aws-amplify/auth';
import buildGraphQLProvider from 'ra-data-graphql';
import { DataProvider } from 'react-admin';
import buildQuery from '../ra-queries/buildQuery';
import shemaJson from '../interfaces/introspection.generated.json';

const schema = shemaJson.__schema;

export const buildApolloClient = (): any => {
  const apiUrl = `${process.env.REACT_APP_API_URL}/graphql`;

  const authMiddleware = setContext(async (operation, { headers }) => {
    let jwtToken = '';
    const session = await Auth.currentSession();
    try {
      jwtToken = session.getIdToken().getJwtToken();
    } catch (error) {
      console.error('Error: DataProviderFactory | L:19: ', error);
    }

    return {
      uri: `${apiUrl}?q=${operation.operationName}`,
      headers: {
        ...headers,
        authorization: `Bearer ${jwtToken}`,
      },
    };
  });

  const httpLink = createHttpLink({ uri: apiUrl });

  return new ApolloClient({
    cache: new InMemoryCache({ addTypename: false }),
    link: concat(authMiddleware, httpLink),
  });
};

export const ApiProvider = ({ children }: { children?: ReactNode }) => (
  <ApolloProvider client={buildApolloClient()}>{children}</ApolloProvider>
);

const DataProviderFactory = async (): Promise<DataProvider> =>
  buildGraphQLProvider({
    client: buildApolloClient(),
    buildQuery,
    introspection: { schema },
  });

export default DataProviderFactory;
