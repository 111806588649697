import React from 'react';
import {
  BooleanInput,
  Edit,
  EditProps,
  FileField,
  FileInput,
  required,
  SimpleForm,
} from 'react-admin';
import StyledGridLayout from '../../components/StyledGridLayout';
import { StyledTextInput } from '../../components/StyledInputs';
import { SimpleConfirmDeleteToolbar } from '../../components/SimpleConfirmDeleteToolbar';

const LeasingOfferEdit = (props: EditProps) => (
  <Edit {...props} title="Leasing Offer">
    <SimpleForm redirect="show" toolbar={<SimpleConfirmDeleteToolbar />}>
      <StyledGridLayout>
        <StyledTextInput source="title" validate={[required()]} />
        <StyledTextInput source="subTitle" validate={[required()]} />
        <StyledTextInput source="tagline" validate={[required()]} />
        <StyledTextInput source="fromPrice" validate={[required()]} />
        <StyledTextInput source="initialPayment" validate={[required()]} />
        <StyledTextInput source="contractLength" validate={[required()]} />
        <StyledTextInput source="miles" validate={[required()]} />
        <BooleanInput source="visibleToPublic" label="Is visible to public" />
        <BooleanInput source="vatIncluded" label="VAT Included" validate={[required()]} />
        <FileInput source="offerImageForUpload" accept="image/*">
          <FileField source="src" title="Offer Image" />
        </FileInput>
      </StyledGridLayout>
    </SimpleForm>
  </Edit>
);

export default LeasingOfferEdit;
