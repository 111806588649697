import { Auth, CognitoUser } from '@aws-amplify/auth';
import { ClientMetaData } from '@aws-amplify/auth/lib-esm/types';
import { AuthProvider as AuthProviderInterface } from 'ra-core';

export interface AuthProviderOptions {
  authGroups?: string[];
}

const defaultOptions = {
  authGroups: [],
};

export class AuthProvider {
  public authGroups: string[];

  public constructor(options?: AuthProviderOptions) {
    this.authGroups = options?.authGroups || defaultOptions.authGroups;
  }

  public login = async ({
    username,
    password,
    clientMetadata,
  }: Record<string, unknown>): Promise<CognitoUser | unknown> => {
    return Auth.signIn(username as string, password as string, clientMetadata as ClientMetaData);
  };

  public logout = (): Promise<any> => {
    return Auth.signOut();
  };

  public checkAuth = async (): Promise<void> => {
    const session = await Auth.currentSession();

    if (
      session.isValid() &&
      session.getIdToken().decodePayload()['cognito:groups'] &&
      session.getIdToken().decodePayload()['cognito:groups'].includes('Admin')
    ) {
      return;
    }

    throw new Error('Unauthorized');
  };

  public checkError = (error: any): Promise<void> => {
    return Promise.resolve(error);
  };

  public getPermissions = async (): Promise<string[]> => {
    const session = await Auth.currentSession();
    const groups = session.getAccessToken().decodePayload()['cognito:groups'];
    return groups ? Promise.resolve(groups) : Promise.reject();
  };
}

export function buildAuthProvider(options?: AuthProviderOptions): AuthProviderInterface {
  const authProvider = new AuthProvider(options);

  return {
    login: authProvider.login,
    logout: authProvider.logout,
    checkAuth: authProvider.checkAuth,
    checkError: authProvider.checkError,
    getPermissions: authProvider.getPermissions,
  };
}
