/* eslint-disable no-nested-ternary,prettier/prettier */
import React from 'react';
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  List,
  ListProps,
  Pagination,
  TextField,
  TextInput,
  useRedirect,
} from 'react-admin';
import { Chip } from '@material-ui/core';
import {
  Assignment as AssignmentIcon,
  BusinessCenter as BusinessIcon,
  Check as CheckIcon,
  Create as CreateIcon,
  Help as HelpIcon,
  Person as PersonIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const CarsFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn variant="outlined" />
    <BooleanInput label="Required Approval Only" alwaysOn source="pendingApproval" />
    <BooleanInput label="Display Drafts" alwaysOn source="withDrafts" />
  </Filter>
);

// const carRowStyle = (record: any) => ({
//   backgroundColor:
//     record.availabilityPeriods && record.availabilityPeriods.length > 0 ? 'white' : '#ffe3b1',
// });

const carRowStyle = () => ({
  backgroundColor: 'white',
});

const useStyles = makeStyles({
  registrationCell: {
    width: 150,
  },
  carMakerCell: {
    width: 150,
  },
  ownerCell: {
    width: 100,
  },
  statusCell: {
    width: 180,
  },
  createdAtCell: {
    width: 180,
  },
});

const CarsList = (props: ListProps) => {
  const classes = useStyles();
  const redirect = useRedirect();

  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<CarsFilter />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={10}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
    >
      <Datagrid rowClick="show" rowStyle={carRowStyle}>
        <TextField
          source="details.registration"
          label="Registration"
          cellClassName={classes.registrationCell}
        />
        <TextField
          source="details.maker.name"
          label="Maker"
          sortable={false}
          cellClassName={classes.carMakerCell}
        />
        <TextField source="details.model.name" label="Model" sortable={false} />
        <TextField source="details.vin" label="VIN" sortable={false} />
        <BooleanField source="isAvailableToBuy" sortable={false} label="Is Available to Buy" />
        <FunctionField
          label="Owner"
          textAlign="center"
          cellClassName={classes.ownerCell}
          render={(record: any) => {
            if (record.business) {
              return (
                <Chip
                  size="small"
                  icon={<BusinessIcon />}
                  label={record.business?.displayName || record.business?.name}
                  variant="outlined"
                />
              );
            }

            if (record.user) {
              return (
                <Chip
                  size="small"
                  icon={<PersonIcon />}
                  label={`${record.user?.details?.firstName} ${record.user?.details?.lastName}`.trim()}
                  variant="outlined"
                  onClick={(e) => {
                    e.stopPropagation();
                    redirect(`/Users/${record.user.id}/show`);
                  }}
                />
              );
            }

            return null;
          }}
        />
        <FunctionField
          label="Status"
          textAlign="center"
          cellClassName={classes.statusCell}
          render={(record: any) => {
            let label;
            let icon;
            let color;

            if (record.isDraft) {
              label = 'Draft';
              color = '#528eff';
              icon = <CreateIcon style={{ color }} />;
            }

            if (!label && record.detailsRequested && record.details.approveStatus) {
              label = 'Requested Changes';
              color = '#ff8a36';
              icon = <AssignmentIcon style={{ color }} />;
            }

            if (!label && record.detailsRequested && !record.details.approveStatus) {
              label = 'Requires Approval';
              color = '#ff8a36';
              icon = <AssignmentIcon style={{ color }} />;
            }

            if (!label && record.details && record.details.approveStatus) {
              label = 'Approved';
              color = 'green';
              icon = <CheckIcon style={{ color }} />;
            }

            if (!label) {
              label = 'Unknown';
              icon = <HelpIcon />;
            }

            return (
              <Chip
                size="small"
                label={`${label} ${record.visible ? '' : ' (Hidden)'}`.trim()}
                icon={icon}
                variant="outlined"
                style={{
                  borderColor: color,
                  color,
                }}
              />
            );
          }}
        />
        <DateField
          source="createdAt"
          showTime
          cellClassName={classes.createdAtCell}
          textAlign="center"
        />
      </Datagrid>
    </List>
  );
};

export default CarsList;
