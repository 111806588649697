import { RaListParams } from '../interfaces/RaQueries';
import { ApiListParams } from '../interfaces/ApiQueries';
import { capitalize } from './stringHelpers';

interface InvitesListParams extends ApiListParams {
  status?: string;
  sort?: string;
  businessId?: string;
}

const getInvitesListParams = ({ pagination, sort, filter }: RaListParams): InvitesListParams => {
  const { perPage, page } = pagination;
  const limit = perPage;
  const offset = (page - 1) * (perPage || 1);
  const sortConverted = `CreatedAt${capitalize(sort.order)}`;

  return {
    limit,
    offset,
    sort: sortConverted,
    status: filter.status,
    searchPhrase: filter.q,
    businessId: filter.business,
  };
};

export default getInvitesListParams;
