import { RaListParams } from '../interfaces/RaQueries';

interface PricingsListParams {
  business: boolean;
}

const getPricingsListParams = (params: RaListParams): PricingsListParams => {
  return {
    business: params.filter.type === 'Business',
  };
};

export default getPricingsListParams;
