import React from 'react';
import { Create, CreateProps, required, SimpleForm, Toolbar } from 'react-admin';
import SaveButtonAware from '../../components/SaveButtonAware';
import StyledGridLayout from '../../components/StyledGridLayout';
import { StyledNumberInput, StyledTextInput } from '../../components/StyledInputs';

const BundleCreateToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButtonAware />
  </Toolbar>
);

const BundleCreate = (props: CreateProps) => (
  <Create {...props} title="Bundles">
    <SimpleForm toolbar={<BundleCreateToolbar />} redirect="show">
      <StyledGridLayout>
        <StyledTextInput source="name" label="Display Name" validate={[required()]} />
        <StyledTextInput source="internalName" label="Internal Name" validate={[required()]} />
        <StyledNumberInput source="price" step={1} validate={[required()]} />
        <StyledTextInput source="description" validate={[required()]} />
        <StyledTextInput source="contentPageUrl" validate={[required()]} />
        <StyledTextInput
          source="contentfulBenefitsComponentId"
          label="Contentful Benefits Component Id"
        />
        <StyledTextInput
          source="contentfulBannerComponentId"
          label="Contentful Banner Component Id"
        />
      </StyledGridLayout>
    </SimpleForm>
  </Create>
);

export default BundleCreate;
