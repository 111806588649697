import { gql } from '@apollo/client';
import {
  RaFetchType,
  RaGetResponse,
  RaListParams,
  RaListResponse,
  RaQuery,
} from '../../interfaces/RaQueries';
import { LeasingEnquiryType } from '../../interfaces/types.generated';
import { ApiResponse } from '../../interfaces/ApiQueries';
import getLeasingEnquiriesListParams from '../../utils/getLeasingEnquiriesListParams';

interface ListResult {
  leasingEnquiries: {
    leasingEnquiries: Array<LeasingEnquiryType>;
    overallCount: number;
  };
}

export enum LeasingEnquiryActions {
  AddNote = 'ADD_NOTE',
  EditNote = 'EDIT_NOTE',
  RemoveNote = 'REMOVE_NOTE',
  MarkAsContacted = 'MARK_AS_CONTACTED',
  MarkAsNoResponse = 'MARK_AS_NO_RESPONSE',
}

const LeasingEnquiries = (raFetchType: RaFetchType, params: any): RaQuery => {
  switch (raFetchType) {
    case 'GET_LIST':
      return {
        query: gql`
          query (
            $limit: Int
            $offset: Int
            $searchPhrase: String
            $order: LeasingEnquiriesQueryOrderEnum!
            $leasingOfferId: String
            $showAll: Boolean!
          ) {
            leasingEnquiries(
              params: {
                limit: $limit
                offset: $offset
                searchPhrase: $searchPhrase
                order: $order
                leasingOfferId: $leasingOfferId
                showAll: $showAll
              }
            ) {
              leasingEnquiries {
                id
                leasingOfferId
                contactName
                email
                phoneNumber
                contractLength
                contractType
                initialPayment
                annualMileage
                includeMaintenance
                message
                status
                createdAt
                updatedAt
                leasingOffer {
                  id
                  title
                  subTitle
                }
              }
              overallCount
            }
          }
        `,
        variables: getLeasingEnquiriesListParams(params as RaListParams),
        parseResponse: ({ data }: ApiResponse<ListResult>): RaListResponse<LeasingEnquiryType> => ({
          data: data.leasingEnquiries.leasingEnquiries,
          total: data.leasingEnquiries.overallCount,
        }),
      };
    case 'GET_ONE':
      return {
        query: gql`
          query ($id: String!) {
            leasingEnquiry(id: $id) {
              id
              leasingOfferId
              contactName
              email
              phoneNumber
              contractLength
              contractType
              initialPayment
              annualMileage
              includeMaintenance
              status
              message
              leasingOffer {
                id
                title
                subTitle
              }
              notes {
                id
                note
                isDraft
                createdAt
              }
              createdAt
              updatedAt
            }
          }
        `,
        variables: { id: params.id },
        parseResponse: ({ data }): RaGetResponse<LeasingEnquiryType> => ({
          data: {
            ...data.leasingEnquiry,
          },
        }),
      };
    case 'UPDATE':
      if (params.data.action === LeasingEnquiryActions.AddNote) {
        return {
          query: gql`
            mutation createLeasingEnquiryNote(
              $leasingEnquiryId: String!
              $note: String!
              $isDraft: Boolean!
            ) {
              createLeasingEnquiryNote(
                leasingEnquiryId: $leasingEnquiryId
                note: $note
                isDraft: $isDraft
              ) {
                id
                note
                isDraft
                createdAt
              }
            }
          `,
          variables: {
            leasingEnquiryId: params.id,
            note: params.data.note,
            isDraft: params.data.isDraft,
          },
          parseResponse: (): RaGetResponse<LeasingEnquiryType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === LeasingEnquiryActions.EditNote) {
        return {
          query: gql`
            mutation updateLeasingEnquiryNote(
              $noteId: String!
              $note: String!
              $isDraft: Boolean!
            ) {
              updateLeasingEnquiryNote(noteId: $noteId, note: $note, isDraft: $isDraft) {
                id
                note
                isDraft
                createdAt
              }
            }
          `,
          variables: {
            noteId: params.data.noteId,
            note: params.data.note,
            isDraft: params.data.isDraft,
          },
          parseResponse: (): RaGetResponse<LeasingEnquiryType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === LeasingEnquiryActions.MarkAsContacted) {
        return {
          query: gql`
            mutation ($id: String!) {
              markLeasingEnquiryAsContacted(id: $id) {
                id
                leasingOfferId
                contactName
                email
                phoneNumber
                contractLength
                contractType
                initialPayment
                annualMileage
                includeMaintenance
                status
                message
                notes {
                  id
                  note
                  isDraft
                  createdAt
                }
                createdAt
                updatedAt
              }
            }
          `,
          variables: { id: params.id },
          parseResponse: ({ data }): RaGetResponse<LeasingEnquiryType> => ({
            data: {
              ...data.markLeasingEnquiryAsContacted,
            },
          }),
        };
      }
      if (params.data.action === LeasingEnquiryActions.MarkAsNoResponse) {
        return {
          query: gql`
            mutation ($id: String!) {
              markLeasingEnquiryAsNoResponse(id: $id) {
                id
                leasingOfferId
                contactName
                email
                phoneNumber
                contractLength
                contractType
                initialPayment
                annualMileage
                includeMaintenance
                status
                message
                notes {
                  id
                  note
                  isDraft
                  createdAt
                }
                createdAt
                updatedAt
              }
            }
          `,
          variables: { id: params.id },
          parseResponse: ({ data }): RaGetResponse<LeasingEnquiryType> => ({
            data: {
              ...data.markLeasingEnquiryAsNoResponse,
            },
          }),
        };
      }
      return {
        query: gql`
          mutation deleteLeasingEnquiryNote($noteId: String!) {
            deleteLeasingEnquiryNote(noteId: $noteId) {
              id
            }
          }
        `,
        variables: { noteId: params.data.noteId },
        parseResponse: (): RaGetResponse<LeasingEnquiryType> => ({
          data: { id: params.id, ...params.data },
        }),
      };
    default:
      throw new Error(`Unknown fetch type: ${raFetchType}`);
  }
};

export default LeasingEnquiries;
