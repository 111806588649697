import React, { useCallback } from 'react';
import {
  BooleanField,
  DateField,
  EditProps,
  FunctionField,
  Link,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useDataProvider,
  useNotify,
  useRefresh,
  useShowController,
} from 'react-admin';
import LeasingEnquiryNotes from './elements/LeasingEnquiryNotes';
import { LeasingEnquiryStatusEnum } from '../../interfaces/types.generated';
import { Button } from '@material-ui/core';
import { LeasingEnquiryActions } from '../../ra-queries/queries/leasingEnquiries';

const LeasingEnquiriesShow = (props: EditProps) => {
  const { record } = useShowController(props);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const handleMarkAsContacted = useCallback(
    async (currentRecord: any) => {
      return dataProvider
        .update('LeasingEnquiries', {
          id: currentRecord.id,
          data: { ...currentRecord, action: LeasingEnquiryActions.MarkAsContacted },
          previousData: currentRecord,
        })
        .then(() => {
          refresh();
          notify('Leasing enquiry has been marked as complete');
        })
        .catch((error) => {
          refresh();
          notify(`Leasing enquiry could not be marked as complete: ${error.message}`, 'warning');
        });
    },
    [dataProvider, notify, refresh],
  );

  const handleMarkAsNoResponse = useCallback(
    async (currentRecord: any) => {
      return dataProvider
        .update('LeasingEnquiries', {
          id: currentRecord.id,
          data: { ...currentRecord, action: LeasingEnquiryActions.MarkAsNoResponse },
          previousData: currentRecord,
        })
        .then(() => {
          refresh();
          notify('Leasing enquiry has been marked as No Response');
        })
        .catch((error) => {
          refresh();
          notify(`Leasing enquiry could not be marked as No Response: ${error.message}`, 'warning');
        });
    },
    [dataProvider, notify, refresh],
  );

  if (!record) return null;

  return (
    <Show {...props} title="Leasing Offers">
      <TabbedShowLayout>
        <Tab label="summary" contentClassName="two-columns-layout">
          <TextField source="id" label="Id" />
          <TextField source="contactName" label="Contact Name" />
          <TextField source="email" label="Eamil" />
          <TextField source="phoneNumber" label="Phone Number" />
          <TextField source="contractType" label="Contract Type" />
          <TextField source="contractLength" label="Contract Length" />
          <TextField source="initialPayment" label="Initial Payment" />
          <TextField source="annualMileage" label="Annual Mileage" />
          <BooleanField source="includeMaintenance" label="Include Maintenance" />
          <TextField source="status" label="Status" />
          <TextField source="message" label="Message" />
          <DateField source="createdAt" label="Created At" />
          <FunctionField
            label="Leasing Offer"
            render={(enquiry: any) => {
              return (
                <Link to={`/LeasingOffers/${enquiry.leasingOfferId}/show`}>
                  {enquiry.leasingOffer.title}
                </Link>
              );
            }}
          />
          {record.status === LeasingEnquiryStatusEnum.Pending && (
            <>
              <div style={{ marginBottom: '20px', display: 'flex' }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: '10px', display: 'flex' }}
                  onClick={() => handleMarkAsContacted(record)}
                >
                  Mark as Contacted
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleMarkAsNoResponse(record)}
                >
                  Mark as No Response
                </Button>
              </div>
            </>
          )}
        </Tab>
        <Tab label="Notes" path="notes">
          <LeasingEnquiryNotes leasingEnquiry={record} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
export default LeasingEnquiriesShow;
