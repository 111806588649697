import React, { useCallback, useState } from 'react';
import parse from 'html-react-parser';
import { Button, TextField } from '@material-ui/core';
import 'quill/dist/quill.snow.css'; // Add css for snow theme
import EditIcon from '@material-ui/icons/Edit';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { CarActions } from '../../../ra-queries/queries/cars';

const CarLocationTipsEditor = (props: any) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const [newLocationTips, setNewLocationTips] = useState<string | undefined>(undefined);

  const handleCarLocationTipsSave = useCallback(
    async (carLocationTips: string) => {
      return dataProvider
        .update('Cars', {
          id: props.record.id,
          data: {
            ...props.record,
            action: CarActions.UpdateCarLocationTips,
            carLocationTips,
          },
          previousData: props.record,
        })
        .then(() => {
          refresh();
          notify('Car location tips has been updated');
        })
        .catch((error) => {
          refresh();
          notify(`Car location tips could not be updated: ${error.message}`, 'warning');
        });
    },
    [dataProvider, notify, props.record, refresh],
  );

  return (
    <div>
      <div style={{ width: '100%' }}>
        <TextField
          label="Car Location Tips"
          value={
            newLocationTips !== undefined
              ? newLocationTips
              : (props.record.carLocationTips as string)
          }
          onChange={(e) => setNewLocationTips(e.target.value)}
          fullWidth
        />
      </div>
      <div style={{ margin: '60px 0 20px 0', display: 'flex' }}>
        <div style={{ marginRight: '20px' }}>
          <Button onClick={() => props.setIsEditing(false)}>Cancel</Button>
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (newLocationTips === undefined) {
              return;
            }

            handleCarLocationTipsSave(newLocationTips);
          }}
          disabled={
            newLocationTips === undefined || newLocationTips === props.record.carLocationTips
          }
        >
          Save car location tips
        </Button>
      </div>
    </div>
  );
};

const CarLocationTips = (props: any) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      {isEditing ? (
        <CarLocationTipsEditor {...props} setIsEditing={setIsEditing} />
      ) : (
        <div style={{ marginTop: '20px', fontSize: '12px', lineHeight: '1.3' }}>
          {props.record.carLocationTips ? parse(props.record.carLocationTips) : '-'}
          <div style={{ margin: '20px 0', display: 'flex' }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<EditIcon />}
              onClick={() => setIsEditing(true)}
            >
              Update car location tips
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default CarLocationTips;
