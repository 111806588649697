import React from 'react';
import {
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  Link,
  List,
  ListProps,
  TextField,
  TextInput,
} from 'react-admin';
import { gql, useQuery } from '@apollo/client';
import { StyledSelectInput } from '../../components/StyledInputs';

const GET_BUSINESSES = gql`
  query Businesses {
    businesses {
      id
      name
      displayName
    }
  }
`;

const InvitesFilter = (props: any) => {
  const { data } = useQuery(GET_BUSINESSES);
  return (
    <Filter {...props}>
      <TextInput label="Invitee Email/Phone" source="q" alwaysOn variant="outlined" />
      <StyledSelectInput
        source="status"
        label="Status"
        choices={[
          { id: 'PENDING', name: 'Pending' },
          { id: 'USED', name: 'Used' },
          { id: 'REVOKED', name: 'Revoked' },
        ]}
        alwaysOn
      />
      <StyledSelectInput
        source="business"
        label="Business"
        choices={
          data &&
          data.businesses.map((buisness: any) => {
            return { id: buisness.id, name: `${buisness.displayName} (${buisness.name})` };
          })
        }
      />
    </Filter>
  );
};

const InvitesList = (props: ListProps) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<InvitesFilter />}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid rowClick="show">
        <TextField source="resellerBusiness.displayName" label="Business" sortable={false} />
        <FunctionField
          source="grantee"
          label="Grantee email"
          render={(discount: any) => {
            if (discount.grantee) {
              return discount.grantee.user !== null ? (
                <Link to={`/Users/${discount.grantee.user?.id}/show`}>
                  {discount.grantee.email}
                </Link>
              ) : (
                discount.grantee.email
              );
            }
            return '';
          }}
        />
        <TextField source="grantee.phoneNumber" label="Grantee Phone Number" sortable={false} />
        <TextField source="status" sortable={false} />
        <DateField source="createdAt" showTime />
      </Datagrid>
    </List>
  );
};

export default InvitesList;
