/* eslint-disable no-underscore-dangle */
import { gql } from '@apollo/client';
import { RaFetchType, RaGetResponse, RaListResponse, RaQuery } from '../../interfaces/RaQueries';
import { CarMakerType } from '../../interfaces/types.generated';
import { ApiResponse } from '../../interfaces/ApiQueries';

interface ListResult {
  carMakers: Array<CarMakerType>;
}

export enum CarmakersActions {
  CreateCarModel = 'CREATE_CAR_MODEL',
  UpdateCarModel = 'UPDATE_CAR_MODEL',
  DeleteCarModel = 'DELETE_CAR_MODEL',
}

const Carmakers = (raFetchType: RaFetchType, params: any): RaQuery => {
  switch (raFetchType) {
    case 'GET_LIST':
      return {
        query: gql`
          query {
            carMakers(visible: null) {
              id
              name
              visible
              models {
                id
                name
                visible
              }
            }
          }
        `,
        variables: {},
        parseResponse: ({ data }: ApiResponse<ListResult>): RaListResponse<CarMakerType> => ({
          data: data.carMakers,
          total: data.carMakers.length,
        }),
      };
    case 'GET_ONE':
      return {
        query: gql`
          query ($id: String!) {
            carMaker(id: $id) {
              id
              name
              visible
              models {
                id
                name
                visible
              }
            }
          }
        `,
        variables: params,
        parseResponse: ({ data }): RaGetResponse<CarMakerType> => ({
          data: data.carMaker,
        }),
      };
    case 'CREATE':
      return {
        query: gql`
          mutation ($name: String!) {
            createCarMaker(name: $name) {
              id
            }
          }
        `,
        variables: { name: params.data.name },
        parseResponse: ({ data }): RaGetResponse<CarMakerType> => ({
          data: data.createCarMaker,
        }),
      };
    case 'DELETE':
      return {
        query: gql`
          mutation ($id: String!) {
            deleteCarMaker(id: $id)
          }
        `,
        variables: { id: params.id },
        parseResponse: ({ data }): RaGetResponse<CarMakerType> => ({
          data: data.deleteCarMaker,
        }),
      };
    case 'UPDATE':
      if (params.data.action === CarmakersActions.CreateCarModel) {
        return {
          query: gql`
            mutation createCarModel($name: String!, $carMakerId: String!) {
              createCarModel(name: $name, carMakerId: $carMakerId) {
                id
              }
            }
          `,
          variables: {
            name: params.data.name,
            carMakerId: params.data.carMakerId,
          },
          parseResponse: (): RaGetResponse<CarMakerType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === CarmakersActions.UpdateCarModel) {
        return {
          query: gql`
            mutation updateCarModel($visible: Boolean, $name: String, $carModelId: String!) {
              updateCarModel(visible: $visible, name: $name, carModelId: $carModelId) {
                id
              }
            }
          `,
          variables: {
            visible: params.data.visible,
            name: params.data.name,
            carModelId: params.data.carModelId,
          },
          parseResponse: (): RaGetResponse<CarMakerType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === CarmakersActions.DeleteCarModel) {
        return {
          query: gql`
            mutation deleteCarModel($id: String!) {
              deleteCarModel(id: $id)
            }
          `,
          variables: {
            id: params.data.carId,
          },
          parseResponse: (): RaGetResponse<CarMakerType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      // STANDARD CAR MAKER UPDATE ACTION
      return {
        query: gql`
          mutation updateCarMaker($visible: Boolean, $name: String, $carMakerId: String!) {
            updateCarMaker(visible: $visible, name: $name, carMakerId: $carMakerId) {
              id
            }
          }
        `,
        variables: {
          visible: params.data.visible,
          name: params.data.name,
          carMakerId: params.id,
        },
        parseResponse: ({ data }): RaGetResponse<CarMakerType> => ({
          data: data.updateCarMaker,
        }),
      };
    default:
      throw new Error(`Unknown fetch type: ${raFetchType}`);
  }
};

export default Carmakers;
