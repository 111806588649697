import React from 'react';
import PropTypes from 'prop-types';
import { SaveButton } from 'react-admin';

const SaveButtonAware = ({ invalid, ...rest }: any) => <SaveButton disabled={invalid} {...rest} />;

SaveButtonAware.propTypes = {
  invalid: PropTypes.bool,
};

export default SaveButtonAware;
