import React from 'react';
import {
  BooleanInput,
  Datagrid,
  DateField,
  Filter,
  List,
  ListProps,
  TextField,
  TextInput,
} from 'react-admin';

const LeasingEnquiriesFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput label="Contact name" source="q" alwaysOn variant="outlined" />
      <TextInput label="Leasing Offer Id" source="leasingOfferId" alwaysOn variant="outlined" />
      <BooleanInput
        label="Show Contacted/No Response"
        alwaysOn
        source="showAll"
        defaultValue={false}
      />
    </Filter>
  );
};

const LeasingEnquiriesList = (props: ListProps) => (
  <List
    {...props}
    pagination={false}
    bulkActionButtons={false}
    empty={false}
    filters={<LeasingEnquiriesFilter />}
  >
    <Datagrid rowClick="show">
      <TextField source="contactName" label="Contact Name" />
      <TextField source="email" label="Eamil" />
      <TextField source="phoneNumber" label="Phone Number" />
      <TextField source="contractType" label="Contract Type" />
      <TextField source="contractLength" label="Contract Length" />
      <TextField source="leasingOffer.title" label="Leasing Offer Title" />
      <DateField source="createdAt" label="Created At" />
    </Datagrid>
  </List>
);

export default LeasingEnquiriesList;
