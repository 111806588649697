import React from 'react';
import { Datagrid, EditButton, List, ListProps, TextField } from 'react-admin';

const BusinessesList = (props: ListProps) => (
  <List {...props} pagination={false} bulkActionButtons={false}>
    <Datagrid rowClick="show">
      <TextField source="type" label="Type" />
      <TextField source="displayName" label="Display Name" />
      <TextField source="name" label="Company Name" />
      <TextField source="brandName" label="Brand" />
      <TextField source="locationName" label="Location" />
      <EditButton />
    </Datagrid>
  </List>
);

export default BusinessesList;
