import React, { useState } from 'react';
import { Create, CreateProps, FormTab, required, TabbedForm, Toolbar } from 'react-admin';
import { Button } from '@material-ui/core';
import StyledGridLayout from '../../components/StyledGridLayout';
import { StyledSelectInput, StyledTextInput } from '../../components/StyledInputs';
import SaveButtonAware from '../../components/SaveButtonAware';
import { ColorInput } from '../../components/ColorInput';

const OfferCreateToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButtonAware />
  </Toolbar>
);

export enum ContentType {
  Hero,
  Secondary,
  Tile,
}

const OfferCreate = (props: CreateProps) => {
  const [addHeroContent, setAddHeroContent] = useState(false);
  const [addSecondaryContent, setAddSecondaryContent] = useState(false);
  const [addTileContent, setAddTileContent] = useState(false);

  const toggleContent = (type: ContentType, toggle: boolean) => {
    switch (type) {
      case ContentType.Hero: {
        setAddHeroContent(toggle);
        break;
      }
      case ContentType.Secondary: {
        setAddSecondaryContent(toggle);
        break;
      }
      case ContentType.Tile: {
        setAddTileContent(toggle);
        break;
      }
    }
  };

  return (
    <Create {...props} title="Businesses">
      <TabbedForm toolbar={<OfferCreateToolbar />} redirect="show">
        <FormTab label="Offer Detail">
          <StyledGridLayout>
            <StyledTextInput source="offerName" validate={[required()]} />
            <StyledSelectInput
              source="offerType"
              label="Type"
              choices={[
                { id: 'MyOffers', name: 'My Offers' },
                { id: 'PrizeDraw', name: 'Prize Draw' },
              ]}
              validate={[required()]}
            />
            <StyledTextInput
              source="offerDetailSlug"
              label="Offer detail contentful slug"
              validate={[required()]}
            />
            <StyledTextInput source="contentfulEntityId" label="Contentful entity id" />
          </StyledGridLayout>
        </FormTab>
        <FormTab label="Hero Content">
          {!addHeroContent && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => toggleContent(ContentType.Hero, true)}
            >
              Add Hero Content
            </Button>
          )}
          {addHeroContent && (
            <>
              <StyledGridLayout>
                <StyledTextInput
                  source="heroContentTitle"
                  validate={[addHeroContent, required()]}
                />
                <StyledTextInput
                  source="heroContentContent"
                  validate={[addHeroContent, required()]}
                />
                <StyledTextInput
                  source="heroContentActionTitle"
                  validate={[addHeroContent, required()]}
                />
                <StyledTextInput
                  source="heroContentActionLink"
                  validate={[addHeroContent, required()]}
                />
              </StyledGridLayout>
              <Button
                variant="contained"
                color="primary"
                onClick={() => toggleContent(ContentType.Hero, false)}
              >
                Remove
              </Button>
            </>
          )}
        </FormTab>
        <FormTab label="Secondary Content">
          {!addSecondaryContent && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => toggleContent(ContentType.Secondary, true)}
            >
              Add Secondary Content
            </Button>
          )}
          {addSecondaryContent && (
            <>
              <StyledGridLayout>
                <StyledTextInput
                  source="secondaryContentTitle"
                  validate={[addSecondaryContent, required()]}
                />
                <StyledTextInput
                  source="secondaryContentContent"
                  validate={[addSecondaryContent, required()]}
                />
                <StyledTextInput
                  source="secondaryContentActionTitle"
                  validate={[addSecondaryContent, required()]}
                />
                <StyledTextInput
                  source="secondaryContentActionLink"
                  validate={[addSecondaryContent, required()]}
                />
                <ColorInput
                  source="secondaryContentColor"
                  label="Card Color"
                  validate={required()}
                />
              </StyledGridLayout>
              <Button
                variant="contained"
                color="primary"
                onClick={() => toggleContent(ContentType.Secondary, false)}
              >
                Remove
              </Button>
            </>
          )}
        </FormTab>
        <FormTab label="Tile Content">
          {!addTileContent && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => toggleContent(ContentType.Tile, true)}
            >
              Add Tile Content
            </Button>
          )}
          {addTileContent && (
            <>
              <StyledGridLayout>
                <StyledTextInput
                  source="tileContentTitle"
                  validate={[addTileContent, required()]}
                />
                <StyledTextInput
                  source="tileContentContent"
                  validate={[addTileContent, required()]}
                />
                <StyledTextInput
                  source="tileContentActionTitle"
                  validate={[addTileContent, required()]}
                />
                <StyledTextInput
                  source="tileContentActionLink"
                  validate={[addTileContent, required()]}
                />
              </StyledGridLayout>
              <Button
                variant="contained"
                color="primary"
                onClick={() => toggleContent(ContentType.Tile, false)}
              >
                Remove
              </Button>
            </>
          )}
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default OfferCreate;
