/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useCallback, useMemo, useState } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Calendar, dateFnsLocalizer, Event, stringOrDate } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import { enGB } from 'date-fns/locale';
import areIntervalsOverlapping from 'date-fns/areIntervalsOverlapping';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { CarActions } from '../../../ra-queries/queries/cars';

interface AddEventProps {
  start: stringOrDate;
  end: stringOrDate;
}

const locales = {
  'en-GB': enGB,
};

const formats = {
  dateFormat: 'dd/MM/yyyy',
  dayFormat: 'EEEE, dd/MM',
  timeGutterFormat: 'h aaa',
  dayHeaderFormat: 'dd/MM/yyyy',
  eventTimeRangeFormat: ({ start, end }: { start: Date; end: Date }) =>
    `${format(start, 'hh:mm a')} - ${format(end, 'hh:mm a')}`,
  dayRangeHeaderFormat: ({ start, end }: { start: Date; end: Date }) =>
    `${format(start, 'dd MMM.')} - ${format(end, 'dd MMM.')}`,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const useStyles = makeStyles({
  datepicker: {
    marginBottom: '20px',
  },
});

const CarAvailabilityPeriods = (props: any) => {
  const classes = useStyles();
  const car = props.record;
  const availabilityPeriods = car.availabilityPeriods;
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const [allDay, setAllDay] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const eventsList = useMemo(() => {
    return availabilityPeriods
      ? availabilityPeriods.map((period: any) => {
          if (period.allDayDate) {
            return {
              start: new Date(`${period.allDayDate}T00:00`),
              end: new Date(`${period.allDayDate}T23:59:59`),
              resource: period,
            };
          }
          return {
            start: new Date(period.timeStart),
            end: new Date(period.timeEnd),
            resource: period,
          } as Event;
        })
      : [];
  }, [availabilityPeriods]);
  const [newEvent, setNewEvent] = useState<any>();

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const addEvent = (props: AddEventProps) => {
    const { end, start } = props as { end: Date; start: Date };
    const isEvenOverlapping = eventsList.findIndex(
      (event: Event) =>
        areIntervalsOverlapping(
          { end, start },
          { start: event.start as Date, end: event.end as Date },
        ) === true,
    );

    if (isEvenOverlapping === -1 && eventsList) {
      setNewEvent({
        carId: car.id,
        timeStart: start,
        timeEnd: end,
        allDayDate: format(start, 'yyyy-MM-dd'),
      });
      setShowPopup(true);
    } else {
      notify('Events can not overlap', 'warning');
    }
  };

  const handleModalClose = () => {
    setShowPopup(false);
    setNewEvent(undefined);
    setAllDay(false);
  };

  const handlePeriodAdd = useCallback(
    async (eventToBeAdded) => {
      return dataProvider
        .update('Cars', {
          id: car.id,
          data: {
            carId: eventToBeAdded.carId,
            allDayDate:
              allDay && !eventToBeAdded.recurringWeekly ? eventToBeAdded.allDayDate : undefined,
            recurringWeekly: eventToBeAdded.recurringWeekly,
            recurringUntil: eventToBeAdded.recurringWeekly
              ? eventToBeAdded.recurringUntil.getTime()
              : undefined,
            timeStart: allDay
              ? eventToBeAdded.recurringWeekly
                ? new Date(`${eventToBeAdded.allDayDate}T00:00`).getTime()
                : undefined
              : eventToBeAdded.timeStart.getTime(),
            timeEnd: allDay
              ? eventToBeAdded.recurringWeekly
                ? new Date(`${eventToBeAdded.allDayDate}T23:59:59`).getTime()
                : undefined
              : eventToBeAdded.timeEnd.getTime(),
            action: CarActions.AddAvailabilityPeriod,
          },
          previousData: car,
        })
        .then(() => {
          refresh();
          notify('Availability period has been added');
          handleModalClose();
        })
        .catch((error) => {
          refresh();
          notify(`Availability period could not be added: ${error.message}`, 'warning');
        });
    },
    [allDay, car, dataProvider, notify, refresh],
  );

  const handlePeriodRemove = useCallback(
    async (periodId) => {
      return dataProvider
        .update('Cars', {
          id: car.id,
          data: {
            action: CarActions.RemoveAvailabilityPeriod,
            periodId,
          },
          previousData: car,
        })
        .then(() => {
          refresh();
          notify('Availability period has been removed');
          handleModalClose();
        })
        .catch((error) => {
          refresh();
          notify(`Availability period could not be removed: ${error.message}`, 'warning');
        });
    },
    [car, dataProvider, notify, refresh],
  );

  return (
    <>
      <Calendar
        defaultView="week"
        endAccessor="end"
        events={eventsList}
        formats={formats}
        localizer={localizer}
        onSelectEvent={(event: any) => {
          // eslint-disable-next-line no-alert
          if (window.confirm('Would you like to remove this period?'))
            handlePeriodRemove(event.resource.id);
        }}
        onSelectSlot={addEvent}
        selectable
        startAccessor="start"
        step={15}
        timeslots={4}
        views={['week']}
      />
      <Dialog open={showPopup} onClose={handleModalClose} fullWidth>
        <DialogTitle id="responsive-dialog-title">Add availability period</DialogTitle>
        <DialogContent>
          {newEvent && (
            <>
              {allDay ? (
                <TextField
                  type="date"
                  id="allDayDate"
                  label="Date"
                  value={newEvent.allDayDate}
                  onChange={(e) => {
                    setNewEvent({ ...newEvent, allDayDate: e.target.value });
                  }}
                  fullWidth
                  className={classes.datepicker}
                />
              ) : (
                <>
                  <TextField
                    type="datetime-local"
                    id="time-start"
                    label="From"
                    value={format(newEvent.timeStart, "yyyy-MM-dd'T'HH:mm")}
                    onChange={(e) => {
                      setNewEvent({ ...newEvent, timeStart: new Date(e.target.value) });
                    }}
                    fullWidth
                    className={classes.datepicker}
                  />
                  <TextField
                    type="datetime-local"
                    id="time-end"
                    label="To"
                    value={format(newEvent.timeEnd, "yyyy-MM-dd'T'HH:mm")}
                    onChange={(e) => {
                      setNewEvent({ ...newEvent, timeEnd: new Date(e.target.value) });
                    }}
                    fullWidth
                    className={classes.datepicker}
                  />
                </>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={allDay}
                    onChange={(e) => {
                      setAllDay(e.target.checked);
                    }}
                    name="allDay"
                  />
                }
                label="All day"
              />
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={newEvent.recurringWeekly}
                      onChange={(e) => {
                        setNewEvent({ ...newEvent, recurringWeekly: e.target.checked });
                      }}
                      name="recurringWeekly"
                    />
                  }
                  label="Recurring weekly"
                />
              </div>
              {newEvent.recurringWeekly && (
                <TextField
                  type="date"
                  id="recurringUntil"
                  label="Recurring until"
                  value={format(newEvent.recurringUntil || new Date(), 'yyyy-MM-dd')}
                  onChange={(e) => {
                    setNewEvent({ ...newEvent, recurringUntil: new Date(e.target.value) });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleModalClose} color="primary">
            Cancel
          </Button>
          <Button color="primary" autoFocus onClick={() => handlePeriodAdd(newEvent)}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CarAvailabilityPeriods;
