import React from 'react';
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  EditButton,
  Filter,
  FunctionField,
  List,
  ListProps,
  TextField,
  TextInput,
} from 'react-admin';

const UsersFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Name/Email/Phone" source="q" alwaysOn variant="outlined" />
    <BooleanInput label="Pending approval only" alwaysOn source="pendingApproval" />
    <BooleanInput label="Valid car owner" source="validAsCarOwner" />
    <BooleanInput label="Valid car renter" source="validAsCarRenter" />
  </Filter>
);

const UsersList = (props: ListProps) => (
  <List {...props} bulkActionButtons={false} filters={<UsersFilter />}>
    <Datagrid rowClick="show">
      <TextField source="details.firstName" label="First Name" sortable={false} />
      <TextField source="details.lastName" label="Last Name" sortable={false} />
      <TextField source="email" />
      <FunctionField
        label="User type"
        render={(record: any) => (record.business ? 'Business' : 'Normal')}
      />
      <TextField source="details.approvalStatus" label="Status" sortable={false} />
      <TextField source="detailsRequested.approvalStatus" label="Update status" sortable={false} />
      <BooleanField source="validAsCarOwner" sortable={false} />
      <BooleanField source="validAsCarRenter" sortable={false} />
      <DateField source="createdAt" sortable showTime />
      <EditButton />
    </Datagrid>
  </List>
);

export default UsersList;
