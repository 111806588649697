import React from 'react';
import {
  DateField,
  Show,
  ShowProps,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  useShowController,
} from 'react-admin';
import { ImageFieldWithPreviewLink } from '../../components/ImageFieldWithPreviewLink';
import AttachedBundles from './elements/AttachedBundles';
import { Typography } from '@material-ui/core';

const OfferShow = (props: ShowProps) => {
  const { record } = useShowController(props);

  if (!record) return null;

  return (
    <Show {...props} title="Businesses">
      <TabbedShowLayout>
        <Tab label="summary" contentClassName="two-columns-layout">
          <TextField source="id" label="Offer Id" sortable={false} />
          <TextField source="offerName" label="Offer Name" sortable={false} />
          <TextField source="offerType" label="Offer Type" sortable={false} />
          <TextField source="offerDetailSlug" label="Offer Detail Slug" sortable={false} />
          <TextField source="contentfulEntityId" label="Contentful Entity Id" sortable={false} />
          <DateField source="createdAt" sortable showTime />
        </Tab>
        <Tab label="Hero content" contentClassName="two-columns-layout" path="hero">
          {!record.heroContent && (
            <Typography variant="h6" display="block" gutterBottom>
              Hero content not available
            </Typography>
          )}
          {record.heroContent && (
            <SimpleShowLayout>
              <TextField source="heroContent.title" label="Title" sortable={false} />
              <TextField source="heroContent.content" label="Content" sortable={false} />
              <ImageFieldWithPreviewLink source="heroContent.offerImage.url" label="Offer Image" />
              <ImageFieldWithPreviewLink source="heroContent.logoImage.url" label="Logo Image" />
              <TextField
                source="heroContent.offerAction.title"
                label="Action Title"
                sortable={false}
              />
              <TextField
                source="heroContent.offerAction.link"
                label="Action Link"
                sortable={false}
              />
            </SimpleShowLayout>
          )}
        </Tab>
        <Tab label="Secondary Content" contentClassName="two-columns-layout" path="secondary">
          {!record.secondaryContent && (
            <Typography variant="h6" display="block" gutterBottom>
              Secondary content not available
            </Typography>
          )}
          {record.secondaryContent && (
            <SimpleShowLayout>
              <TextField source="secondaryContent.title" label="Title" sortable={false} />
              <TextField source="secondaryContent.content" label="Content" sortable={false} />
              <ImageFieldWithPreviewLink
                source="secondaryContent.offerImage.url"
                label="Offer Image"
              />
              <ImageFieldWithPreviewLink
                source="secondaryContent.logoImage.url"
                label="Logo Image"
              />
              <TextField
                source="secondaryContent.offerAction.title"
                label="Action Title"
                sortable={false}
              />
              <TextField
                source="secondaryContent.offerAction.link"
                label="Action Link"
                sortable={false}
              />
              <TextField source="secondaryContent.cardColor" label="Card Color" sortable={false} />
            </SimpleShowLayout>
          )}
        </Tab>
        <Tab label="Tile content" contentClassName="two-columns-layout" path="tile">
          {!record.tileContent && (
            <Typography variant="h6" display="block" gutterBottom>
              Tile content not available
            </Typography>
          )}
          {record.tileContent && (
            <SimpleShowLayout>
              <TextField source="tileContent.title" label="Title" sortable={false} />
              <TextField source="tileContent.content" label="Content" sortable={false} />
              <ImageFieldWithPreviewLink source="tileContent.offerImage.url" label="Offer Image" />
              <ImageFieldWithPreviewLink source="tileContent.logoImage.url" label="Logo Image" />
              <TextField
                source="tileContent.offerAction.title"
                label="Action Title"
                sortable={false}
              />
              <TextField
                source="tileContent.offerAction.link"
                label="Action Link"
                sortable={false}
              />
            </SimpleShowLayout>
          )}
        </Tab>
        <Tab label="Attached Bundles" path="bundles">
          <AttachedBundles />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
export default OfferShow;
