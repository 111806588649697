import React, { useCallback, useState } from 'react';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
} from '@material-ui/core';
import { gql, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { UserActions } from '../../../ra-queries/queries/users';

const useStyles = makeStyles({
  root: {
    marginRight: '10px',
    marginBottom: '20px',
  },
});

const GET_TAGS = gql`
  query tags {
    tags {
      id
      name
    }
  }
`;

const UserTags = (props: any) => {
  const tags = props.record.tags;
  const user = props.user;
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const classes = useStyles();

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTagId, setSelectedTagId] = useState('');

  const { data: tagsData } = useQuery(GET_TAGS);

  const handleUserTagAdd = useCallback(
    async (tagId: string) => {
      return dataProvider
        .update('Users', {
          id: user.id,
          data: { ...user, action: UserActions.AddUserTag, tagId },
          previousData: user,
        })
        .then(() => {
          refresh();
          notify('Tag has been added');
        })
        .catch((error) => {
          refresh();
          notify(`Tag could not be added: ${error.message}`, 'warning');
        });
    },
    [dataProvider, notify, refresh, user],
  );

  const handleUserTagDelete = useCallback(
    async (tagId: string) => {
      return dataProvider
        .update('Users', {
          id: user.id,
          data: { ...user, action: UserActions.RemoveUserTag, tagId },
          previousData: user,
        })
        .then(() => {
          refresh();
          notify('Tag has been deleted');
        })
        .catch((error) => {
          refresh();
          notify(`Tag could not be deleted: ${error.message}`, 'warning');
        });
    },
    [dataProvider, notify, refresh, user],
  );

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleAddAction = () => {
    setModalOpen(true);
  };

  return (
    <>
      <div style={{ margin: '20px 0 30px 0' }}>
        <Button variant="contained" color="primary" onClick={handleAddAction}>
          Add tag
        </Button>
      </div>
      {tags.map((tag: any) => (
        <Chip
          key={tag.id}
          label={tag.name}
          onDelete={() => handleUserTagDelete(tag.id)}
          className={classes.root}
        />
      ))}
      <Dialog open={modalOpen} onClose={handleModalClose} fullWidth>
        <DialogTitle id="responsive-dialog-title">Add tag to user</DialogTitle>
        <DialogContent>
          <Select
            value={selectedTagId}
            onChange={(e: any) => setSelectedTagId(e.target.value)}
            variant="outlined"
            label="Tags"
            fullWidth
          >
            {tagsData &&
              tagsData.tags.map((tag: any) => (
                <MenuItem value={tag.id} key={tag.id}>
                  {tag.name}
                </MenuItem>
              ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleModalClose} color="primary">
            Cancel
          </Button>
          <Button color="primary" autoFocus onClick={() => handleUserTagAdd(selectedTagId)}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserTags;
