import React, { FC } from 'react';
import { Amplify } from '@aws-amplify/core';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import GlobalStyles from './GlobalStyles';
import Admin from './Admin';
import { ApiProvider } from './providers/DataProviderFactory';
import awsExports from './aws-exports';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

Amplify.configure(awsExports);

const App: FC = () => (
  <ApiProvider>
    <GlobalStyles />
    <Admin />
  </ApiProvider>
);

export default App;
