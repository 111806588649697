/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { gql, useQuery } from '@apollo/client';
import { BundleActions } from '../../../ra-queries/queries/bundles';

const useStyles = makeStyles({
  root: {
    marginBottom: '20px',
  },
});

const GET_BUSINESSES = gql`
  query Businesses {
    businesses {
      id
      name
      displayName
    }
  }
`;

const Resellers = (props: any) => {
  const bundle = props.record;
  const resellers = props.record.resellers;
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const classes = useStyles();

  const [modalAddOpen, setModalAddOpen] = useState(false);
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [selectedBussinesId, setSelectedBussinesId] = useState('');
  const [editedReseller, setEditedReseller] = useState<any>();

  const { data: businessesData } = useQuery(GET_BUSINESSES);

  const handleAddReseller = useCallback(
    async ({ businessId }: any) => {
      return dataProvider
        .update('Bundles', {
          id: bundle.id,
          data: {
            ...bundle,
            action: BundleActions.addBundleReseller,
            businessId,
          },
          previousData: bundle,
        })
        .then(() => {
          refresh();
          notify('Reseller has been added');
        })
        .catch((error) => {
          refresh();
          notify(`Reseller could not be added: ${error.message}`, 'warning');
        });
    },
    [bundle, dataProvider, notify, refresh],
  );

  const handleEditReseller = useCallback(
    async (newResellerData) => {
      return dataProvider
        .update('Bundles', {
          id: bundle.id,
          data: {
            ...bundle,
            action: BundleActions.updateBundleReseller,
            updateInput: {
              bundleTypeId: bundle.id,
              businessId: newResellerData.business.id,
              active: newResellerData.active,
              resellerPrice: newResellerData.resellerPrice,
            },
          },
          previousData: bundle,
        })
        .then(() => {
          refresh();
          notify('Reseller has been updated');
        })
        .catch((error) => {
          refresh();
          notify(`Reseller could not be updated: ${error.message}`, 'warning');
        });
    },
    [bundle, dataProvider, notify, refresh],
  );

  const handleRemoveReseller = useCallback(
    async ({ businessId }: any) => {
      return dataProvider
        .update('Bundles', {
          id: bundle.id,
          data: {
            ...bundle,
            action: BundleActions.deleteBundleReseller,
            businessId,
          },
          previousData: bundle,
        })
        .then(() => {
          refresh();
          notify('Reseller has been removed');
        })
        .catch((error) => {
          refresh();
          notify(`Reseller could not be removed: ${error.message}`, 'warning');
        });
    },
    [bundle, dataProvider, notify, refresh],
  );

  const handleModalAddClose = () => {
    setModalAddOpen(false);
  };

  const handleModalEditClose = () => {
    setModalEditOpen(false);
  };

  const handleAddAction = () => {
    setModalAddOpen(true);
  };

  const handleEditAction = (businessId: string) => {
    const newEditedReseller = resellers.find(
      (reseller: any) => reseller.business.id === businessId,
    );
    if (newEditedReseller) {
      setEditedReseller(newEditedReseller);
    }
    setModalEditOpen(true);
  };

  return (
    <>
      <div style={{ margin: '20px 0 30px 0' }}>
        <Button variant="contained" color="primary" onClick={handleAddAction}>
          Add reseller
        </Button>
      </div>
      {resellers.map((reseller: any) => (
        <Card key={reseller.id} className={classes.root}>
          <CardContent>
            <Typography variant="h6" display="block" gutterBottom>
              {reseller.business.displayName} ({reseller.business.name})
            </Typography>
            <Typography variant="overline" display="block" gutterBottom>
              Price:{' '}
              {new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(
                reseller.resellerPrice,
              )}
            </Typography>
            <Typography variant="overline" display="block" gutterBottom>
              Active: {reseller.active ? 'yes' : 'no'}
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              color="primary"
              onClick={() => {
                handleEditAction(reseller.business.id);
              }}
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
            <Button
              size="small"
              style={{ background: 'none', color: '#f44336' }}
              onClick={() => {
                handleRemoveReseller({ businessId: reseller.business.id });
              }}
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          </CardActions>
        </Card>
      ))}
      <Dialog open={modalAddOpen} onClose={handleModalAddClose} fullWidth>
        <DialogTitle id="responsive-dialog-title">Add reseller to the bundle</DialogTitle>
        <DialogContent>
          <Select
            value={selectedBussinesId}
            onChange={(e: any) => setSelectedBussinesId(e.target.value)}
            variant="outlined"
            label="Businesses"
            fullWidth
          >
            {businessesData &&
              businessesData.businesses.map((businessOption: any) => (
                <MenuItem value={businessOption.id} key={businessOption.id}>
                  {businessOption.displayName} ({businessOption.name})
                </MenuItem>
              ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleModalAddClose} color="primary">
            Cancel
          </Button>
          <Button
            color="primary"
            autoFocus
            disabled={!selectedBussinesId || selectedBussinesId === ''}
            onClick={() => {
              handleAddReseller({ businessId: selectedBussinesId });
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {editedReseller && (
        <Dialog open={modalEditOpen} onClose={handleModalEditClose} fullWidth>
          <DialogTitle id="responsive-dialog-title">Edit reseller</DialogTitle>
          <DialogContent>
            <TextField
              id="outlined-basic"
              label="Business"
              variant="outlined"
              value={editedReseller.business.name}
              disabled
              fullWidth
              style={{ marginBottom: '20px' }}
            />
            <TextField
              id="outlined-basic"
              label="Reseller price"
              variant="outlined"
              type="number"
              value={editedReseller.resellerPrice}
              onChange={(e) => {
                setEditedReseller({
                  ...editedReseller,
                  resellerPrice: e.target.value,
                });
              }}
              fullWidth
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={editedReseller.active}
                  onChange={(e) => {
                    setEditedReseller({ ...editedReseller, active: e.target.checked });
                  }}
                  name="active"
                />
              }
              label="Active"
            />
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleModalEditClose} color="primary">
              Cancel
            </Button>
            <Button
              color="primary"
              autoFocus
              onClick={() => {
                handleEditReseller(editedReseller);
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default Resellers;
