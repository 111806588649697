/* eslint-disable no-underscore-dangle */
import { gql } from '@apollo/client';
import _omit from 'lodash.omit';
import {
  RaFetchType,
  RaGetResponse,
  RaListParams,
  RaListResponse,
  RaQuery,
  RaUpdateParams,
} from '../../interfaces/RaQueries';
import { BundleTypeType } from '../../interfaces/types.generated';
import { ApiResponse } from '../../interfaces/ApiQueries';
import getListParams from '../../utils/getListParams';

interface ListResult {
  bundleTypes: Array<BundleTypeType>;
}

export enum BundleActions {
  addBundleReseller = 'ADD_BUNDLE_RESELLER',
  updateBundleReseller = 'UPDATE_BUNDLE_RESELLER',
  deleteBundleReseller = 'DELETE_BUNDLE_RESELLER',
}

const Bundles = (raFetchType: RaFetchType, params: any): RaQuery => {
  switch (raFetchType) {
    case 'GET_LIST':
      return {
        query: gql`
          query {
            bundleTypes {
              id
              name
              internalName
              price
              description
              isDefault
              periodDuration
              contentPageUrl
              contentfulBenefitsComponentId
              contentfulBannerComponentId
              currentVersionId
            }
          }
        `,
        variables: getListParams(params as RaListParams),
        parseResponse: ({ data }: ApiResponse<ListResult>): RaListResponse<BundleTypeType> => ({
          data: data.bundleTypes.map((bundle) => {
            return { ...bundle, price: Number(bundle.price) / 100 };
          }),
          total: data.bundleTypes.length,
        }),
      };
    case 'GET_ONE':
      return {
        query: gql`
          query ($id: String!) {
            bundleType(id: $id) {
              id
              name
              internalName
              price
              description
              isDefault
              periodDuration
              contentPageUrl
              contentfulBenefitsComponentId
              contentfulBannerComponentId
              currentVersionId
              resellers {
                id
                business {
                  id
                  name
                  displayName
                }
                resellerPrice
                active
              }
              attachedOffers {
                rank
                offerType
                offer {
                  id
                  offerName
                }
              }
            }
            bundleTypePdfUrl(bundleTypeId: $id)
          }
        `,
        variables: params,
        parseResponse: ({ data }): RaGetResponse<BundleTypeType> => ({
          data: {
            ...data.bundleType,
            price: Number(data.bundleType.price) / 100,
            pdfUrl: data.bundleTypePdfUrl,
            resellers: data.bundleType.resellers
              ? data.bundleType.resellers.map((reseller: any) => {
                  return { ...reseller, resellerPrice: Number(reseller.resellerPrice) / 100 };
                })
              : [],
          },
        }),
      };
    case 'CREATE':
      return {
        query: gql`
          mutation ($input: CreateBundleTypeInput!) {
            createBundleType(bundleInput: $input) {
              id
              name
            }
          }
        `,
        variables: {
          input: { ...params.data, price: Math.round(Number(params.data.price) * 100) },
        },
        parseResponse: ({ data }): RaGetResponse<BundleTypeType> => ({
          data: { ...data.createBundleType, price: Number(params.data.price) * 100 },
        }),
      };
    case 'UPDATE':
      if (params.data.action === BundleActions.addBundleReseller) {
        return {
          query: gql`
            mutation addBundleTypeReseller($businessId: String!, $bundleTypeId: String!) {
              addBundleTypeReseller(businessId: $businessId, bundleTypeId: $bundleTypeId) {
                id
              }
              updateBundleTypeReseller(
                update: { businessId: $businessId, bundleTypeId: $bundleTypeId, active: true }
              )
            }
          `,
          variables: {
            bundleTypeId: params.id,
            businessId: params.data.businessId,
          },
          parseResponse: (): RaGetResponse<BundleTypeType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === BundleActions.deleteBundleReseller) {
        return {
          query: gql`
            mutation deleteBundleTypeReseller($businessId: String!, $bundleTypeId: String!) {
              deleteBundleTypeReseller(businessId: $businessId, bundleTypeId: $bundleTypeId)
            }
          `,
          variables: {
            bundleTypeId: params.id,
            businessId: params.data.businessId,
          },
          parseResponse: (): RaGetResponse<BundleTypeType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      if (params.data.action === BundleActions.updateBundleReseller) {
        return {
          query: gql`
            mutation updateBundleTypeReseller($update: BundleTypeResellerUpdateInput!) {
              updateBundleTypeReseller(update: $update)
            }
          `,
          variables: {
            update: {
              ...params.data.updateInput,
              resellerPrice: Math.round(Number(params.data.updateInput.resellerPrice) * 100),
            },
          },
          parseResponse: (): RaGetResponse<BundleTypeType> => ({
            data: { id: params.id, ...params.data },
          }),
        };
      }
      // STANDARD BUNDLE UPDATE ACTION
      return {
        query: gql`
          mutation ($updateInput: UpdateBundleTypeInput!, $id: String!) {
            updateBundleType(updateInput: $updateInput, id: $id) {
              id
              name
            }
          }
        `,
        variables: {
          updateInput: _omit(
            { ...params.data, price: Math.round(Number(params.data.price) * 100) },
            [
              'id',
              'currentVersionId',
              'periodDuration',
              'pdfUrl',
              'pdfFile',
              'resellers',
              'isDefault',
            ],
          ),
          id: (params as RaUpdateParams<BundleTypeType>).id,
        },
        parseResponse: ({ data }): RaGetResponse<BundleTypeType> => ({
          data: data.updateBundleType,
        }),
      };
    default:
      throw new Error(`Unknown fetch type: ${raFetchType}`);
  }
};

export default Bundles;
