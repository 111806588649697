/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { gql, useQuery } from '@apollo/client';
import { User } from '../../../interfaces/types.generated';
import { BusinessActions } from '../../../ra-queries/queries/businesses';

const GET_USERS = gql`
  query Users($query: String!) {
    users(params: { searchPhrase: $query }) {
      id
      email
      details {
        firstName
        lastName
      }
    }
  }
`;

const BusinessUsers = (props: any) => {
  const business = props.record;
  const users = props.record.users;
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const [modalOpen, setModalOpen] = useState(false);
  const [userRole, setUserRole] = useState<string>('Operator');

  const [userSearchQuery, setUserSearchQuery] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<{ id: string } | null>();

  const { data } = useQuery(GET_USERS, {
    variables: { query: selectedUser ? '' : userSearchQuery || '' },
  });

  const handleAddUserToBusiness = useCallback(
    async ({ userId, businessRole }: any) => {
      return dataProvider
        .update('Businesses', {
          id: business.id,
          data: {
            ...business,
            action: BusinessActions.AddUserToBusiness,
            userId,
            businessRole,
          },
          previousData: business,
        })
        .then(() => {
          refresh();
          notify('User has been added to the business');
        })
        .catch((error) => {
          refresh();
          notify(`User could not be added to the business: ${error.message}`, 'warning');
        });
    },
    [business, dataProvider, notify, refresh],
  );

  const handleRemoveUserFromBusiness = useCallback(
    async ({ userId }: any) => {
      return dataProvider
        .update('Businesses', {
          id: business.id,
          data: {
            ...business,
            action: BusinessActions.RemoveUserFromBusiness,
            userId,
          },
          previousData: business,
        })
        .then(() => {
          refresh();
          notify('User has been removed from the business');
        })
        .catch((error) => {
          refresh();
          notify(`User could not be removed from the business: ${error.message}`, 'warning');
        });
    },
    [business, dataProvider, notify, refresh],
  );

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleAddAction = () => {
    setModalOpen(true);
  };

  return (
    <>
      <div style={{ margin: '20px 0 30px 0' }}>
        <Button variant="contained" color="primary" onClick={handleAddAction}>
          Attach user to company
        </Button>
      </div>
      <Table>
        <TableBody>
          {users.map((user: User) => (
            <TableRow key={user.id}>
              <TableCell>{user.id}</TableCell>
              <TableCell>{user.businessUserRole}</TableCell>
              <TableCell>
                {user.details.firstName} {user.details.lastName}
              </TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>
                <Button
                  size="small"
                  style={{ background: 'none', color: '#f44336' }}
                  onClick={() => handleRemoveUserFromBusiness({ userId: user.id })}
                  startIcon={<DeleteIcon />}
                >
                  Remove user
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Dialog open={modalOpen} onClose={handleModalClose} fullWidth>
        <DialogTitle id="responsive-dialog-title">Add user to the business</DialogTitle>
        <DialogContent>
          <div style={{ marginBottom: '20px' }}>
            <Autocomplete
              onChange={(event, newValue) => {
                setSelectedUser(newValue);
              }}
              inputValue={userSearchQuery}
              onInputChange={(event, newInputValue) => {
                setUserSearchQuery(newInputValue);
              }}
              options={data && data.users ? data.users : []}
              getOptionLabel={(option: any) => {
                if (!option) return '';
                return `${option.details.firstName} ${option.details.lastName} (${option.email})`;
              }}
              renderInput={(params) => <TextField {...params} label="User" variant="outlined" />}
            />
          </div>
          <Select
            value={userRole}
            onChange={(e: any) => setUserRole(e.target.value)}
            variant="outlined"
            label="User role"
            fullWidth
          >
            <MenuItem value="Operator">Operator</MenuItem>
            <MenuItem value="Owner">Owner</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleModalClose} color="primary">
            Cancel
          </Button>
          <Button
            color="primary"
            autoFocus
            disabled={!selectedUser}
            onClick={() => {
              handleAddUserToBusiness({ userId: selectedUser!.id, businessRole: userRole });
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BusinessUsers;
