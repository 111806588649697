import React, { useCallback, useState } from 'react';
import { Button, MenuItem, Select, TextField } from '@material-ui/core';
import { Save as SaveIcon } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useDataProvider, useNotify, useRedirect, useRefresh } from 'react-admin';
import { gql, useQuery } from '@apollo/client';

const GET_USERS = gql`
  query Users($query: String!) {
    users(params: { searchPhrase: $query }) {
      id
      email
      details {
        firstName
        lastName
      }
    }
  }
`;

const GET_BUNDLES = gql`
  query bundleTypes {
    bundleTypes {
      id
      name
    }
  }
`;

const SubscriptionCreate = () => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();
  const redirect = useRedirect();

  const [userSearchQuery, setUserSearchQuery] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<{ id: string } | null>();
  const { data: usersData } = useQuery(GET_USERS, {
    variables: { query: selectedUser ? '' : userSearchQuery || '' },
  });

  const [selectedBundleId, setSelectedBundleId] = useState<string>();
  const { data: bundlesData } = useQuery(GET_BUNDLES);

  const handleAddSubscription = useCallback(
    async ({ bundleTypeId, userId }: any) => {
      return dataProvider
        .create('Subscriptions', {
          data: {
            bundleTypeId,
            userId,
          },
        })
        .then(() => {
          redirect('/subscriptions');
          refresh();
          notify('Subscription added');
        })
        .catch((error) => {
          refresh();
          notify(`Subscription could not be added: ${error.message}`, 'warning');
        });
    },
    [dataProvider, notify, redirect, refresh],
  );

  return (
    <div style={{ marginTop: '20px' }}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridGap: '20px',
          marginBottom: '20px',
        }}
      >
        <Autocomplete
          onChange={(event, newValue) => {
            setSelectedUser(newValue);
          }}
          inputValue={userSearchQuery}
          onInputChange={(event, newInputValue) => {
            setUserSearchQuery(newInputValue);
          }}
          options={usersData && usersData.users ? usersData.users : []}
          getOptionLabel={(option: any) => {
            if (!option) return '';
            return `${option.details.firstName} ${option.details.lastName} (${option.email})`;
          }}
          renderInput={(params) => <TextField {...params} label="User" variant="outlined" />}
        />
        <Select
          value={selectedBundleId}
          onChange={(e: any) => setSelectedBundleId(e.target.value)}
          variant="outlined"
          label="Bundle"
          placeholder="bundle"
          fullWidth
        >
          {bundlesData &&
            bundlesData.bundleTypes.map((bundleOption: any) => (
              <MenuItem value={bundleOption.id} key={bundleOption.id}>
                {bundleOption.name}
              </MenuItem>
            ))}
        </Select>
      </div>
      <Button
        variant="contained"
        color="primary"
        startIcon={<SaveIcon />}
        onClick={() => {
          if (selectedUser && selectedBundleId) {
            handleAddSubscription({ bundleTypeId: selectedBundleId, userId: selectedUser.id });
          }
        }}
      >
        Save
      </Button>
    </div>
  );
};

export default SubscriptionCreate;
